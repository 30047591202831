import Dashboard from './views/Dashboard';
import Branch from './views/pages/Branch';
import Category from './views/pages/Category';
import SubCategory from './views/pages/SubCategory';
import Warehouse from './views/pages/Warehouse';
import Department from './views/pages/Department';
import Rack from './views/pages/Rack';
import MaterialForm from './views/components/Material/Material/MaterialForm';
import MaterialTableLayout from './views/pages/Material/MaterialTableLayout';
import MaterialLog from './views/components/Material/Material/MaterialLog';
import DepartmentForm from './views/components/Department/Form/DepartmentForm';
import Login from './views/pages/Login';
import Employee from './views/pages/Employee';
import EmployeeForm from './views/components/Employee/EmployeeForm';
import EmployeeFormEdit from './views/components/Employee/EmployeeFormEdit';
import Supplier from './views/pages/Supplier';
import SupplierForm from './views/components/Supplier/SupplierForm';
import SupplierFormEdit from './views/components/Supplier/SupplierFormEdit';
import Import from './views/pages/Import';
import ImportForm from './views/components/Import/ImportForm';
import ImportFormEdit from './views/components/Import/ImportFormEdit';
import WithdrawalTool from './views/pages/WithdrawalTool';
import WithDrawalAdd from './views/pages/Withdrawal/WithdrawalAdd';
import WithdrawalReturn from './views/pages/Withdrawal/WithdrawalReturn';
import WithdrawalResult from './views/components/Withdrawal/WithdrawalResult';

const routes = [
	{
		path: '/login',
		name: 'Login',
		mini: 'L',
		component: Login,
		layout: '/auth',
		sidebar: false
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'nc-icon nc-bank',
		component: Dashboard,
		layout: '/page'
	},
	{
		path: '/branch',
		name: 'Branch',
		icon: 'nc-icon nc-bank',
		component: Branch,
		layout: '/page'
	},
	{
		path: '/category',
		name: 'Category',
		icon: 'nc-icon nc-bank',
		component: Category,
		layout: '/page'
	},
	{
		path: '/subcategory',
		name: 'Subcategory',
		icon: 'nc-icon nc-bank',
		component: SubCategory,
		layout: '/page'
	},
	{
		path: '/warehouse',
		name: 'Warehouse',
		icon: 'nc-icon nc-bank',
		component: Warehouse,
		layout: '/page'
	},
	{
		path: '/department',
		name: 'Department',
		icon: 'nc-icon nc-bank',
		component: Department,
		layout: '/page',
		exact: true
	},
	{
		path: '/department/add',
		name: 'Department Form',
		icon: 'nc-icon nc-bank',
		component: DepartmentForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/department/edit/:id',
		name: 'Department Form',
		icon: 'nc-icon nc-bank',
		component: DepartmentForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/rack',
		name: 'Rack',
		icon: 'nc-icon nc-bank',
		component: Rack,
		layout: '/page'
	},
	{
		path: '/raw-materials',
		name: 'Raw Materials',
		icon: 'nc-icon nc-bank',
		component: MaterialTableLayout,
		layout: '/page',
		exact: true
	},
	{
		path: '/tool-materials',
		name: 'Tool Materials',
		icon: 'nc-icon nc-bank',
		component: MaterialTableLayout,
		layout: '/page',
		exact: true
	},
	{
		path: '/longterm-materials',
		name: 'Long Term Materials',
		icon: 'nc-icon nc-bank',
		component: MaterialTableLayout,
		layout: '/page',
		exact: true
	},
	{
		path: '/raw-materials/:method',
		name: 'Materials Add',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/raw-materials/:method/:id',
		name: 'Materials Edit',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/tool-materials/:method/',
		name: 'Materials Add',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/tool-materials/:method/:id',
		name: 'Materials Edit',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/longterm-materials/:method/',
		name: 'Long Term Materials Add',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/longterm-materials/:method/:id',
		name: 'Long Term Materials Edit',
		icon: 'nc-icon nc-bank',
		component: MaterialForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/materials/log/:id',
		name: 'Materials Log',
		icon: 'nc-icon nc-bank',
		component: MaterialLog,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/employees',
		name: 'Employees',
		icon: 'nc-icon nc-bank',
		component: Employee,
		layout: '/page',
		exact: true
	},
	{
		path: '/employees/add',
		name: 'Employees',
		icon: 'nc-icon nc-bank',
		component: EmployeeForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/employees/edit/:id',
		name: 'Employees',
		icon: 'nc-icon nc-bank',
		component: EmployeeFormEdit,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/suppliers',
		name: 'Supplier',
		icon: 'nc-icon nc-bank',
		component: Supplier,
		layout: '/page',
		exact: true,
		sidebar: true
	},
	{
		path: '/suppliers/add',
		name: 'Supplier',
		icon: 'nc-icon nc-bank',
		component: SupplierForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/suppliers/edit/:id',
		name: 'Supplier',
		icon: 'nc-icon nc-bank',
		component: SupplierFormEdit,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/import',
		name: 'Import',
		icon: 'nc-icon nc-bank',
		component: Import,
		layout: '/page',
		exact: true
	},
	{
		path: '/import/add',
		name: 'Import Add',
		icon: 'nc-icon nc-bank',
		component: ImportForm,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/import/edit/:id',
		name: 'Import Edit',
		icon: 'nc-icon nc-bank',
		component: ImportFormEdit,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/withdrawal-tools',
		name: 'Withdrawal-Tools',
		icon: 'nc-icon nc-bank',
		component: WithdrawalTool,
		layout: '/page',
		exact: true
	},
	{
		path: '/withdrawal-tools/add',
		name: 'Withdrawal Add',
		icon: 'nc-icon nc-bank',
		component: WithDrawalAdd,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/withdrawal-tools/return/:id',
		name: 'Withdrawal Tools Return',
		icon: 'nc-icon nc-bank',
		component: WithdrawalReturn,
		layout: '/page',
		exact: true,
		sidebar: false
	},
	{
		path: '/withdrawal-tools/result/:id',
		name: 'Withdrawal Tools Result',
		icon: 'nc-icon nc-bank',
		component: WithdrawalResult,
		layout: '/page',
		exact: true,
		sidebar: false
	}
];

export default routes;
