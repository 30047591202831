import React, { useState, useEffect } from 'react';

import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Button,
	Table,
	Spinner,
	Nav,
	NavItem,
	NavLink,
	Pagination,
	PaginationItem,
	PaginationLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
	Popover,
	PopoverHeader,
	PopoverBody,
	Input,
	Form
} from 'reactstrap';

import { Link } from 'react-router-dom';

import { import_lang, import_form_lang, modal_lang, import_modal_lang } from '../../i18n/lo-LA';

import strapi, { URL } from '../../strapi-sdk';

import Moment from 'react-moment';

import _ from 'lodash';
import DeleteModal from './Import/DeleteModal';

import ReactBSAlert from 'react-bootstrap-sweetalert';

import MoveModal from './Import/MoveModal';

const Import = props => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [countPages, setCountPages] = useState(0);

	//DELETE MODAL
	const [alert, setAlert] = useState(null);

	//MOVE MODAL
	const [warehouses, setWarehouses] = useState(null);

	//TABS
	const [activeTab, setActiveTab] = useState('notyet');
	const [status, setStatus] = useState('unpaid');

	// Ordering by updated_at
	const [dateOrdering, setDateOrdering] = useState('DESC');
	const [billDateOrdering, setBillDateOrdering] = useState('DESC');

	//Poppver & SEARCH
	const [popover, setPopover] = useState(false);
	const [searchID, setSearchID] = useState(null);

	const LIMIT = 10;

	useEffect(
		() => {
			function fetchWarehouses() {
				strapi.getEntries('warehouses').then(res => {
					setWarehouses(res);
				});
			}
			fetchWarehouses();
			counting('notyet', 1, 'unpaid');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const counting = async (move, p, stt) => {
		setPopover(false);
		setPage(p);
		setLoading(true);

		let countPage;
		let approve = '';
		let resData;
		if (move === 'notyet') approve = '&approve=false';
		if (move === 'moved') approve = '&approve=true';

		countPage = await strapi.request('GET', `${URL}materialorders/count/?${approve}&status=${stt}`);

		resData = await strapi.request(
			'GET',
			`${URL}materialorders?${approve}&_start=${(p - 1) * 10}&_limit=${p *
				10}&status=${stt}&_sort=updated_at:${dateOrdering}`
		);
		setCountPages(Math.ceil(countPage / LIMIT));
		setData(resData);
		setLoading(false);
	};

	const updateTab = async tab => {
		await counting(tab, 1, status);
	};

	const updateNav = async nav => {
		await counting(activeTab, 1, nav);
	};

	//filtering date
	const dateOrder = async () => {
		setPopover(false);
		setLoading(true);

		let approve = '';
		let order = dateOrdering;
		if (activeTab === 'notyet') approve = '&approve=false';
		if (activeTab === 'moved') approve = '&approve=true';
		if (dateOrdering === 'DESC') order = 'ASC';
		if (dateOrdering === 'ASC') order = 'DESC';

		const resData = await strapi.request(
			'GET',
			`${URL}materialorders?${approve}&_start=${(page - 1) * 10}&_limit=${page *
				10}&status=${status}&_sort=updated_at:${order}`
		);
		setDateOrdering(order);
		setData(resData);
		setLoading(false);
	};
	//filtering date
	const BillDateOrder = async () => {
		setPopover(false);
		setLoading(true);

		let approve = '';
		let order = billDateOrdering;
		if (activeTab === 'notyet') approve = '&approve=false';
		if (activeTab === 'moved') approve = '&approve=true';
		if (billDateOrdering === 'DESC') order = 'ASC';
		if (billDateOrdering === 'ASC') order = 'DESC';

		const resData = await strapi.request(
			'GET',
			`${URL}materialorders?${approve}&_start=${(page - 1) * 10}&_limit=${page *
				10}&status=${status}&_sort=bill_date:${order}`
		);
		setBillDateOrdering(order);
		setData(resData);
		setLoading(false);
	};

	// SEARCH BY BILL CODE
	const handleSearch = async e => {
		e.preventDefault();
		setPopover(false);
		setLoading(true);

		const resData = await strapi.request('GET', `${URL}materialorders?id=${searchID}`);
		setData(resData);
		setLoading(false);
	};

	// DELETE MODAL
	const handleRemove = id => {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={modal_lang.ARE_YOU_SURE}
				onConfirm={() => successDelete(id)}
				onCancel={() => cancelDetele()}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText={modal_lang.YES_IM_SURE}
				cancelBtnText={modal_lang.CANCEL}
				showCancel
			>
				ທ່ານຕ້ອງການລຶບບິນເລກ {id} ແມ່ນບໍ່? ທ່ານຈະບໍ່ສາມາດກູ້ຂໍ້ມູນໄດ້ຄືນ!
			</ReactBSAlert>
		);
	};

	const successDelete = id => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title={import_lang.DELETED}
				onConfirm={() => {
					strapi
						.deleteEntry('materialorders', id)
						.then(res => {
							updateTab(activeTab, 1, status);
							hideAlert();
						})
						.catch(err => {
							console.log(err);
						});
				}}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				{import_lang.DELETED_MSG}
			</ReactBSAlert>
		);
	};
	const cancelDetele = () => {
		setAlert(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title={import_lang.CANCELLED}
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				{import_lang.CANCELLED_MSG}
			</ReactBSAlert>
		);
	};

	const hideAlert = () => {
		setAlert(null);
	};

	// UPDATE STATUS
	const UpdateStatus = (id, stt) => {
		strapi.request('PUT', `${URL}materialorders/edit/${id}`, { data: stt }).then(res => {
			updateTab(activeTab, page, stt);
		});
	};

	const getStatusName = choice => {
		if (choice === 'waiting') return 'ລໍຖ້າ';
		if (choice === 'unpaid') return 'ຍັງບໍ່ຊຳລະ';
		if (choice === 'paid') return 'ຊຳລະແລ້ວ';
	};

	return (
		<div className="content">
			{alert}
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader style={{ borderBottom: 'dashed', borderWidth: '2px', borderColor: '#ccc' }}>
							<Row>
								<Col lg="7" xs="6">
									<h3 className="title" style={{ marginBottom: 'unset' }}>
										{import_lang.IMPORT_LIST}
									</h3>
								</Col>
								<Col lg="5" xs="6">
									<div className="pull-right">
										<Link to="/page/import/add">
											<Button size="sm" style={{ marginTop: 0, fontSize: '1.2rem' }}>
												{import_lang.ADD}
											</Button>
										</Link>
									</div>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							{/* 3 NAVBAR ITEMS WAITING, UNPAID, PAID */}
							<Nav
								className="nav-pills-secondary nav-pills-icons justify-content-center"
								pills
								role="tablist"
							>
								<NavItem>
									<NavLink
										data-toggle="tab"
										href="#pablo"
										role="tablist"
										className={status === 'waiting' ? 'active' : ''}
										onClick={() => {
											// counting(activeTab, 1, 'waiting');
											setStatus('waiting');
											updateNav('waiting');
										}}
									>
										<i className="now-ui-icons objects_umbrella-13" />
										{import_form_lang.WAITING}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										data-toggle="tab"
										href="#pablo"
										role="tablist"
										className={status === 'unpaid' ? 'active' : ''}
										onClick={async () => {
											setStatus('unpaid');
											updateNav('unpaid');
										}}
									>
										<i className="now-ui-icons shopping_shop" />
										{import_form_lang.UNPAID}
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										data-toggle="tab"
										href="#pablo"
										role="tablist"
										className={status === 'paid' ? 'active' : ''}
										onClick={async () => {
											setStatus('paid');
											updateNav('paid');
										}}
									>
										<i className="now-ui-icons ui-2_settings-90" />
										{import_form_lang.PAID}
									</NavLink>
								</NavItem>
							</Nav>
							{/* END NAVBAR */}
						</CardBody>
					</Card>
					{/* TABS */}
					<Card>
						<CardBody>
							<div className="nav-tabs-navigation">
								<div className="nav-tabs-wrapper">
									<Nav id="tabs" role="tablist" tabs style={{ fontSize: '1.1rem' }}>
										<NavItem>
											<NavLink
												aria-expanded={activeTab === 'notyet'}
												data-toggle="tab"
												href="#pablo"
												role="tab"
												className={activeTab === 'notyet' ? 'active' : ''}
												onClick={() => {
													setActiveTab('notyet');
													updateTab('notyet');
												}}
											>
												{import_lang.WAITING_FOR_APPROVING}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												aria-expanded={activeTab === 'moved'}
												data-toggle="tab"
												href="#pablo"
												role="tab"
												className={activeTab === 'moved' ? 'active' : ''}
												onClick={() => {
													setActiveTab('moved');
													updateTab('moved');
												}}
											>
												{import_lang.APPROVED}
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</div>

							{/* TABLE */}
							{loading ? (
								<Spinner color="primary" />
							) : (
								<React.Fragment>
									<Popover
										placement="top"
										isOpen={popover}
										target="popover"
										className="popover-primary"
									>
										<PopoverHeader>{import_lang.SEARCH_BILL_CODE}</PopoverHeader>
										<PopoverBody>
											<Form method="POST" onSubmit={handleSearch}>
												<Input
													type="text"
													name="searchBillCode"
													placeholder={import_lang.CODE}
													onChange={e => setSearchID(e.target.value)}
												/>
											</Form>
										</PopoverBody>
									</Popover>

									<Table striped>
										<thead>
											<tr>
												<th style={{ textAlign: 'center' }}>{import_lang.NUMBER_LIST}</th>

												<th style={{ cursor: 'pointer' }} onClick={BillDateOrder}>
													{import_form_lang.BILL_DATE}{' '}
													{billDateOrdering === 'DESC' ? (
														<i className="nc-icon nc-minimal-down" />
													) : (
														<i className="nc-icon nc-minimal-up" />
													)}
												</th>
												<th style={{ cursor: 'pointer' }} onClick={dateOrder}>
													{import_lang.LATEST_UPDATE}{' '}
													{dateOrdering === 'DESC' ? (
														<i className="nc-icon nc-minimal-down" />
													) : (
														<i className="nc-icon nc-minimal-up" />
													)}
												</th>
												<th
													style={{ textAlign: 'center', width: '10%', cursor: 'pointer' }}
													id="popover"
													onClick={() => setPopover(!popover)}
												>
													<span>
														<i className="nc-icon nc-zoom-split" /> {import_lang.CODE}
													</span>
												</th>
												<th style={{ width: '10%', textAlign: 'right' }}>
													{import_lang.SUPPLIER}
												</th>
												<th style={{ width: '10%', textAlign: 'right' }}>
													{import_lang.MUST_PAY}
												</th>
												<th style={{ textAlign: 'center', width: '15%' }}>
													{import_lang.STATUS}
												</th>
												<th style={{ textAlign: 'center', width: '10%' }}>
													{import_modal_lang.MOVE_TO_WAREHOUSE}
												</th>
												<th style={{ width: '20%' }} />
											</tr>
										</thead>
										{_.isEmpty(data) ? (
											<p style={{ fontSize: '1.3rem', color: 'red', textAlign: 'center' }}>
												{import_lang.NO_DATA}
											</p>
										) : (
											<tbody>
												{data.map((item, idx) => (
													<tr key={item.id}>
														<th scope="row" style={{ textAlign: 'center' }}>
															{page === 1 ? idx + 1 : (page - 1) * 10 + (idx + 1)}
														</th>
														<th scope="row">
															<Moment format="D/MM/YYYY" withTitle>
																{item.bill_date}
															</Moment>
														</th>
														<th scope="row">
															<Moment format="D/MM/YYYY" withTitle>
																{item.updated_at}
															</Moment>
														</th>
														<td style={{ textAlign: 'center' }}>#{item.id}</td>
														<td style={{ textAlign: 'right' }}>{item.supplier.name}</td>
														<td style={{ textAlign: 'right' }}>
															{new Intl.NumberFormat('ja-JP').format(item.overall_price) +
																' ' +
																import_form_lang.KIP}
														</td>
														<td style={{ textAlign: 'center' }}>
															<UncontrolledDropdown group direction="right">
																<DropdownToggle caret style={{ fontSize: '0.8rem' }}>
																	{getStatusName(item.status)}
																</DropdownToggle>
																<DropdownMenu
																	style={{
																		border: 'dashed',
																		borderWidth: '2px',
																		borderColor: '#ccc',
																		fontSize: '1.1rem'
																	}}
																>
																	<DropdownItem
																		style={{
																			borderBottom: 'dashed',
																			borderWidth: '2px',
																			borderColor: '#ccc',
																			fontSize: '1.1rem'
																		}}
																		onClick={() =>
																			UpdateStatus(item.id, {
																				status: 'waiting'
																			})}
																	>
																		ລໍຖ້າ
																	</DropdownItem>
																	<DropdownItem
																		style={{
																			borderBottom: 'dashed',
																			borderWidth: '2px',
																			borderColor: '#ccc',
																			fontSize: '1.1rem'
																		}}
																		onClick={() =>
																			UpdateStatus(item.id, { status: 'unpaid' })}
																	>
																		ຍັງບໍ່ຊຳລະ
																	</DropdownItem>
																	<DropdownItem
																		style={{ fontSize: '1.1rem' }}
																		onClick={() =>
																			UpdateStatus(item.id, { status: 'paid' })}
																	>
																		ຊຳລະແລ້ວ
																	</DropdownItem>
																</DropdownMenu>
															</UncontrolledDropdown>
														</td>
														<td style={{ textAlign: 'center' }}>
															{item.approve === true ? (
																<MoveModal
																	id={item.id}
																	item={item}
																	warehouses={warehouses}
																	updateTab={updateTab}
																	tab={activeTab}
																	nav={status}
																	success={true}
																/>
															) : (
																<MoveModal
																	id={item.id}
																	item={item}
																	warehouses={warehouses}
																	updateTab={updateTab}
																	tab={activeTab}
																	nav={status}
																/>
															)}
														</td>
														<td>
															<UncontrolledDropdown>
																<DropdownToggle
																	aria-expanded={false}
																	aria-haspopup={true}
																	caret
																	className="btn-round btn-block"
																	color="primary"
																	data-toggle="dropdown"
																	id="dropdownMenuButton"
																	type="button"
																	disabled={item.approve}
																	style={{ fontSize: '0.9rem' }}
																>
																	{import_lang.SETTING}
																</DropdownToggle>
																<DropdownMenu
																	aria-labelledby="dropdownMenuButton"
																	right
																>
																	<DropdownItem header tag="div">
																		{import_form_lang.CHOOSE}
																	</DropdownItem>
																	{item.approve ? null : (
																		<React.Fragment>
																			<DropdownItem>
																				<Link
																					to={`/page/import/edit/${item.id}`}
																					style={{
																						color: 'unset',
																						textDecoration: 'unset'
																					}}
																				>
																					{import_lang.EDIT}
																				</Link>
																			</DropdownItem>

																			<DeleteModal
																				id={item.id}
																				handleRemove={() =>
																					handleRemove(item.id)}
																				item={item}
																			/>
																		</React.Fragment>
																	)}
																</DropdownMenu>
															</UncontrolledDropdown>
														</td>
													</tr>
												))}
											</tbody>
										)}
									</Table>

									<Pagination listClassName="justify-content-center">
										{page - 1 === 0 ? null : (
											<PaginationItem>
												<PaginationLink
													tag="span"
													onClick={() => counting(activeTab, page - 1, status)}
												>
													{import_lang.PREVIOUS}
												</PaginationLink>
											</PaginationItem>
										)}
										{_.range(1, countPages + 1).map(p => (
											<PaginationItem key={p} active={page === p ? true : false}>
												<PaginationLink
													tag="span"
													onClick={() => counting(activeTab, p, status)}
												>
													{p}
												</PaginationLink>
											</PaginationItem>
										))}

										{page === countPages || countPages === 0 ? null : (
											<PaginationItem>
												<PaginationLink
													tag="span"
													onClick={() => counting(activeTab, page + 1, status)}
												>
													{import_lang.NEXT}
												</PaginationLink>
											</PaginationItem>
										)}
									</Pagination>
								</React.Fragment>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default Import;
