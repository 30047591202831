import React, { useState, useEffect } from 'react';

import {
	Button,
	Modal,
	Input,
	Card,
	Form,
	CardBody,
	Row,
	Col,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroup
} from 'reactstrap';

import Select from 'react-select';

import strapi from '../../../../strapi-sdk';

import _ from 'lodash';

import '../../../../assets/scss/selectModal.css';

import { validateQuantity } from '../../../../utils/validate';
import { modal_lang, material_form_modal_lang } from '../../../../i18n/lo-LA';

const MaterialFormModal = props => {
	const [data, setData] = useState({
		material: props.materialID,
		quantity: '',
		warehouse: null,
		rack: null
	});
	const [materialWarehouseID, setMaterialWarehouseID] = useState(null);
	const [err, setErr] = useState([]);
	const [warehouses, setWarehouses] = useState(null);
	const [racks, setRacks] = useState(null);
	const [selectedWarehouse, setSelectedWarehouse] = useState(null);
	const [selectedRack, setSelectedRack] = useState(null);
	const [loading, setLoading] = useState(true);
	const [rackLoading, setRackLoading] = useState(true);
	const [editQuantity, setEditQuantity] = useState(false);

	// function useFetch() {
	async function fetchWarehouses() {
		const resWarehouse = await strapi.getEntries('warehouses');
		getWarehouses(resWarehouse);
		setLoading(false);
	}

	async function fetchRacks(id) {
		const resRacks = await strapi.getEntry('warehouses', id);
		setRackLoading(true);
		await getRacks(resRacks);
	}

	async function getWarehouses(resWarehouse) {
		let wares = [];
		resWarehouse.map(x => {
			wares = [...wares, { value: x.name, label: x.name, id: x.id }];
		});
		setWarehouses(wares);
	}

	async function getRacks(resRacks) {
		let racksArray = [];
		resRacks.racks.map(x => {
			racksArray = [...racksArray, { value: x.name, label: x.name, id: x.id }];
		});
		setRacks(racksArray);
		setRackLoading(false);
	}

	function resetSelection() {
		setData({
			material: props.materialID,
			quantity: '',
			warehouse: null,
			rack: null
		});
		setSelectedWarehouse(null);
		setSelectedRack(null);
		setRackLoading(true);
	}

	useEffect(
		() => {
			fetchWarehouses();
			if (props.materialWarehouseID !== null) {
				setMaterialWarehouseID(props.materialWarehouseID);
				async function fetchDatafromID(materialWarehouseID) {
					var type = typeof materialWarehouseID;
					if (type === 'number') {
						const res = await strapi.getEntry('materialwarehouses', materialWarehouseID);
						setData({
							quantity: res.quantity,
							warehouse: res.warehouse.id,
							rack: res.rack.id
						});
						setSelectedWarehouse({ label: res.warehouse.name, value: res.warehouse.id });
						await fetchRacks(res.warehouse.id);
						setSelectedRack({ label: res.rack.name, value: res.rack.id });
						setRackLoading(false);
					} else {
						resetSelection();
					}
				}
				fetchDatafromID(props.materialWarehouseID);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.materialWarehouseID]
	);

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit() {
		let log = {
			user: localStorage.getItem('id'),
			from_value: props.currentQuantity,
			to_value: data.quantity || props.currentQuantity,
			material: '',
			warehouse: '',
			rack: '',
			action_type: '',
			unit: props.unit,
			materialwarehouse: props.materialWarehouseID
		};
		let errors = {};
		if (!validateQuantity(data.quantity.toString())) {
			errors.Quantiy = true;
		}

		if (!_.isEmpty(errors)) {
			setErr(errors);
			return;
		}

		let res = {};
		try {
			if (props.materialWarehouseID === null) {
				res = await strapi.createEntry('materialwarehouses', data);
				log.action_type = 'ADD';
				log.materialwarehouse = res.id;
			} else {
				res = await strapi.updateEntry('materialwarehouses', materialWarehouseID, data);
				log.action_type = 'EDIT';
			}

			log.warehouse = res.warehouse.name;
			log.material = res.material.name;
			log.rack = res.rack.name;

			await strapi.createEntry('materiallogs', log);

			props.getReadyPage(props.materialID);
			setErr([]);
			resetSelection();
			props.hideModal();
			props.notify('tr', 2, modal_lang.SUCCESS);
		} catch {
			props.notify('tr', 3, modal_lang.FAIL);
		}
	}

	return (
		<Modal
			className="modal-login"
			modalClassName=" modal-primary"
			isOpen={props.ModalMaterialForm}
			toggle={props.showModal}
		>
			<Card className=" card-login card-plain">
				<div className=" modal-header justify-content-center">
					<button
						aria-label="Close"
						className=" close"
						data-dismiss="modal"
						type="button"
						onClick={props.showModal}
					>
						<span aria-hidden={true}>×</span>
					</button>
					<div className=" header header-primary text-center">
						<div className=" logo-container">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{props.title + material_form_modal_lang.WAREHOUSE_INFO}
							</h3>
						</div>
					</div>
				</div>
				<div className=" modal-body">
					<Form action="" className=" form" method="">
						<CardBody>
							{props.materialWarehouseID !== null ? (
								<Row>
									<Col sm="12">
										<Input
											type="checkbox"
											style={{
												position: 'unset',
												marginTop: 'unset',
												marginLeft: 'unset',
												transform: 'scale(1.5)'
											}}
											onClick={() => setEditQuantity(!editQuantity)}
										/>{' '}
										<span style={{ fontSize: '1.1rem' }}>ແກ້ໄຂຈຳນວນຢູ່ນີ້</span>
										<p />
									</Col>
								</Row>
							) : (
								''
							)}
							<Row>
								{editQuantity || props.materialWarehouseID === null ? (
									<Col sm="12">
										<FormGroup className={err.Quantiy ? 'has-danger' : ''}>
											<InputGroup>
												<Input
													name="quantity"
													placeholder={material_form_modal_lang.QUANTITY}
													type="text"
													style={{ borderColor: err.MaterialName && 'red' }}
													value={data.quantity || ''}
													onChange={onChange}
												/>
												<InputGroupAddon addonType="append">
													<InputGroupText>{props.unit}</InputGroupText>
												</InputGroupAddon>
											</InputGroup>
										</FormGroup>
									</Col>
								) : (
									''
								)}
								<Col sm="12">
									{loading ? (
										<p>Loading...</p>
									) : (
										<React.Fragment>
											<Select
												className="react-select primary SelectInModalFont"
												classNamePrefix="react-select"
												name="warehouse"
												value={selectedWarehouse}
												onChange={value => {
													setData({ ...data, warehouse: value.id });
													setSelectedWarehouse(value);
													setSelectedRack(null);
													fetchRacks(value.id);
												}}
												options={warehouses}
												placeholder={material_form_modal_lang.SELECT_WAREHOUSE}
											/>
											{rackLoading ? (
												''
											) : (
												<Select
													className="react-select primary SelectInModalMarginTop SelectInModalFont"
													classNamePrefix="react-select"
													name="rack"
													value={selectedRack}
													onChange={value => {
														setData({ ...data, rack: value.id });
														setSelectedRack(value);
													}}
													options={racks}
													placeholder={material_form_modal_lang.SELECT_RACK}
												/>
											)}
										</React.Fragment>
									)}
									{/* </InputGroup> */}
									<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
										{err.Quantiy && err.Quantiy}
									</p>
								</Col>
							</Row>
						</CardBody>
					</Form>
				</div>
				<div className=" modal-footer text-right">
					<Button
						block
						className=" btn-default "
						style={{ fontSize: '1.1rem' }}
						color="default"
						onClick={() => handleSubmit(props)}
					>
						{modal_lang.SUBMIT}
					</Button>
				</div>
			</Card>
		</Modal>
	);
};

export default MaterialFormModal;
