import React, { useState, useEffect } from 'react';

import { Card, CardHeader, CardBody, CardFooter, Label, Spinner, Table, Input, FormGroup, Button } from 'reactstrap';

import { withdrawal_lang } from '../../../i18n/lo-LA';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import Select from 'react-select';
import strapi, { URL } from '../../../strapi-sdk';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

import ImageUpload from '../../../components/CustomUpload/WithdrawalUpload';

import _ from 'lodash';
import WebcamCapture from '../../../utils/Webcam';
import history from '../../../history';

let mytimer;

const NormalMaterial = props => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [contentLoading, setContentLoading] = useState(true);
	const [material, setMaterial] = useState(null);
	const [materialwarehouse, setMaterialwarehouse] = useState(null);
	const [data, setData] = useState({
		withdraw_date: moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
	});
	const [quantity, setQuantity] = useState(0);
	const [closed, setClosed] = useState(true);
	const [beforeImages, setBeforeImages] = useState(null);

	const [errors, setErrors] = useState({});

	//images
	const [imageOption, setImageOption] = useState('browse');

	//webcam
	const [webcamImg, setWebcamImg] = useState(null);
	const [webcamImgForm, setWebcamImgForm] = useState(null);

	const [materialOptions, setMaterialOptions] = useState([]);
	const [materialwarehouseOptions, setMaterialwarehouseOptions] = useState([]);
	const [selectedMaterialWarehouse, setSelectedMaterialWarehouse] = useState(null);

	//loading submit
	const [submitLoading, setSubmitLoading] = useState(false);

	const loadingMessage = () => <Spinner color="primary" />;

	// SEARCH MATERIAL TYPE 2
	const searchOnInputChange = e => {
		if (e === '') return;
		clearInterval(mytimer);
		setLoading(true);
		mytimer = setTimeout(() => {
			strapi
				.request('GET', `${URL}materials?materialtype=2&name_contains=${e}`)
				.then(res => {
					assignToSelect(res);
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}, 1000);
	};

	const assignToSelect = resMt => {
		let local = [];
		resMt.map(x => {
			local = [
				...local,
				{
					value: x.name,
					label: x.name,
					id: x.id
				}
			];
		});
		setMaterialOptions(local);
	};

	const assignToSelectMaterialWarehouse = resMt => {
		let local = [];
		resMt.map(x => {
			local = [
				...local,
				{
					value: x.warehouse.name + ' (' + x.rack.name + ')',
					label: x.warehouse.name + ' (' + x.rack.name + ')',
					id: x.id,
					quantity: x.quantity
				}
			];
		});
		setMaterialwarehouseOptions(local);
	};

	const OnChange = async e => {
		try {
			setContentLoading(true);
			const res = await strapi.request('GET', `${URL}materialwarehouses?material=${e.id}`);
			const resM = await strapi.getEntry('materials', e.id);
			setData({ ...data, material: e.id });
			setMaterial(resM);
			setMaterialwarehouse(res);
			assignToSelectMaterialWarehouse(res);
			setContentLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const onChangeWDQuantity = e => {
		const e_quantity = e.target.value;
		let error = {};
		if (e_quantity > quantity) {
			error.wdquantity = 'ຈຳນວນໃນສາງມີບໍ່ພໍ!';
		}
		if (e_quantity <= 0) error.WDQuantityLessThanZero = 'ຈຳນວນຕ້ອງບໍ່ໜ້ອຍຫຼືເທົ່າກັບ 0';
		setData({ ...data, withdraw_quantity: e_quantity });
		setErrors(error);
	};

	const handleSubmit = async () => {
		setSubmitLoading(true);
		//validation
		let error = {};
		if (_.isEmpty(props.getEmployee())) error.employee = 'ກະລຸນາເລືອກພະນັກງານ';
		if (webcamImg === null && beforeImages === null) error.image = 'ກະລຸນາເລືອກຮູບ ຫຼື ຖ່າຍຮູບຂອງອຸປະກອນກ່ອນເບີກ';
		if (data.withdraw_quantity === null || data.withdraw_quantity === undefined)
			error.quantity = 'ກະລຸນາລະບຸຈຳນວນທີ່ຕ້ອງການເບີກ';

		if (!_.isEmpty(error)) {
			setVisible(true);
			ToastsStore.error('ກະລຸນາກວດສອບຂໍ້ມູນທີ່ກອກ');
			setSubmitLoading(false);
			setErrors(error);
			setTimeout(() => {
				setVisible(false);
			}, 1500);
		} else {
			const newData = {
				...data,
				employee: props.getEmployee().id,
				closed: !closed,
				material_type: 2,
				materialwarehouse: selectedMaterialWarehouse
			};
			try {
				const resCreate = await strapi.createEntry('withdrawals', newData);
				const q = { quantity: quantity - newData.withdraw_quantity };
				const form = new FormData();
				let name = new Date().getTime();
				if (imageOption === 'browse') {
					form.append('files', beforeImages, name + '.jpg');
				} else {
					form.append('files', webcamImgForm, name + '.jpg');
				}
				form.append('refId', resCreate.id);
				form.append('ref', 'withdrawal');
				form.append('source', 'content-manager');
				form.append('field', 'before_images');
				const resUpload = await strapi.upload(form);
				const resMWarehouse = await strapi.updateEntry('materialwarehouses', selectedMaterialWarehouse, q);
				let logs = {
					user: localStorage.getItem('id'),
					from_value: quantity,
					to_value: quantity - newData.withdraw_quantity,
					action_type: 'WITHDRAW',
					material: material.name,
					warehouse: resMWarehouse.warehouse.name,
					rack: resMWarehouse.rack.name,
					materialwarehouse: resMWarehouse.id,
					unit: material.unit.name,
					bill_id: resCreate.id
				};
				const materialLogs = await strapi.createEntry('materiallogs', logs);
				setVisible(true);
				ToastsStore.success(withdrawal_lang.SUCCESS);
				setTimeout(() => {
					setSubmitLoading(false);
					setVisible(false);
					history.push('/page/withdrawal-tools/');
				}, 1500);
			} catch (err) {
				setVisible(true);
				setSubmitLoading(false);
				ToastsStore.error('ເກີດຂໍ້ຜິດພາດກະລຸນາລອງໃໝ່ອີກຄັ້ງ');
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			}
		}
	};

	return (
		<Card>
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<CardHeader>{withdrawal_lang.NORMAL_MATERIAL}</CardHeader>
			<CardBody>
				<Label style={{ fontSize: '1.1rem' }}>
					{withdrawal_lang.CHOOSE_NORMAL_MATERIAL}
					<span style={{ color: 'red' }}>*</span>
				</Label>
				<Select
					className="react-select primary SelectInModalFont"
					classNamePrefix="react-select"
					name="material"
					onInputChange={searchOnInputChange}
					onChange={OnChange}
					// value={search}
					options={materialOptions}
					isLoading={loading}
					loadingMessage={loadingMessage}
					// style={{ fontSize: '1.1rem' }}
					// placeholder={withdrawal_lang.EMPLOYEE_NAME}
				/>

				{contentLoading ? null : (
					<Table bordered style={{ marginTop: '10px' }}>
						<tbody>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ width: '50%', textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WAREHOUSE} <span style={{ color: 'red' }}>*</span>
								</td>
								<td style={{ width: '50%', textAlign: 'left' }}>
									<Select
										required
										className={`react-select primary SelectInModalFont`}
										classNamePrefix="react-select"
										name="warehouses"
										// value={selectedWarehouse}
										onChange={e => {
											setSelectedMaterialWarehouse(e.id);
											setQuantity(e.quantity);
										}}
										options={materialwarehouseOptions}
										placeholder={withdrawal_lang.CHOOSE_WAREHOUSE_RACK}
									/>
									{errors.warehouse === '' ? null : (
										<span style={{ fontSize: '0.9rem', color: 'red' }}>{errors.warehouse}</span>
									)}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>{withdrawal_lang.HAVE_QUANTITY}</td>
								<td style={{ textAlign: 'center' }}>{quantity + ' ' + material.unit.name}</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_QUANTITY}
								</td>
								<td style={{ textAlign: 'center' }}>
									<Input
										type="number"
										placeholder="ໃສ່ຈຳນວນທີ່ຕ້ອງການ"
										onChange={onChangeWDQuantity}
										className={errors.wdquantity || errors.quantity ? 'has-danger' : null}
									/>
									{errors.wdquantity ? <span style={{ color: 'red' }}>{errors.wdquantity}</span> : ''}
									{errors.WDQuantityLessThanZero ? (
										<span style={{ color: 'red' }}>{errors.WDQuantityLessThanZero}</span>
									) : (
										''
									)}
									<p style={{ color: 'red' }}>{errors.quantity ? errors.quantity : null}</p>
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>ວັນທີເບີກ</td>
								<td>
									<ReactDatetime
										dateFormat="YYYY-MM-DD HH:mm:ss"
										defaultValue={moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')}
										inputProps={{
											className: 'form-control',
											placeholder: withdrawal_lang.WITHDRAWAL_DATE
										}}
										onChange={e => {
											setData({
												...data,
												withdraw_date: moment(e).format('YYYY-MM-DD HH:mm:ss')
											});
										}}
									/>
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_PHOTO}
								</td>
								<td style={{ textAlign: 'center' }}>
									<div className="form-check-radio inline">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="before_images"
												id="radio1"
												value="browse"
												onClick={e => setImageOption(e.target.value)}
												defaultChecked
											/>
											ເລືອກຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<div className="form-check-radio">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="before_images"
												id="radio2"
												value="webcam"
												onClick={e => setImageOption(e.target.value)}
											/>
											ຖ່າຍຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<p style={{ color: 'red' }}>{errors.image ? errors.image : null}</p>
									{imageOption === 'browse' ? (
										<ImageUpload
											// image_url={
											// 	data.image === undefined || data.image === null
											// 		? null
											// 		: URL + data.image.url
											// }
											setAvatar={setBeforeImages}
										/>
									) : webcamImg ? (
										<React.Fragment>
											<img src={webcamImg} />
											<Button style={{ marginTop: '10px' }} onClick={() => setWebcamImg(null)}>
												ຖ່າຍໃໝ່
											</Button>
										</React.Fragment>
									) : (
										<WebcamCapture
											setWebcamImg={setWebcamImg}
											setWebcamImgForm={setWebcamImgForm}
										/>
									)}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.REQUIRE_RETURN}
								</td>
								<td style={{ textAlign: 'center' }}>
									<FormGroup check>
										<Label check>
											<Input
												type="checkbox"
												defaultChecked={true}
												onClick={() => setClosed(!closed)}
											/>{' '}
											<span className="form-check-sign">
												<span className="check" />
											</span>
										</Label>
									</FormGroup>
								</td>
							</tr>
						</tbody>
					</Table>
				)}
			</CardBody>
			{contentLoading ? null : (
				<CardFooter style={{ textAlign: 'center' }}>
					<p style={{ color: 'red' }}>{errors.employee ? errors.employee : null}</p>
					<Button
						size="md"
						style={{ fontSize: '1.1rem' }}
						color="success"
						onClick={handleSubmit}
						disabled={submitLoading}
					>
						ສົ່ງຂໍ້ມູນ
					</Button>
				</CardFooter>
			)}
		</Card>
	);
};

export default NormalMaterial;
