import React from 'react';
import classnames from 'classnames';
import {
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container
} from 'reactstrap';

class PageNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			color: 'navbar-transparent'
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.updateColor);
	}
	componentDidUpdate(e) {
		if (
			window.outerWidth < 993 &&
			e.history.location.pathname !== e.location.pathname &&
			document.documentElement.className.indexOf('nav-open') !== -1
		) {
			document.documentElement.classList.toggle('nav-open');
		}
	}
	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: 'bg-white'
			});
		} else {
			this.setState({
				color: 'navbar-transparent'
			});
		}
	};
	// this function opens and closes the sidebar on small devices
	toggleSidebar = () => {
		document.documentElement.classList.toggle('nav-open');
	};

	toggleCollapse = () => {
		let newState = {
			collapseOpen: !this.state.collapseOpen
		};
		if (!this.state.collapseOpen) {
			newState['color'] = 'bg-white';
		} else {
			newState['color'] = 'navbar-transparent';
		}
		this.setState(newState);
	};

	render() {
		const { pathname } = this.props.location;
		const currentPage = pathname.replace('/page/', '');
		return (
			<React.Fragment>
				<Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand="lg">
					<Container fluid>
						<div className="navbar-wrapper">
							<div
								className={classnames('navbar-toggle', {
									toggled: this.state.sidebarOpen
								})}
							>
								<button className="navbar-toggler" type="button" onClick={this.toggleSidebar}>
									<span className="navbar-toggler-bar bar1" />
									<span className="navbar-toggler-bar bar2" />
									<span className="navbar-toggler-bar bar3" />
								</button>
							</div>
							<NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
								<span className="d-none d-md-block">
									<b>{currentPage}</b>
								</span>
								<span className="d-block d-md-none">{currentPage}</span>
							</NavbarBrand>
						</div>
						<button
							aria-controls="navigation-index"
							aria-expanded={this.state.collapseOpen}
							aria-label="Toggle navigation"
							className="navbar-toggler"
							// data-target="#navigation"
							data-toggle="collapse"
							type="button"
							onClick={this.toggleCollapse}
						>
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
							<span className="navbar-toggler-bar navbar-kebab" />
						</button>
						<Collapse className="justify-content-end" navbar isOpen={this.state.collapseOpen}>
							<Nav navbar>
								<UncontrolledDropdown className="btn-rotate" nav>
									<DropdownToggle
										aria-haspopup={true}
										caret
										color="default"
										data-toggle="dropdown"
										id="navbarDropdownMenuLink"
										nav
									>
										<i className="nc-icon nc-bell-55" />
										<p>
											<span className="d-lg-none d-md-block">Some Actions</span>
										</p>
									</DropdownToggle>
									<DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
										<DropdownItem href="#me" onClick={e => e.preventDefault()}>
											Action
										</DropdownItem>
										<DropdownItem href="#me" onClick={e => e.preventDefault()}>
											Another action
										</DropdownItem>
										<DropdownItem href="#me" onClick={e => e.preventDefault()}>
											Something else here
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
								<NavItem>
									<NavLink className="btn-rotate" href="#pablo" onClick={e => e.preventDefault()}>
										<i className="nc-icon nc-settings-gear-65" />
										<p>
											<span className="d-lg-none d-md-block">Account</span>
										</p>
									</NavLink>
								</NavItem>
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</React.Fragment>
		);
	}
}

export default PageNavbar;
