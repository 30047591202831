import React, { useState, useEffect } from 'react';

import { Modal, ModalBody, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Moment from 'react-moment';

import './Log.css';
import strapi, { URL } from '../../../../strapi-sdk';

import { material_log_modal } from '../../../../i18n/lo-LA';
import { Link } from 'react-router-dom';

import _ from 'lodash';

const MaterialLogModal = props => {
	const [modal, setModal] = useState(false);
	const [logs, setLogs] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [countPages, setCountPages] = useState(0);

	const LIMIT = 10;

	useEffect(
		() => {
			async function counting() {
				const countPage = await strapi.request(
					'GET',
					`${URL}materiallogs/count/?materialwarehouse=${props.id}`
				);
				setCountPages(Math.ceil(countPage / LIMIT));
				updatePage(page);
			}
			counting();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const updatePage = async p => {
		setPage(p);
		setLoading(true);
		const res = await strapi.request(
			'GET',
			`${URL}materiallogs?materialwarehouse=${props.id}&_start=${(p - 1) * 10}&_limit=${p * 10}&_sort=id:DESC`
		);

		setLogs(res);
		setLoading(false);
	};

	const toggle = () => {
		setModal(!modal);
	};

	let count = 0;

	return (
		<React.Fragment>
			<span onClick={toggle} className="title">
				{props.title}
			</span>
			<Modal isOpen={modal} toggle={toggle} size="lg" style={{ maxWidth: '1600px', width: '80%' }}>
				<div className="modal-header justify-content-center">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggle}>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{material_log_modal.HISTORY}</h5>
				</div>
				<ModalBody>
					{loading ? (
						'Loading...'
					) : (
						<React.Fragment>
							<Table size="sm" striped>
								<thead>
									<tr>
										{/* <th>#</th> */}
										<th>{material_log_modal.USERNAME}</th>
										<th style={{ textAlign: 'center' }}>{material_log_modal.BILL_ID}</th>
										<th>{material_log_modal.MATERIAL}</th>
										<th>{material_log_modal.WAREHOUSE}</th>
										<th>{material_log_modal.RACK}</th>
										<th>{material_log_modal.METHOD}</th>
										<th>{material_log_modal.FROM}</th>
										<th>{material_log_modal.TO}</th>
										<th>{material_log_modal.TIME}</th>
									</tr>
								</thead>
								<tbody>
									{logs.map((log, idx) => {
										count += 1;

										return (
											<tr key={log.id}>
												{/* <th scope="row">{idx + 1}</th> */}
												<td>{log.user.username}</td>
												{log.action_type === 'WITHDRAW' || log.action_type === 'RETURN' ? (
													<td style={{ textAlign: 'center' }}>
														<Link to={`/page/withdrawal-tools/result/${log.bill_id}`}>
															#{_.padStart(log.bill_id, 4, 0)}
														</Link>
													</td>
												) : (
													<td style={{ textAlign: 'center' }}>
														#{_.padStart(log.bill_id, 4, 0)}
													</td>
												)}

												<td>{log.material}</td>
												<td>{log.warehouse}</td>
												<td>{log.rack}</td>
												<td>{log.action_type}</td>
												<td>{log.from_value + ' ' + log.unit}</td>
												<td>{log.to_value + ' ' + log.unit}</td>
												<td>
													<Moment format="D/MMM/YYYY-HH:mm:ss" withTitle>
														{log.created_at}
													</Moment>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>

							<Pagination listClassName="justify-content-center">
								{page - 1 === 0 ? null : (
									<PaginationItem>
										<PaginationLink tag="span" onClick={() => updatePage(page - 1)}>
											{material_log_modal.PREVIOUS}
										</PaginationLink>
									</PaginationItem>
								)}
								{_.range(1, countPages + 1).map(p => (
									<PaginationItem key={p} active={page === p ? true : false}>
										<PaginationLink tag="span" onClick={() => updatePage(p)}>
											{p}
										</PaginationLink>
									</PaginationItem>
								))}
								{page === countPages ? null : (
									<PaginationItem>
										<PaginationLink tag="span" onClick={() => updatePage(page + 1)}>
											{material_log_modal.NEXT}
										</PaginationLink>
									</PaginationItem>
								)}
							</Pagination>
						</React.Fragment>
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default MaterialLogModal;
