import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Table, FormGroup, Label, Input } from 'reactstrap';
import { withdrawal_lang } from '../../../i18n/lo-LA';
import strapi, { URL } from '../../../strapi-sdk';
import moment from 'moment';

const ReturnDetail = props => {
	const withdrawal = props.withdrawal;
	const [materialwarehouse, setMaterialwarehouse] = useState('');
	const [units, setUnits] = useState(null);
	const [loadingUnits, setLoadingUnits] = useState(true);

	useEffect(() => {
		const fetchUnits = () => {
			strapi.getEntries('units').then(res => {
				setUnits(res);
				setLoadingUnits(false);
			});
		};
		fetchUnits();
	}, []);

	const getMaterialwarehouse = id => {
		strapi.getEntry('materialwarehouses', id).then(res => {
			setMaterialwarehouse(res.warehouse.name + ' (' + res.rack.name + ')');
		});
	};

	const getUnit = id => {
		return units.filter(unit => unit.id === id)[0].name;
	};
	return (
		<Card>
			<CardHeader>
				<h3 style={{ marginBottom: 'unset', borderBottom: 'dashed', borderWidth: '2px', borderColor: '#ccc' }}>
					{withdrawal_lang.RETURN_DETAIL}
				</h3>
			</CardHeader>
			<CardBody>
				<Table striped>
					<tbody>
						<tr style={{ fontSize: '1rem' }}>
							<td style={{ textAlign: 'right' }}>{withdrawal_lang.MATERIAL_NAME}</td>
							<td style={{ textAlign: 'center' }}>{withdrawal.material.name}</td>
						</tr>
						<tr style={{ fontSize: '1rem' }}>
							<td style={{ textAlign: 'right' }}>{withdrawal_lang.WAREHOUSE}</td>
							<td style={{ textAlign: 'center' }}>
								{getMaterialwarehouse(withdrawal.materialwarehouse.id)}
								{materialwarehouse}
							</td>
						</tr>
						<tr style={{ fontSize: '1rem' }}>
							<td style={{ textAlign: 'right' }}>{withdrawal_lang.RETURN_QUANTITY}</td>
							<td style={{ textAlign: 'center' }}>
								{loadingUnits
									? null
									: withdrawal.return_quantity + ' ' + getUnit(withdrawal.material.unit)}
							</td>
						</tr>
						<tr style={{ fontSize: '1rem' }}>
							<td style={{ textAlign: 'right' }}>{withdrawal_lang.RETURN_DATE}</td>
							<td style={{ textAlign: 'center' }}>
								{moment(withdrawal.return_date).format('DD/MM/YYYY HH:mm:ss')}
							</td>
						</tr>
						{withdrawal.material_type === '3' ? (
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right' }}>{withdrawal_lang.MATERIAL_STATUS}</td>
								<td style={{ textAlign: 'center' }}>
									{withdrawal.return_status === 'new' ? withdrawal_lang.STATUS_NEW : null}
									{withdrawal.return_status === 'broken' ? (
										<span style={{ color: 'red' }}>withdrawal_lang.STATUS_BROKEN</span>
									) : null}
									{withdrawal.return_status === 'good' ? withdrawal_lang.STATUS_GOOD : null}
									{withdrawal.return_status === 'lost' ? (
										<span style={{ color: 'red' }}>withdrawal_lang.STATUS_LOST</span>
									) : null}
								</td>
							</tr>
						) : null}
						<tr style={{ fontSize: '1rem' }}>
							<td style={{ textAlign: 'right' }}>{withdrawal_lang.RETURN_IMAGE}</td>
							<td style={{ textAlign: 'center' }}>
								{withdrawal.return_images.map(image => (
									<img key={image.id} src={URL + image.url} width="200" />
								))}
							</td>
						</tr>
					</tbody>
				</Table>
			</CardBody>
		</Card>
	);
};

export default ReturnDetail;
