import React, { useState } from 'react';
import strapi from '../../../strapi-sdk';

import { Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import useGlobal from '../../../store';

import _ from 'lodash';

const BranchTable = props => {
	const [globalState, globalActions] = useGlobal();
	const [alert, setAlert] = useState(null);

	// fetch data from API
	// using Strapi SDK
	// Update fetched data to Global State
	const useFetchBranches = () => {
		const [globalState, globalActions] = useGlobal();

		const fetchData = async () => {
			const res = await strapi.getEntries('branches');
			globalActions.updateBranchesList(res);
		};

		if (_.isEmpty(globalState.branches)) {
			fetchData();
		}
		return null;
	};
	useFetchBranches();
	// END useFetchBranches

	// Confirm Alert Box before delete item
	const confirmMessage = (props, item) => {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title="ທ່ານແນ່ໃຈແລ້ວບໍ່?"
				onConfirm={() => BranchDelete(props, item.id)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText="ແນ່ໃຈ, ລຶບດຽວນີ້!"
				cancelBtnText="ຍົກເລີກ"
				showCancel
			>
				ທ່ານຕ້ອງການລຶບສາຂາ <span style={{ fontWeight: 700, color: 'red' }}>{item.name}</span> ແມ່ນບໍ່?
			</ReactBSAlert>
		);
	};
	// END Alert Box

	// BranchDelete function perform deleting item
	// and then update the global state of Branches by refetch data from API
	async function BranchDelete(props, id) {
		try {
			await strapi.deleteEntry('branches', id);
			globalActions.updateBranchesList(await strapi.getEntries('branches'));
			successDelete();
		} catch {
			cancelDetele();
		}
	}
	// END BranchDelete

	// Success Message of Alert Box
	const successDelete = () => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="ສຳເລັດ!"
				onConfirm={() => setAlert(null)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
			>
				ສາຂາທີ່ທ່ານເລືອກຖືກລຶບຮຽບຮ້ອຍແລ້ວ
			</ReactBSAlert>
		);
	};
	// End Success Message box

	// Error alert box after click on delete
	const cancelDetele = () => {
		setAlert(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title="ຜິດພາດ"
				onConfirm={() => setAlert(null)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
			>
				ບໍ່ສາມາດລຶບສາຂາດັ່ງກ່າວໄດ້
			</ReactBSAlert>
		);
	};
	// End Error alert box

	return (
		<React.Fragment>
			{alert}
			<tbody>
				{_.isEmpty(globalState.branches) ? (
					<tr>
						<td>Loading...</td>
					</tr>
				) : (
					globalState.branches.map((item, idx) => (
						<tr key={item.id}>
							<td>{idx + 1}</td>
							<td>{item.name}</td>
							<td>{item.tel}</td>
							<td className="text-right">
								<Button
									className="btn-icon"
									color="success"
									size="sm"
									onClick={() => props.showModal(item.id)}
								>
									<i className="fa fa-edit" />
								</Button>
								{` `}
								<Button
									className="btn-icon"
									color="danger"
									size="sm"
									onClick={() => confirmMessage(props, item)}
								>
									<i className="fa fa-times" />
								</Button>
							</td>
						</tr>
					))
				)}
			</tbody>
		</React.Fragment>
	);
};

export default BranchTable;
