import React, { useState, useEffect } from 'react';

import {
	Button,
	Modal,
	Input,
	Card,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Form,
	CardBody,
	Row,
	Col
} from 'reactstrap';

import Select from 'react-select';

import strapi from '../../../strapi-sdk';

import useGlobal from '../../../store';

import _ from 'lodash';

import { validateName } from '../../../utils/validate';
import { category_lang, modal_lang } from '../../../i18n/lo-LA';

const CategoryModal = props => {
	const [globalState, globalActions] = useGlobal();
	const [data, setData] = useState({ name: '', category: null });
	const [contentID, setContentID] = useState(null);
	const [err, setErr] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(true);

	// function useFetch() {
	async function fetchCategories() {
		const resCategory = await strapi.getEntries('categories');
		getCategories(resCategory);
	}

	useEffect(
		() => {
			fetchCategories();
			if (props.contentID !== null) {
				setContentID(props.contentID);
				async function fetchDatafromID(contentID) {
					var type = typeof contentID;
					if (type === 'number') {
						const res = await strapi.getEntry('subcategories', contentID);
						setData({ name: res.name, category: res.category });
						setSelectedCategory({ label: res.category.name, value: res.category.id });
					}
				}
				fetchDatafromID(props.contentID);
			} else {
				setData({ name: '', category: null });
				setSelectedCategory(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.contentID]
	);

	async function getCategories(cates) {
		let cats = [];
		cates.map(cate => {
			cats = [...cats, { value: cate.name, label: cate.name, id: cate.id }];
		});
		setCategories(cats);
		setLoading(false);
	}

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit(props) {
		let errors = {};
		if (!validateName(data.name)) {
			errors.subCategoryName = modal_lang.VALIDATE_NAME;
		}

		if (!_.isEmpty(errors)) {
			setErr(errors);
			return;
		}

		try {
			if (props.contentID === null) {
				await strapi.createEntry('subcategories', data);
			} else {
				await strapi.updateEntry('subcategories', contentID, data);
			}
			globalActions.updateSubCategoriesList(await strapi.getEntries('subcategories'));
			setData({ name: '', category: null });
			setContentID(null);
			setErr([]);
			setSelectedCategory(null);
			props.hideModal();
			props.notify('tr', 2, modal_lang.SUCCESS);
		} catch {
			props.notify('tr', 3, modal_lang.FAIL);
		}
	}

	return (
		<Modal
			className="modal-login"
			modalClassName=" modal-primary"
			isOpen={props.ModalSubCategory}
			toggle={props.showModal}
		>
			<Card className=" card-login card-plain">
				<div className=" modal-header justify-content-center">
					<button
						aria-label="Close"
						className=" close"
						data-dismiss="modal"
						type="button"
						onClick={props.showModal}
					>
						<span aria-hidden={true}>×</span>
					</button>
					<div className=" header header-primary text-center">
						<div className=" logo-container">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{props.title + category_lang.CATEGORY}
							</h3>
						</div>
					</div>
				</div>
				<div className=" modal-body">
					<Form action="" className=" form" method="">
						<CardBody>
							<Row>
								<Col sm="12">
									<InputGroup size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className=" nc-icon nc-shop" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="name"
											placeholder={category_lang.CATEGORY_NAME}
											type="text"
											style={{ borderColor: err.subCategoryName && 'red' }}
											value={data.name}
											onChange={onChange}
										/>
									</InputGroup>
								</Col>
								<Col sm="12">
									{loading ? (
										<p>Loading...</p>
									) : (
										<Select
											className="react-select primary"
											classNamePrefix="react-select"
											name="category"
											value={selectedCategory}
											onChange={value => {
												setData({ ...data, category: value.id });
												setSelectedCategory(value);
											}}
											options={categories}
											placeholder="ເລືອກປະເພດຫຼັກ"
										/>
									)}
									{/* </InputGroup> */}
									<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
										{err.subCategoryName && err.subCategoryName}
									</p>
								</Col>
							</Row>
						</CardBody>
					</Form>
				</div>
				<div className=" modal-footer text-right">
					<Button
						block
						className=" btn-default "
						style={{ fontSize: '1.1rem' }}
						color="default"
						onClick={() => handleSubmit(props)}
					>
						{modal_lang.SUBMIT}
					</Button>
				</div>
			</Card>
		</Modal>
	);
};

export default CategoryModal;
