import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import Strapi from 'strapi-sdk-javascript/build/main';

import history from '../history';
import { AUTH_URL } from '../strapi-sdk';

const apiUrl = process.env.API_URL || AUTH_URL;
const strapi = new Strapi(apiUrl);

export const strapiRegister = (username, email, password) => {
	if (!process.browser) {
		return undefined;
	}
	strapi.register(username, email, password).then(res => {
		setToken(res);
	});
	return Promise.resolve();
};
//use strapi to get a JWT and token object, save
//to approriate cookei for future requests
export const strapiLogin = (email, password) => {
	if (!process.browser) {
		return;
	}
	// Get a token
	return strapi.login(email, password).then(res => {
		setToken(res);
	});
	// return Promise.resolve();
};

export const setToken = token => {
	if (!process.browser) {
		return;
	}
	Cookies.set('username', token.user.username);
	Cookies.set('jwt', token.jwt);
	localStorage.setItem('id', token.user.id);
	localStorage.setItem('username', token.user.username);
	localStorage.setItem('jwt', token.jwt);

	// if (localStorage.getItem('username')) {
	// 	history.push('/');
	// }
};

export const unsetToken = () => {
	if (!process.browser) {
		return;
	}
	Cookies.remove('jwt');
	Cookies.remove('username');
	localStorage.removeItem('id');
	localStorage.removeItem('jwt');
	localStorage.removeItem('username');

	// to support logging out from all windows
	window.localStorage.setItem('logout', Date.now());
	history.push('/');
};

export const getUserFromServerCookie = req => {
	if (!req.headers.cookie || '') {
		return undefined;
	}

	let username = req.headers.cookie.split(';').find(user => user.trim().startsWith('username='));
	if (username) {
		username = username.split('=')[1];
	}

	const jwtCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith('jwt='));
	if (!jwtCookie) {
		return undefined;
	}
	const jwt = jwtCookie.split('=')[1];
	return jwtDecode(jwt), username;
};

export const getUserFromLocalCookie = () => {
	return Cookies.get('username');
};

export const getUserFromLocalStorage = () => {
	return localStorage.get('username');
};

//these will be used if you expand to a provider such as Auth0
const getQueryParams = () => {
	const params = {};
	window.location.href.replace(/([^(?|#)=&]+)(=([^&]*))?/g, ($0, $1, $2, $3) => {
		params[$1] = $3;
	});
	return params;
};
export const extractInfoFromHash = () => {
	if (!process.browser) {
		return undefined;
	}
	const { id_token, state } = getQueryParams();
	return { token: id_token, secret: state };
};
