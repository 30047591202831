import React, { useState, useEffect } from 'react';

import { Card, CardHeader, CardBody, Row, Col, Button, Label, Spinner, Table } from 'reactstrap';

import { Link } from 'react-router-dom';

import { withdrawal_lang } from '../../../i18n/lo-LA';

import Select from 'react-select';
import strapi, { URL } from '../../../strapi-sdk';
import LongTermMaterial from '../../components/Withdrawal/LongTermMaterial';

import useGlobal from '../../../store';
import NormalMaterial from '../../components/Withdrawal/NormalMaterial';

let mytimer;

const WithDrawalAdd = props => {
	const [globalState, globalActions] = useGlobal();

	const [loadingEmp, setLoadingEmp] = useState(false);
	const [EmployeesOption, setEmployeesOption] = useState([]);
	const [Employee, setEmployee] = useState(null);
	const [Departments, setDepartments] = useState(null);

	// Material Types
	const [materialTypeOptions, setMaterialTypeOptions] = useState([]);
	const [loadingMTypes, setLoadingMTypes] = useState(true);
	const [materialType, setMaterialType] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const resDep = await strapi.getEntries('departments');
			const resMTypes = await strapi.getEntries('materialtypes');
			setDepartments(resDep);
			assignMtypesToSelect(resMTypes);
		};
		fetchData();
	}, []);

	const loadingMessage = () => <Spinner color="primary" />;

	// EMPLOYEE SIDEBAR
	const searchOnInputChange = e => {
		if (e === '') return;
		clearInterval(mytimer);
		setLoadingEmp(true);
		mytimer = setTimeout(() => {
			strapi
				.request('GET', `${URL}employees?fullname_contains=${e}`)
				.then(res => {
					assignEmpToSelect(res);
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					setLoadingEmp(false);
				});
		}, 1000);
	};

	const EmpOnChange = async e => {
		const emp = await strapi.getEntry('employees', e.id);
		setEmployee(emp);
		globalActions.updateWithdrawEmp(emp);
	};

	const assignEmpToSelect = resEmp => {
		let local_Emp = [];
		resEmp.map(x => {
			local_Emp = [...local_Emp, { value: x.fullname, label: x.fullname, id: x.id }];
		});
		setEmployeesOption(local_Emp);
	};

	const assignMtypesToSelect = resMTypes => {
		setLoadingMTypes(true);
		let local_MTypes = [];
		resMTypes.map(x => {
			local_MTypes = [...local_MTypes, { value: x.name, label: x.name, id: x.id }];
		});
		const newArr = local_MTypes.filter(item => item.id !== 1);
		setMaterialTypeOptions(newArr);
		setLoadingMTypes(false);
	};

	const DepartmentById = id => {
		return Departments.filter(dp => dp.id === id);
	};

	const getEmployee = () => {
		return Employee;
	};

	const onMTypeChange = e => {
		setMaterialType(e);
	};

	return (
		<div className="content">
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader
							tag="h3"
							style={{
								padding: 5,
								textAlign: 'center'
							}}
							className="d-flex"
						>
							<div className="">
								<Link to="/page/withdrawal-tools/" className="">
									<Button color="danger" style={{ fontSize: '1.1rem' }}>
										<i className="fas fa-arrow-circle-left" /> {withdrawal_lang.BACK}
									</Button>
								</Link>
							</div>
							<div className="w-75 align-self-center">{withdrawal_lang.CREATE_WITHDRAWAL}</div>
						</CardHeader>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={5}>
					<Card>
						<CardBody>
							<Label style={{ fontSize: '1.1rem' }}>
								{withdrawal_lang.CHOOSE_EMPLOYEE}
								<span style={{ color: 'red' }}>*</span>
							</Label>
							<Select
								className="react-select primary SelectInModalFont"
								classNamePrefix="react-select"
								name="employee"
								onInputChange={searchOnInputChange}
								onChange={EmpOnChange}
								// value={search}
								options={EmployeesOption}
								isLoading={loadingEmp}
								loadingMessage={loadingMessage}
								style={{ fontSize: '1.1rem' }}
								placeholder={withdrawal_lang.EMPLOYEE_NAME}
							/>
							<br />
							{Employee !== null ? (
								<React.Fragment>
									<center>
										<img src={URL + Employee.image.url} style={{ width: '200px' }} />
									</center>
									<br />
									<Table bordered>
										<tbody style={{ fontSize: '1.1rem' }}>
											<tr>
												<td style={{ textAlign: 'right', fontWeight: '700' }}>
													{withdrawal_lang.NAME}
												</td>
												<td style={{ textAlign: 'center' }}>{Employee.fullname}</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'right', fontWeight: '700' }}>
													{withdrawal_lang.GENDER}
												</td>
												<td style={{ textAlign: 'center' }}>{Employee.gender}</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'right', fontWeight: '700' }}>
													{withdrawal_lang.BRANCH}
												</td>
												<td style={{ textAlign: 'center' }}>{Employee.branch.name}</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'right', fontWeight: '700' }}>
													{withdrawal_lang.DEPARTMENT}
												</td>
												<td style={{ textAlign: 'center' }}>
													{Employee.employeedetail.map(dp => (
														<p key={dp.id}>{DepartmentById(dp.department)[0].name}</p>
													))}
												</td>
											</tr>
										</tbody>
									</Table>
								</React.Fragment>
							) : null}
						</CardBody>
					</Card>
				</Col>
				<Col sm={12} md={7}>
					<Row>
						<Col md={12}>
							<Card>
								<CardBody>
									<Label style={{ fontSize: '1.1rem' }}>
										{withdrawal_lang.MATERIAL_TYPE}
										<span style={{ color: 'red' }}>*</span>
									</Label>
									<Select
										className="react-select primary SelectInModalFont"
										classNamePrefix="react-select"
										name="materialtypes"
										// onInputChange={searchOnInputChange}
										onChange={onMTypeChange}
										// value={search}
										options={materialTypeOptions}
										isLoading={loadingMTypes}
										loadingMessage={loadingMessage}
										style={{ fontSize: '1.1rem' }}
										placeholder={withdrawal_lang.CHOOSE_MATERIAL_TYPE}
										isSearchable={false}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							{materialType !== null && materialType.id === 2 ? (
								<NormalMaterial getEmployee={getEmployee} />
							) : null}
							{materialType !== null && materialType.id === 3 ? (
								<LongTermMaterial getEmployee={getEmployee} />
							) : null}
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default WithDrawalAdd;
