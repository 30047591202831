import React, { useEffect, useState } from 'react';

import {
	Card,
	CardBody,
	CardHeader,
	Row,
	Col,
	Input,
	FormGroup,
	Label,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	CardFooter,
	Button
} from 'reactstrap';

import Select from 'react-select';

import { Link } from 'react-router-dom';
import history from '../../../history';

import _ from 'lodash';

import ImageUpload from '../../../components/CustomUpload/ImageUpload';

import { employee_lang, employee_form_lang } from '../../../i18n/lo-LA';
import strapi, { URL } from '../../../strapi-sdk';

import '../../../assets/scss/selectModal.css';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

const EmployeeForm = props => {
	const initDep = { department: null, subdepartment: null };

	//response
	const [ResBranches, setResBranches] = useState(null);
	const [ResDepartments, setResDepartments] = useState(null);

	// Refactored for select
	const [branches, setBranches] = useState(null);
	const [departmentsList, setDepartmentsList] = useState(null);
	const [SubDepartmentList, setSubDepartmentList] = useState([]);

	const [loading, setLoading] = useState(true);

	//avatar
	const [avatar, setAvatar] = useState(null);

	// Selected Branches
	const [singleSelect, setSingleSelect] = useState(null);

	// Array of selected Department and SubDepartment
	const [depArray, setDepArray] = useState([]);

	//toast visible
	const [visible, setVisible] = useState(false);

	const [data, setData] = useState({
		fullname: '',
		gender: 'ຊາຍ',
		branch: singleSelect,
		salary: ''
	});

	const [errors, setErrors] = useState({});

	useEffect(() => {
		function Initialize() {
			var branches = strapi.getEntries('branches');
			var departments = strapi.getEntries('departments');
			Promise.all([branches, departments])
				.then(values => {
					setResBranches(values[0]);
					setResDepartments(values[1]);
					//Refactoring
					refactorBranches(values[0]);
					refactorDept(values[1]);
				})
				.catch(err => {
					ToastsStore.error(employee_form_lang.GENERAL_ERROR);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		Initialize();
	}, []);

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function refactorBranches(resWarehouse) {
		let local_branches = [];
		resWarehouse.map(x => {
			local_branches = [...local_branches, { value: x.name, label: x.name, id: x.id }];
		});
		setBranches(local_branches);
	}
	async function refactorDept(resDept) {
		let local_dept = [];
		resDept.map(x => {
			local_dept = [...local_dept, { value: x.name, label: x.name, id: x.id }];
		});
		setDepartmentsList(local_dept);
	}

	async function refactorSubDept(id, idx) {
		const ResSubDept = ResDepartments.filter(dept => dept.id === id)[0].subdepartments;
		let local_sub_dept = [];
		ResSubDept.map(x => {
			local_sub_dept = [...local_sub_dept, { value: x.name, label: x.name, id: x.id }];
		});
		let newArr = [...SubDepartmentList];
		newArr[idx] = local_sub_dept;
		setSubDepartmentList(newArr);
	}

	const handleSubmit = () => {
		setErrors({});
		let y = {
			data: {
				employeedetail: depArray
			}
		};

		let local_errors = {};
		if (avatar === null) local_errors.avatar = 'ກະລຸນາເລືອກຮູບປະຈຳໂຕພະນັກງານ';
		if (_.isEmpty(data.fullname)) local_errors.fullname = 'ກະລຸນາໃສ່ຊື່ພະນັກງານ';
		if (!data.branch) local_errors.branch = 'ກະລຸນາເລືອກສາຂາຂອງພະນັກງານ';
		if (_.isEmpty(data.salary)) local_errors.salary = 'ກະລຸນາລະບຸເງິນເດືອນຂອງພະນັກງານ';
		if (_.isEmpty(local_errors)) {
			// depArray.forEach(e => (e.employee = 6));
			strapi
				.createEntry('employees', data)
				.then(res => {
					if (avatar !== null) {
						const form = new FormData();
						form.append('files', avatar, avatar.name);
						form.append('refId', res.id);
						form.append('ref', 'employee');
						form.append('source', 'content-manager');
						form.append('field', 'image');

						strapi
							.upload(form)
							.then(() => {})
							.catch(err => {
								setVisible(true);
								ToastsStore.error(`${employee_form_lang.SUB_DEPARTMENT_TOAST_ERROR}`);
							})
							.finally(() => {
								setTimeout(() => {
									setVisible(false);
									return;
								}, 1500);
							});
					}

					depArray.forEach(e => (e.employee = res.id));
					strapi
						.request('POST', `${URL}employeedetails/add`, y)
						.then(res => {
							setVisible(true);
							ToastsStore.success(`${employee_form_lang.ADDED_EMPLOYEE}`);
						})
						.catch(err => {
							setVisible(true);
							ToastsStore.error(`${employee_form_lang.SUB_DEPARTMENT_TOAST_ERROR}`);
						});
				})
				.catch(err => {
					setVisible(true);
					ToastsStore.error(`${employee_form_lang.DEPARTMENT_TOAST_ERROR}`);
				})
				.finally(() => {
					setTimeout(() => {
						setVisible(false);
						history.push('/page/employees/');
					}, 1500);
				});
		} else {
			setErrors(local_errors);
		}
	};

	const handleAddDept = () => {
		setDepArray(depArray.concat([initDep]));
	};

	const handleDeptChange = (idx, name) => evt => {
		const newDepArray = depArray.map((dep, didx) => {
			if (idx !== didx) return dep;
			if (name === 'department') return { ...dep, department: evt.id };
			if (name === 'subdepartment') return { ...dep, subdepartment: evt.id };
		});
		refactorSubDept(evt.id, idx);
		setDepArray(newDepArray);
	};

	const handleRemoveDept = idx => () => {
		setSubDepartmentList(SubDepartmentList.filter((s, sidx) => idx !== sidx));
		setDepArray(depArray.filter((s, didx) => idx !== didx));
	};

	return (
		<div className="content">
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<Row>
				<Col sm="12" md={{ size: 10, offset: 1 }}>
					<Card>
						<CardHeader
							tag="h3"
							style={{
								textAlign: 'center',
								borderBottom: 'dashed',
								borderColor: '#ccc',
								borderWidth: '2px',
								fontWeight: 'bold',
								padding: 10
							}}
							className="d-flex"
						>
							<div className="">
								<Link to="/page/employees/" className="">
									<Button color="danger" style={{ fontSize: '1.1rem' }}>
										<i className="fas fa-arrow-circle-left" /> ກັບຄືນ
									</Button>
								</Link>
							</div>
							<div className="w-75 align-self-center">{employee_form_lang.ADD_EMPLOYEE}</div>
						</CardHeader>
						<CardBody>
							{loading ? (
								'Loading...'
							) : (
								<Row>
									<Col
										sm={12}
										md={6}
										style={{
											textAlign: 'center',
											borderRight: 'dashed',
											borderColor: '#ccc',
											borderWidth: '1px'
										}}
									>
										<Row>
											<Col sm="12" md={{ size: 6, offset: 3 }}>
												<ImageUpload
													image_url={
														data.image === undefined || data.image === null
															? null
															: URL + data.image.url
													}
													setAvatar={setAvatar}
												/>
												<span style={{ color: 'red', textAlign: 'left', fontSize: '1rem' }}>
													{errors.avatar}
												</span>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<FormGroup>
													<InputGroup className={errors.fullname ? 'has-danger' : ''}>
														<Input
															type="text"
															name="fullname"
															placeholder={employee_lang.FULLNAME}
															style={{ fontSize: '1.1rem' }}
															onChange={onChange}
														/>
													</InputGroup>
												</FormGroup>
												<span style={{ color: 'red', textAlign: 'left', fontSize: '1rem' }}>
													{errors.fullname}
												</span>
											</Col>
										</Row>
										<Row style={{ marginTop: '10px' }}>
											<Col md={6}>
												<div className="form-check-radio">
													<Label check style={{ fontSize: '1.1rem' }}>
														<Input
															defaultChecked
															defaultValue="ຊາຍ"
															id="gender"
															name="gender"
															type="radio"
															onChange={onChange}
														/>
														{employee_form_lang.MALE} <span className="form-check-sign" />
													</Label>
												</div>
											</Col>
											<Col md={6}>
												<div className="form-check-radio">
													<Label check style={{ fontSize: '1.1rem' }}>
														<Input
															defaultValue="ຍິງ"
															id="gender"
															name="gender"
															type="radio"
															onChange={onChange}
														/>
														{employee_form_lang.FEMALE} <span className="form-check-sign" />
													</Label>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<Select
													className="react-select primary SelectInModalFont"
													classNamePrefix="react-select"
													name="singleSelect"
													value={singleSelect}
													onChange={value => {
														setData({ ...data, branch: value.id });
														setSingleSelect(value);
													}}
													options={branches}
													placeholder={employee_form_lang.CHOOSE_BRANCH}
												/>
												<span style={{ color: 'red', textAlign: 'left', fontSize: '1rem' }}>
													{errors.branch}
												</span>
											</Col>
										</Row>
										<Row style={{ marginTop: '10px' }}>
											<Col md={12}>
												<FormGroup>
													<InputGroup className={errors.salary ? 'has-danger' : ''}>
														<Input
															name="salary"
															type="text"
															placeholder={employee_form_lang.SALARY}
															style={{ fontSize: '1.1rem' }}
															onChange={onChange}
														/>
														<InputGroupAddon addonType="prepend">
															<InputGroupText>{employee_form_lang.KIP}</InputGroupText>
														</InputGroupAddon>
													</InputGroup>
												</FormGroup>
												<span style={{ color: 'red', textAlign: 'left', fontSize: '1rem' }}>
													{errors.salary}
												</span>
											</Col>
										</Row>
									</Col>
									<Col sm={12} md={6}>
										<Row>
											<Col
												md={12}
												style={{
													fontSize: '1.1rem',
													borderColor: '#ccc'
												}}
											>
												<Col sm="12" md={{ size: 6, offset: 3 }}>
													<Button
														style={{ marginTop: 0 }}
														color="info"
														onClick={handleAddDept}
													>
														<i className="fas fa-plus-circle" /> ເພີ່ມເຂົ້າພະແນກ
													</Button>
												</Col>
											</Col>
										</Row>
										{depArray.map((dep, idx) => (
											<React.Fragment key={idx}>
												<div
													style={{
														border: 'dashed',
														borderWidth: '2px',
														padding: 10,
														borderColor: '#ccc'
													}}
												>
													<Row>
														<Col sm={8}>
															<Select
																className="react-select primary SelectInModalFont"
																classNamePrefix="react-select"
																name="department"
																// value={singleSelect}
																onChange={handleDeptChange(idx, 'department')}
																options={departmentsList}
																placeholder={employee_form_lang.CHOOSE_DEPARTMENT}
															/>
															<div style={{ marginTop: 10 }} />
															<Select
																className="react-select primary SelectInModalFont"
																classNamePrefix="react-select"
																name="subdepartment"
																// value={singleSelect}
																onChange={handleDeptChange(idx, 'subdepartment')}
																options={SubDepartmentList[idx]}
																isDisabled={
																	_.isEmpty(SubDepartmentList[idx]) ? true : false
																}
																placeholder={
																	_.isEmpty(SubDepartmentList[idx])
																		? employee_form_lang.NO_SUB_DEPARTMENT
																		: employee_form_lang.CHOOSE_SUB_DEPARTMENT
																}
															/>
														</Col>
														<Col sm={4}>
															<Button
																color="danger"
																size="lg"
																style={{
																	height: '100%',
																	margin: 'unset',
																	maxWidth: '100%',
																	width: '100%'
																}}
																onClick={handleRemoveDept(idx)}
															>
																<i className="fas fa-minus-circle" />
															</Button>
														</Col>
													</Row>
												</div>
												<div style={{ marginTop: 10 }} />
											</React.Fragment>
										))}
									</Col>
								</Row>
							)}
						</CardBody>
						<CardFooter
							style={{
								textAlign: 'center',
								borderTop: 'dashed',
								borderColor: '#ccc',
								borderWidth: '2px'
							}}
						>
							<Button
								style={{ fontSize: '1.1rem' }}
								onClick={handleSubmit}
								disabled={visible ? true : false}
							>
								{employee_form_lang.SUBMIT}
							</Button>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default EmployeeForm;
