export const branch_lang = {
	ID: 'ອັນດັບ',
	NAME: 'ຊື່ສາຂາ',
	TEL: 'ເບີຕິດຕໍ່',
	ACTION: 'ຕົວເລືອກ',
	BRANCHES_LIST: 'ລາຍການສາຂາທັງໝົດ',
	ADD: 'ເພີ່ມໃໝ່'
};

export const category_lang = {
	ID: 'ອັນດັບ',
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	ALL_CATEGORY: 'ລາຍການປະເພດສິນຄ້າທັງໝົດ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	CATEGORY_NAME: 'ຊື່ປະເພດ',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບປະເພດ',
	CATEGORY: 'ປະເພດ'
};

export const subcategory_lang = {
	ID: 'ອັນດັບ',
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	ALL_CATEGORY: 'ລາຍການປະເພດສຳຮອງທັງໝົດ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	CATEGORY_NAME: 'ປະເພດຫຼັກ',
	SUBCATEGORY_NAME: 'ຊື່ປະເພດສຳຮອງ',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບປະເພດ',
	RIGHT: 'ແມ່ນບໍ່?',
	CATEGORY: 'ປະເພດ'
};

export const modal_lang = {
	ARE_YOU_SURE: 'ທ່ານແນ່ໃຈແລ້ວບໍ່?',
	YES_IM_SURE: 'ແນ່ໃຈ, ລຶບດຽວນີ້!',
	CANCEL: 'ຍົກເລີກ',
	SUCCESS: 'ເພີ່ມຂໍ້ມູນເຂົ້າຖານຂໍ້ມູນຮຽບຮ້ອຍແລ້ວ',
	FAIL: 'ຜິດພາດ! ກະລຸນາຕິດຕໍ່ຜູ້ພັດທະນາລະບົບ',
	VALIDATE_NAME: 'ຊື່ຕ້ອງບໍ່ໃຫ້ມີໂຕອັກສອນພິເສດ ຫຼື ວ່າງເປົ່າ',
	VALIDATE_TEL: 'ເບີໂທຕ້ອງເປັນໂຕເລກເທົ່ານັ້ນ',
	SUBMIT: 'ສົ່ງຂໍ້ມູນ',
	RIGHT: 'ແມ່ນບໍ່?',
	ADD_MTR_VALUE: 'ເພີ່ມຈຳນວນວັດຖຸດິບ',
	REQUIRE_UNIT: 'ກະລຸນາເລືອກຫົວໜ່ວຍ',
	REQUIRE_SUPPLIER: 'ກະລຸເລືອກຜູ່ສະໜອງ',
	REQUIRE_WARRANTY: 'ກະລຸເລືອກມື້ໝົດປະກັນ',
	REQUIRE_STATUS: 'ກະລຸເລືອກສະຖານະຂອງວັດສະດຸ',
	MATERIAL_NAME: 'ຊື່ວັດສະດຸ',
	UNIT: 'ຫົວໜ່ວຍ',
	SERIAL_NUMBER: 'Serial Number',
	WARRANTY: 'ມື້ໝົດປະກັນ',
	STATUS: 'ສະຖານະ',
	SUPPLIER: 'ຜູ່ສະໜອງ'
};

export const warehouse_lang = {
	ID: 'ລຳດັບ',
	WAREHOUSE_NAME: 'ຊື່ສາງ',
	WAREHOUSE_TEL: 'ເບີໂທລະສັບ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	ALL_WAREHOUSE: 'ລາຍການສາງທັງໝົດ',
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	WAREHOUSE: 'ສາງ',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບສາງ',
	STATUS: 'ສະຖານະ'
};

export const department_lang = {
	ID: 'ລຳດັບ',
	NAME: 'ຊື່ພະແນກ',
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	ALL: 'ລາຍການພະແນກທັງໝົດ',
	CURRENT: 'ພະແນກ',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບພະແນກ',
	STATUS: 'ສະຖານະ',
	SUBMIT: 'ສົ່ງຂໍ້ມູນ',
	LIST_SUBDEPARTMENT: 'ລາຍການພະແນກຍ່ອຍ',
	BACK: 'ກັບຄືນ',
	SUCCESS: 'ແກ້ໄຂສຳເລັດ!',
	FAIL: 'ແກ້ໄຂບໍ່ສຳເລັດ! ກະລຸນາຕິດຕໍ່ນັກພັດທະນາ',
	NOT_YET_HAVE: 'ຍັງບໍ່ມີປະເພດຍ່ອຍ',
	SUB_DEPARTMENT: 'ປະເພດຍ່ອຍ',
	CLOSE_MODAL: 'ປິດ'
};

export const rack_lang = {
	ID: 'ລຳດັບ',
	NAME: 'ຊື່ຕູ້',
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	ALL: 'ລາຍການຕູ້ທັງໝົດ',
	CURRENT: 'ຕູ້',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບຕູ້',
	WAREHOUSE: 'ສາງ',
	CHOOSE_WAREHOUSE: 'ເລືອກສາງ',
	STATUS: 'ສະຖານະ'
};

export const material_lang = {
	ADD: 'ເພີ່ມ',
	EDIT: 'ແກ້ໄຂ',
	ALL_MATERIAL_TYPE: 'ລາຍການວັດສະດຸທັງໝົດ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	MATERIAL_TYPE_NAME: 'ຊື່ວັດສະດຸ',
	WOULD_LIKE_TO_REMOVE: 'ທ່ານຕ້ອງການລຶບປະເພດ',
	WOULD_LIKE_TO_REMOVE_2: 'ທ່ານຕ້ອງການລຶບ',
	MATERIAL: 'ວັດສະດຸ',
	WAREHOUSE: 'ສາງ',
	QUANTITY: 'ຈຳນວນ',
	NAME: 'ຊື່',
	MATERIAL_NAME: 'ວັດສະດຸ',
	BACK: 'ກັບຄືນເບິ່ງຂໍ້ມູນທັງໝົດ',
	STATUS: 'ສະຖານະ',
	RANK: 'ອັນດັບ',
	SERIAL_NUMBER: 'Serial Number',
	WARRANTY: 'ໝົດປະກັນວັນທີ',
	CHOOSE_STATUS: 'ເລືອກສະຖານະ',
	STATUS_NEW: 'ໃໝ່',
	STATUS_GOOD: 'ສະພາບດີ',
	STATUS_BROKEN: 'ເພ',
	STATUS_LOST: 'ເສຍ',
	CHOOSE_SUPPLIER: 'ເລືອກຜູ່ສະໜອງ',
	TOTAL_QUANTITY: 'ຈຳນວນທັງໝົດ',
	BUY_DATE: 'ວັນທີຊື້',
	REQUIRE_BUY_DATE: 'ກະລຸນາລະບຸວັນທີຊື້',
	WARRANTY_DATE: 'ມື້ໝົດປະກັນ',
	RAW_CODE: 'ລະຫັດວັດຖຸດິບ',
	TOOL_CODE: 'ລະຫັດເຄື່ອງມື',
	MATERIAL_CODE: 'ລະຫັດອຸປະກອນ',
	MATERIAL_STATUS: 'ສະພາບ',
	RAW: 'ວັດຖຸດິບ',
	TOOL: 'ເຄື່ອງມື',
	MATERIAL_THINGS: 'ອຸປະກອນ',
	REQUIRE_SERIAL_NUMBER: 'ກະລຸນາລະບຸ Serial Number',
	SEARCH_MATERIAL_BY_NAME: 'ຄົ້ນຫາດ້ວຍຊື່',
	SEARCH: 'ຄົ້ນຫາ'
};

export const alert_lang = {
	MATERIAL_SUB_DELETED: 'ປະເພດທີ່ທ່ານເລືອກຖືກລຶບຮຽບຮ້ອຍແລ້ວ',
	SUCCESS: 'ສຳເລັດ!',
	DELETED_FAIL: 'ບໍ່ສາມາດລຶບໝວດໝູ່ດັ່ງກ່າວໄດ້',
	ERROR: 'ຜິດພາດ'
};

export const material_form_lang = {
	HEADER: 'ລາຍລະອຽດສາງ',
	CHOOSE_SUB_TYPE: 'ເລືອກປະເພດຮອງ',
	CHOOSE_TYPE: 'ເລືອກປະເພດ',
	CHOOSE_UNIT: 'ເລືອກຫົວໜ່ວຍວັດແທກ',

	ARE_YOU_SURE: 'ທ່ານແນ່ໃຈແລ້ວບໍ່?',
	YES_IM_SURE: 'ແນ່ໃຈ, ລຶບດຽວນີ້!',
	CANCEL: 'ຍົກເລີກ',
	SUCCESS: 'ເພີ່ມຂໍ້ມູນເຂົ້າຖານຂໍ້ມູນຮຽບຮ້ອຍແລ້ວ',
	FAIL: 'ຜິດພາດ! ກະລຸນາຕິດຕໍ່ຜູ້ພັດທະນາລະບົບ',
	VALIDATE_NAME: 'ຊື່ຕ້ອງບໍ່ໃຫ້ມີໂຕອັກສອນພິເສດ ຫຼື ວ່າງເປົ່າ',
	VALIDATE_TEL: 'ເບີໂທຕ້ອງເປັນໂຕເລກເທົ່ານັ້ນ',
	SUBMIT: 'ສົ່ງຂໍ້ມູນ',
	SUBMIT_EDIT: 'ແກ້ໄຂຂໍ້ມູນ',
	RIGHT: 'ແມ່ນບໍ່?',

	ADD_DETAIL: 'ເພີ່ມຂໍ້ມູນສາງ',

	WAREHOUSE: 'ສາງ',
	RACK: 'ຕູ້',
	QUANTITY: 'ຈຳນວນ',

	LOADING: 'ກຳລັງໂຫລດ...',
	NO_ID: 'ກະລຸນາສ້າງ ວັດສະດຸ ຫຼື ສ່ວນປະກອບກ່ອນ',

	MATERIAL_WAREHOUSE_EMPTY: 'ຍັງບໍ່ມີຢູ່ໃນສາງໃດ',

	ACTION: 'Action',
	ADD: 'ເພີ່ມ',
	ADD_MATERIAL: 'ເພີ່ມຈຳນວນວັດຖຸດິບ',
	EDIT: 'ແກ້ໄຂ'
};

export const material_form_modal_lang = {
	QUANTITY: 'ຈຳນວນ',
	SELECT_WAREHOUSE: 'ເລືອກສາງ',
	SELECT_RACK: 'ເລືອກຕູ້',
	WAREHOUSE_INFO: 'ຂໍ້ມູນສາງ'
};

export const validate_lang = {
	VALIDATE_QUANTITY: 'ຈຳນວນຄວນເປັນໂຕເລກເທົ່ານັ້ນ'
};

export const material_log_modal = {
	HISTORY: 'ປະຫວັດການປ່ຽນແປງ',
	USERNAME: 'ໂດຍຜູ່ໃຊ້',
	MATERIAL: 'ວັດຖຸດິບ',
	WAREHOUSE: 'ສາຂາ',
	RACK: 'ຕູ້',
	METHOD: 'ກະທຳ',
	FROM: 'ຈາກ',
	TO: 'ເປັນ',
	TIME: 'ເວລາ',
	PREVIOUS: 'ກັບຄືນ',
	NEXT: 'ຕໍ່ໄປ',
	BILL_ID: 'ເລກບິນ/ເລກເບີກ'
};

export const login_lang = {
	LOGIN: 'ເຂົ້າສູ່ລະບົບ',
	USERNAME: 'ຊື່ຜູ່ໃຊ້',
	PASSWORD: 'ລະຫັດຜ່ານ',
	INCORRECT: 'ຊື່ຜູ່ໃຊ້ ຫລື ລະຫັດຜ່ານບໍ່ຖືກ',
	WELCOME: 'ຍິນດີຕ້ອນຮັບ'
};

export const employee_lang = {
	FULLNAME: 'ຊື່ ແລະ ນາມສະກຸນ',
	GENDER: 'ເພດ',
	BRANCH: 'ສາຂາ',
	DEPARTMENT: 'ພະແນກ',
	SALARY: 'ເງິນເດືອນ',
	EDIT: 'ແກ້ໄຂ',
	EMPLOYEE: 'ເພີ່ມພະນັກງານ',
	ADD_NEW: 'ເພີ່ມໃໝ່',
	KIP: 'ກີບ',
	ALL_EMPLOYEE: 'ພະນັກງານທັງໝົດ'
};

export const employee_form_lang = {
	MALE: 'ຊາຍ/Male',
	FEMALE: 'ຍິງ/Female',
	CHOOSE_BRANCH: 'ເລືອກສາຂາ',
	SALARY: 'ເງິນເດືອນ',
	KIP: 'ກີບ',
	SUBMIT: 'ສ້າງພະນັກງານ',
	ADD_EMPLOYEE: 'ເພີ່ມພະນັກງານເຂົ້າລະບົບ',
	ADD_DEPARTMENT: 'ເພີ່ມເຂົ້າພະແນກ',
	CHOOSE_DEPARTMENT: 'ເລືອກພະແນກ',
	CHOOSE_SUB_DEPARTMENT: 'ເລືອກພະແນກຍ່ອຍ',
	NO_SUB_DEPARTMENT: 'ບໍ່ມີປະເພດຍ່ອຍ',
	ADDED_EMPLOYEE: 'ເພີ່ມພະນັກງານເຂົ້າລະບົບແລ້ວ!',
	SUB_DEPARTMENT_TOAST_ERROR: 'ບໍ່ສາມາດເພີ່ມພະແນກຍ່ອຍໄດ້',
	DEPARTMENT_TOAST_ERROR: 'ບໍ່ສາມາດເພີ່ມພະນັກງານໄດ້',
	GENERAL_ERROR: 'ຜິດພາດກະລຸນາຕິດຕໍ່ຜູ່ເບິ່ງແຍງລະບົບ',
	ENABLED: 'ຖືກນຳໃຊ້',
	DISABLE: 'ປິດການນຳໃຊ້',
	SUCCESS_CHANGE_HIDE: 'ແກ້ໄຂຂໍ້ມູນສຳເລັດ!',
	CHANGE_AVATAR: 'ປ່ຽນຮູບສຳເລັດແລ້ວ!',
	FAIL_CHANGE_AVATAR: 'ບໍ່ສາມາດປ່ຽນຮູບໄດ້!',
	EDIT_EMPLOYEE: 'ແກ້ໄຂຂໍ້ມູນພະນັກງານ',
	BACK: 'ກັບຄືນ',
	EDIT_SUBMIT: 'ແກ້ໄຂຂໍ້ມູນ'
};

export const file_upload_lang = {
	SELECT_IMAGE: 'ເລືອກຮູບ',
	ADD_IMAGE: 'ເພີ່ມຮູບ',
	REMOVE: 'ລຶບ'
};

export const supplier_lang = {
	SUPPLIER: 'ຜູ່ສະໜອງ',
	SUPPLIER_EDIT: 'ແກ້ໄຂຜູ່ສະໜອງ',
	SUPPLIER_LIST: 'ລາຍຊື່ຜູ່ສະໜອງ',
	SUBMIT: 'ສ້າງຜູ່ສະໜອງ',
	NAME: 'ຊື່ຜູ່ສະໜອງ',
	TEL: 'ເບີໂທລະສັບ (ບໍ່ໃສ່ກໍ່ໄດ້)',
	ADDRESS: 'ທີ່ຢູ່ (ບໍ່ໃສ່ກໍ່ໄດ້)',
	TEL_TABLE: 'ເບີໂທລະສັບ',
	ADDRESS_TABLE: 'ທີ່ຢູ່',
	VALIDATE_TEL: 'ເບີໂທຕ້ອງເປັນໂຕເລກເທົ່ານັ້ນ',
	SUCCESS: 'ເພີ່ມຜູ່ສະໜອງເຂົ້າຖານຂໍ້ມູນຮຽບຮ້ອຍແລ້ວ!',
	SUCCESS_EDIT: 'ແກ້ໄຂຜູ່ສະໜອງຮຽບຮ້ອຍແລ້ວ!',
	FAILED: 'ບໍ່ສາມາດເພີ່ມຜູ່ສະໜອງໄດ້! ກະລຸນາຕິດຕໍ່ຜູ່ເບິ່ງແຍງລະບົບ',
	FAILED_EDIT: 'ບໍ່ສາມາດແກ້ໄຂຜູ່ສະໜອງໄດ້! ກະລຸນາຕິດຕໍ່ຜູ່ເບິ່ງແຍງລະບົບ',
	EDIT: 'ແກ້ໄຂ',
	ADD: 'ເພີ່ມຜູ່ສະໜອງ',
	EDIT_SUBMIT: 'ແກ້ໄຂ'
};

export const import_lang = {
	NUMBER_LIST: 'ລຳດັບ',
	IMPORT_LIST: 'ລາຍການຈັດຊື້',
	CREATE_BILL: 'ເພີ່ມການຈັດຊື້',
	IMPORT: 'ຈັດຊື້',
	ADD: 'ເພີ່ມການຈັດຊື້',
	DATETIME: 'ເວລາ',
	LATEST_UPDATE: 'ເຄື່ອນໄຫວຫຼ້າສຸດ',
	CODE: 'ເລກບິນ',
	SUPPLIER: 'ຜູ່ສະໜອງ',
	MUST_PAY: 'ຕ້ອງຈ່າຍ',
	STATUS: 'ສະຖານະ',
	MOVED_TO_WAREHOUSE: 'ຈັດເກັບເຂົ້າສາງແລ້ວ',
	NOT_YET_MOVED_TO_WAREHOUSE: 'ຍັງບໍ່ໄດ້ຈັດເຂົ້າສາງ',
	ALL: 'ທັງໝົດ',
	NEXT: 'ຖັດໄປ',
	PREVIOUS: 'ກັບຄືນ',
	SETTING: 'ຕັ້ງຄ່າ',
	EDIT: 'ແກ້ໄຂການຈັດຊື້',
	APPROVED: 'ຈັດເກັບເຂົ້າສາງແລ້ວ',
	WAITING_FOR_APPROVING: 'ລໍຖ້າຈັດເກັບ',
	REMOVE: 'ລຶບ',
	DELETED: 'ລຶບແລ້ວ!',
	DELETED_MSG: 'ຂໍ້ມູນຂອງທ່ານຖືກລຶບແລ້ວ!',
	CANCELLED: 'ຍົກເລີກ!',
	CANCELLED_MSG: 'ຂໍ້ມູນຂອງທ່ານປອດໄພດີ',
	SEARCH_BILL_CODE: 'ຄົ້ນຫາເລກບິນ',
	SEARCH: 'ຄົ້ນຫາ',
	NO_DATA: 'ບໍ່ມີຂໍ້ມູນຢູ່ໃນລະບົບ'
};

export const import_form_lang = {
	BILL_DATE: 'ວັນທີອອກບິນ',
	CHOOSE_BILL_DATE: 'ເລືອກວັນທີ',
	DATE: 'ເວລາ',
	DATE_INPUT: 'ປ້ອນເວລາສ້າງ',
	CODE: 'ເລກບິນ',
	INPUT_CODE: 'ປ້ອນເລກບິນ',
	SUPPLIER: 'ຜູ່ສະໜອງ',
	CHOOSE_SUPPLIER: 'ເລືອກ',
	DESCRIPTION: 'ໝາຍເຫດ',
	SHIPPING_COST: 'ຄ່າສົ່ງ',
	KIP: 'ກີບ',
	SEARCH_ITEM: 'ຄົ້ນຫາສິນຄ້າ',
	STATUS: 'ສະຖານະ',
	CHOOSE: 'ເລືອກ',
	LIST_SELECT_ITEM: 'ລາຍການສິນຄ້າ',
	MATERIAL: 'ວັດສະດຸ',
	PRICE: 'ລາຄາ',
	QUANTITY: 'ຈຳນວນ',
	ALL_ITEMS_PRICE: 'ລວມ',
	TOTAL: 'ທັງໝົດ',
	SUBMIT: 'ສ້າງການຈັດຊື້',
	CHOOSE_STATUS: 'ເລືອກສະຖານະ',
	WAITING: 'ລໍຖ້າ',
	UNPAID: 'ຄ້າງຊຳລະ',
	PAID: 'ຊຳລະແລ້ວ',
	ENABLE_CODE: 'ກຳນົດເລກບິນດ້ວຍໂຕເອງ',
	BACK: 'ກັບຄືນ',
	SUCCESS: 'ເພີ່ມການຈັດຊື້ສຳເລັດ!',
	FAILED: 'ບໍ່ສາມາດເພີ່ມການຈັດຊື້ໄດ້! ກະລຸນາຕິດຕໍ່ຜູ່ເບິ່ງແຍງລະບົບ',
	VALIDATE_ZERO: 'ຈຳນວນຕ້ອງໃຫຍ່ກວ່າ 0',
	ERROR_SUPPLIER: 'ກະລຸນາເລືອກຜູ່ສະໜອງ',
	ERROR_STATUS: 'ກະລຸນາເລືອກສະຖານະ',
	ERROR_OVERALL: 'ກະລຸນາເລືອກວັດສະດຸ ພ້ອມລະບຸຈຳນວນ ແລະລາຄາຕໍ່ໜ່ວຍໃຫ້ຄົບ.',
	ERROR_BILL_DATE: 'ກະລຸນາລະບຸວັນທີຈັດຊື້',
	CHOOSE_MATERIAL_TYPE: 'ເລືອກປະເພດວັດສະດຸ',
	ERROR_ALREADY_EXISTED: 'ມີຢູ່ໃນສາງແລ້ວ',
	EDIT_SUBMIT: 'ແກ້ໄຂໃບຈັດຊື້'
};

export const import_modal_lang = {
	MOVE: 'ຈັດເກັບວັດສະດຸ',
	ERROR_WAREHOUSE: 'ກະລຸນາເລືອກສາງ',
	ERROR_RACK: 'ກະລຸນາເລືອກຕູ້',
	ERROR_NO_RACK: 'ບໍ່ສາມາດຈັດເກັບເຂົ້າສາງນີ້ໄດ້! ເນື່ອງຈາກບໍ່ມີຕູ້ເກັບ, ກະລຸນາສ້າງຕູ້ກ່ອນ',
	ERROR_NO_ORDER: 'ບໍ່ມີວັດສະດຸໃນບິນນີ້',
	ORDERS_LIST: 'ລາຍການໃນບິນນີ້',
	FROM: 'ຈາກ',
	CHOOSE_WAREHOUSE: 'ເລືອກສາງ',
	BILL_CODE: 'ເລກບິນ',
	CLICK_TO_MOVE: 'ກົດເພື່ອຈັດເກັບເຂົ້າສາງ',
	ERROR_ALREADY_EXISTED: 'ມີຢູ່ໃນສາງແລ້ວ',
	MOVE_TO_WAREHOUSE: 'ຈັດເກັບເຂົ້າສາງ'
};

export const withdrawal_lang = {
	ROW_INDEX: 'ລຳດັບ',
	ADD: 'ເພີ່ມການເບີກ',
	WITHDRAWAL_TOOL_LIST: 'ລາຍການເບີກອຸປະກອນທັງໝົດ',
	FILTER: 'ຄົ້ນຫາຈາກ',
	BACK: 'ກັບຄືນ',
	CREATE_WITHDRAWAL: 'ເພີ່ມການເບີກ',
	EMPLOYEE: 'ພະນັກງານ',
	EMPLOYEE_NAME: 'ຊື່ພະນັກງານ',
	CHOOSE_DEPARTMENT: 'ເລືອກພະແນກ',
	CHOOSE_EMPLOYEE: 'ເລືອກພະນັກງານ',
	NAME: 'ຊື່',
	GENDER: 'ເພດ',
	DEPARTMENT: 'ພະແນກ',
	BRANCH: 'ສາຂາ',
	MATERIAL_TYPE: 'ປະເພດວັດສະດຸ',
	LONG_TERM_MATERIAL: 'ເລືອກວັດສະດຸມີປະກັນ',
	CHOOSE_MATERIAL_TYPE: 'ເລືອກປະເພດວັດສະດຸ',
	WAREHOUSE: 'ສາງ',
	RACK: 'ຕູ້',
	HAVE_QUANTITY: 'ມີຢູ່ໃນສາງຈຳນວນ',
	WITHDRAW_QUANTITY: 'ເບີກຈຳນວນ',
	WITHDRAW_PHOTO: 'ຮູບກ່ອນເບີກ',
	REQUIRE_RETURN: 'ຕ້ອງສົ່ງຄືນ',
	MATERIAL_STATUS: 'ສະພາບອຸປະກອນ',
	WITHDRAWAL_DATE: 'ວັນທີເບີກ',
	SUCCESS: 'ສຳເລັດແລ້ວ!',
	NORMAL_MATERIAL: 'ວັດສະດຸທົ່ວໄປ',
	CHOOSE_WAREHOUSE_RACK: 'ເລືອກສາງ ແລະ ຕູ້',
	CHOOSE_NORMAL_MATERIAL: 'ເລືອກວັດສະດຸທົ່ວໄປ',
	WITHDRAW_EMPLOYEE: 'ຜູ່ເບີກ',
	WITHDRAW_ITEM: 'ລາຍການເບີກ',
	MATERIAL_NAME: 'ຊື່ວັດສະດຸ',
	RETURN_WITHDRAWAL: 'ສົ່ງຄືນວັດສະດຸ',
	WITHDRAW_QUANTITY_RETURN: 'ຈຳນວນທີ່ໄດ້ເບີກໄປ',
	RETURN_QUANTITY: 'ສົ່ງຄືນຈຳນວນ',
	RETURN_DATE: 'ວັນທີສົ່ງຄືນ',
	CHOOSE_RETURN_DATE: 'ເລືອກວັນທີສົ່ງຄືນ',
	RETURN_STATUS: 'ສະພາບອຸປະກອນຕອນສົ່ງຄືນ',
	STATUS_NEW: 'ໃໝ່',
	STATUS_GOOD: 'ສະພາບດີ',
	STATUS_BROKEN: 'ເພ',
	STATUS_LOST: 'ເສຍ',
	WITHDRAWAL_LIST: 'ລາຍການເບີກອຸປະກອນ',
	WAITING_RETURN: 'ລໍຖ້າສົ່ງຄືນ',
	WITH_DRAW_FROM_DATE: 'ແຕ່ວັນທີ',
	WITH_DRAW_TO_DATE: 'ຫາວັນທີ',
	CLOSED: 'ສົ່ງຄືນແລ້ວ',
	WITHDRAWAL_ID: 'ລະຫັດເບີກ',
	PICK_DATE_OPTIONS: 'ຄົ້ນຫາຈາກວັນທີ',
	PREVIOUS: 'ກັບຄືນ',
	NEXT: 'ຕໍ່ໄປ',
	RETURN_DETAIL: 'ລາຍການສົ່ງຄືນ',
	RETURN_IMAGE: 'ຮູບອຸປະກອນຕອນສົ່ງ',
	WITHDRAWAL_RESULT: 'ສະຫລຸບການເບີກ'
};
