export const updateBranchesList = (store, branches) => {
	store.setState({ branches });
};

export const updateCategoriesList = (store, categories) => {
	store.setState({ categories });
};
export const updateSubCategoriesList = (store, subcategories) => {
	store.setState({ subcategories });
};

export const updateWarehousesList = (store, warehouses) => {
	store.setState({ warehouses });
};

export const updateDepartmentsList = (store, departments) => {
	store.setState({ departments });
};

export const updateRacksList = (store, racks) => {
	store.setState({ racks });
};

export const updateMaterialTypesList = (store, material_types) => {
	store.setState({ material_types });
};

export const updateMaterialSubTypesList = (store, material_sub_types) => {
	store.setState({ material_sub_types });
};

export const updateMaterialsList = (store, materials) => {
	store.setState({ materials });
};

// DEPARTMENT PAGE
export const updateSubDepartment = (store, subdepartments) => {
	store.setState({ subdepartments });
};

// WITHDRAW
export const updateWithdrawEmp = (store, withdraw_emp) => {
	store.setState({ withdraw_emp });
};
export const updateWithdrawList = (store, withdraw_list) => {
	store.setState({ withdraw_list });
};
export const updateWithdrawPageCount = (store, withdraw_page_count) => {
	store.setState({ withdraw_page_count });
};
export const updateWithdrawCurrentPage = (store, withdraw_current_page) => {
	store.setState({ withdraw_current_page });
};
