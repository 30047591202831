import React, { useState, useRef } from 'react';

import { Row, Col, Table, Button, Card, CardHeader, CardBody } from 'reactstrap';

import NotificationAlert from 'react-notification-alert';

import DepartmentTable from '../components/Department/DepartmentTable';
import DepartmentModal from '../components/Department/DepartmentModal';

import { department_lang, branch_lang } from '../../i18n/lo-LA.js';

import './titleBar.css';

const Department = props => {
	const [ModalDepartment, setModalDepartment] = useState(false);
	const [title, setTitle] = useState('');
	const [contentID, setContentID] = useState(null);

	const refNotification = useRef(null);

	const showAddModal = () => {
		setContentID(null);
		setTitle(department_lang.ADD);
		setModalDepartment(!ModalDepartment);
	};

	const hideModal = () => {
		setContentID(null);
		setModalDepartment(false);
	};

	const notify = (place, color, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: <div>{message}</div>,
			type: type,
			icon: 'nc-icon nc-bell-55',
			autoDismiss: 7
		};
		refNotification.current.notificationAlert(options);
	};

	const showEditModal = contentID => {
		setTitle(department_lang.EDIT);
		setContentID(contentID);
		setModalDepartment(!ModalDepartment);
	};

	return (
		<div className="content">
			<NotificationAlert ref={refNotification} />
			<DepartmentModal
				ModalDepartment={ModalDepartment}
				showModal={contentID === null ? showAddModal : showEditModal}
				title={title}
				hideModal={hideModal}
				notify={notify}
				contentID={contentID}
			/>
			<Card>
				<CardHeader>
					<Row>
						<Col lg="7" xs="6">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{department_lang.ALL}
							</h3>
						</Col>
						<Col lg="5" xs="6">
							<div className="pull-right">
								<Button
									size="sm"
									style={{ marginTop: 0, fontSize: '1.2rem' }}
									onClick={() => showAddModal()}
								>
									{department_lang.ADD_NEW}
								</Button>
							</div>
						</Col>
					</Row>
				</CardHeader>
			</Card>
			<Row>
				<Col md="6" sm="12">
					<Card>
						<CardBody>
							<Table responsive striped hover style={{ backgroundColor: 'white', fontSize: '1.2rem' }}>
								<thead>
									<tr>
										<th style={{ fontSize: '1.2rem' }}>{department_lang.ID}</th>
										<th style={{ fontSize: '1.2rem' }}>{department_lang.NAME}</th>
										<th style={{ fontSize: '1.2rem' }} className="text-right">
											{branch_lang.ACTION}
										</th>
									</tr>
								</thead>
								<DepartmentTable notify={notify} showModal={showEditModal} />
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default Department;
