import React from 'react';

import { Row, Col } from 'reactstrap';
import DepartmentLeftFrom from './components/DepartmentLeftForm';
import DepartmentRightFrom from './components/DepartmentRightForm';

const DepartmentForm = props => {
	return (
		<React.Fragment>
			<div className="content">
				<Row>
					<Col sm={12} md={4}>
						<DepartmentLeftFrom location={props} />
					</Col>
					<Col sm={12} md={8}>
						<DepartmentRightFrom location={props} />
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

export default DepartmentForm;
