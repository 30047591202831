import React, { useState } from 'react';
import strapi from '../../../strapi-sdk';

import { category_lang, modal_lang } from '../../../i18n/lo-LA';

import { Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import useGlobal from '../../../store';

import _ from 'lodash';

const CategoryTable = props => {
	const [globalState, globalActions] = useGlobal();
	const [alert, setAlert] = useState(null);

	// fetch data from API
	// using Strapi SDK
	// Update fetched data to Global State
	const useFetchCategories = () => {
		const [globalState, globalActions] = useGlobal();

		const fetchData = async () => {
			const res = await strapi.getEntries('categories');
			globalActions.updateCategoriesList(res);
		};

		if (_.isEmpty(globalState.categories)) {
			fetchData();
		}
		return null;
	};
	useFetchCategories();
	// END useFetchCategories

	// Confirm Alert Box before delete item
	const confirmMessage = (props, item) => {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={modal_lang.ARE_YOU_SURE}
				onConfirm={() => CategoryDelete(props, item.id)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText={modal_lang.YES_IM_SURE}
				cancelBtnText={modal_lang.CANCEL}
				showCancel
			>
				{category_lang.WOULD_LIKE_TO_REMOVE} <span style={{ fontWeight: 700, color: 'red' }}>
					{item.name}
				</span>{' '}
				{category_lang.RIGHT}
			</ReactBSAlert>
		);
	};
	// END Alert Box

	// CategoryDelete function perform deleting item
	// and then update the global state of Categories by refetch data from API
	async function CategoryDelete(props, id) {
		try {
			await strapi.deleteEntry('categories', id);
			globalActions.updateCategoriesList(await strapi.getEntries('categories'));
			successDelete();
		} catch {
			cancelDetele();
		}
	}
	// END CategoryDelete

	// Success Message of Alert Box
	const successDelete = () => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="ສຳເລັດ!"
				onConfirm={() => setAlert(null)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
			>
				ໝວດໝູ່ທີ່ທ່ານເລືອກຖືກລຶບຮຽບຮ້ອຍແລ້ວ
			</ReactBSAlert>
		);
	};
	// End Success Message box

	// Error alert box after click on delete
	const cancelDetele = () => {
		setAlert(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title="ຜິດພາດ"
				onConfirm={() => setAlert(null)}
				onCancel={() => setAlert(null)}
				confirmBtnBsStyle="info"
			>
				ບໍ່ສາມາດລຶບໝວດໝູ່ດັ່ງກ່າວໄດ້
			</ReactBSAlert>
		);
	};
	// End Error alert box

	return (
		<React.Fragment>
			{alert}
			<tbody>
				{_.isEmpty(globalState.categories) ? (
					<tr>
						<td>Loading...</td>
					</tr>
				) : (
					globalState.categories.map((item, idx) => (
						<tr key={item.id}>
							<td>{idx + 1}</td>
							<td>{item.name}</td>
							<td className="text-right">
								<Button
									className="btn-icon"
									color="success"
									size="sm"
									onClick={() => props.showModal(item.id)}
								>
									<i className="fa fa-edit" />
								</Button>
								{` `}
								<Button
									className="btn-icon"
									color="danger"
									size="sm"
									onClick={() => confirmMessage(props, item)}
								>
									<i className="fa fa-times" />
								</Button>
							</td>
						</tr>
					))
				)}
			</tbody>
		</React.Fragment>
	);
};

export default CategoryTable;
