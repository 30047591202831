import React, { useEffect, useState } from 'react';

import { Table, Card, CardBody, CardHeader, Button, Col, Row } from 'reactstrap';

import { employee_lang, branch_lang } from '../../i18n/lo-LA';
import strapi from '../../strapi-sdk';

import { Link } from 'react-router-dom';

const Employee = props => {
	const [employees, setEmployees] = useState(null);
	const [loading, setLoading] = useState(true);
	const [departments, setDepartments] = useState(null);

	useEffect(() => {
		async function fetchEmps() {
			const emps = await strapi.getEntries('employees');
			const depts = await strapi.getEntries('departments');
			setEmployees(emps);
			setDepartments(depts);
			setLoading(false);
		}
		fetchEmps();
	}, []);

	const getDepartment = id => {
		const result = departments.filter(department => department.id === id);
		if (id === null) return '';
		return result[0].name;
	};

	return (
		<div className="content">
			<Card>
				<CardHeader>
					<Row>
						<Col lg="7" xs="6">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{employee_lang.ALL_EMPLOYEE}
							</h3>
						</Col>
						<Col lg="5" xs="6">
							<div className="pull-right">
								<Link to="/page/employees/add">
									<Button size="sm" style={{ marginTop: 0, fontSize: '1.2rem' }}>
										{employee_lang.ADD_NEW}
									</Button>
								</Link>
							</div>
						</Col>
					</Row>
				</CardHeader>
			</Card>
			<Card>
				<CardBody>
					{loading ? (
						<span>Loading...</span>
					) : (
						<Table striped>
							<thead>
								<tr>
									<th>#</th>
									<th>{employee_lang.FULLNAME}</th>
									<th>{employee_lang.GENDER}</th>
									<th>{employee_lang.BRANCH}</th>
									<th>{employee_lang.DEPARTMENT}</th>
									<th>{employee_lang.SALARY}</th>
									<th>{branch_lang.ACTION}</th>
								</tr>
							</thead>
							<tbody>
								{employees.map((emp, idx) => (
									<tr key={emp.id} style={{ fontSize: '1.1rem' }}>
										<th scope="row">{idx + 1}</th>
										<td>{emp.fullname}</td>
										<td>{emp.gender}</td>
										<td>{emp.branch === null ? '' : emp.branch.name}</td>
										<td>
											{emp.employeedetail.map(
												(empdetail, idx) =>
													getDepartment(empdetail.department) +
													(emp.employeedetail.length - 1 !== idx ? ',' : '')
											)}
										</td>
										<td>
											{new Intl.NumberFormat('ja-JP').format(emp.salary) +
												' ' +
												employee_lang.KIP}
										</td>
										<td>
											<Link to={`/page/employees/edit/${emp.id}`}>
												<Button
													className="btn"
													color="success"
													size="sm"
													style={{ fontSize: '1.1rem' }}
												>
													<i className="fa fa-edit" />
													{employee_lang.EDIT}
												</Button>
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</CardBody>
			</Card>
		</div>
	);
};

export default Employee;
