import React from 'react';
import { Card, CardBody, Button, CardHeader, Row, Col } from 'reactstrap';

import { department_lang } from '../../../../../i18n/lo-LA';
import DepartmentFormTable from './DepartmentFormTable';

import { withRouter } from 'react-router-dom';
import SubModal from './SubModal';

const DepartmentRightFrom = props => {
	return (
		<React.Fragment>
			<Card>
				<CardHeader>
					<Row>
						<Col lg="4" xs="4">
							<Button
								size="sm"
								color="info"
								style={{ marginTop: 0, fontSize: '1.2rem' }}
								onClick={() => props.history.push('/page/department')}
							>
								{department_lang.BACK}
							</Button>
						</Col>
						<Col lg="4" xs="4">
							<h3 className="title" style={{ marginBottom: 'unset', fontSize: '1.4rem' }}>
								{department_lang.LIST_SUBDEPARTMENT}
							</h3>
						</Col>
						<Col lg="4" xs="4">
							<div className="pull-right">
								<SubModal location={props.match.params.id} />
							</div>
						</Col>
					</Row>
				</CardHeader>
			</Card>
			<Card>
				<CardBody>
					<DepartmentFormTable />
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default withRouter(DepartmentRightFrom);
