import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import strapi, { URL } from '../../../strapi-sdk';
import { withdrawal_lang } from '../../../i18n/lo-LA';
import ReturnLTDetail from './ReturnLTDetail';
import ReturnDetail from './ReturnDetail';

const WithdrawalResult = props => {
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(true);
	const [Employee, setEmployee] = useState(null);
	const [Departments, setDepartments] = useState(null);
	const [withdrawal, setWithdrawal] = useState(null);

	useEffect(
		() => {
			const fetchData = async () => {
				const resWD = await strapi.getEntry('withdrawals', props.match.params.id);
				const resEmp = await strapi.getEntry('employees', resWD.employee.id);
				const resDep = await strapi.getEntries('departments');
				setWithdrawal(resWD);
				setEmployee(resEmp);
				setDepartments(resDep);
				setLoading(false);
			};
			fetchData();
		},
		[props.match.params.id]
	);

	const DepartmentById = id => {
		return Departments.filter(dp => dp.id === id);
	};

	return (
		<div className="content">
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader
							tag="h3"
							style={{
								padding: 5,
								textAlign: 'center'
							}}
							className="d-flex"
						>
							<div className="">
								<Link to="/page/withdrawal-tools/" className="">
									<Button color="danger" style={{ fontSize: '1.1rem' }}>
										<i className="fas fa-arrow-circle-left" /> {withdrawal_lang.BACK}
									</Button>
								</Link>
							</div>
							<div className="w-75 align-self-center">{withdrawal_lang.WITHDRAWAL_RESULT}</div>
						</CardHeader>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12}>
					<Row>
						<Col sm={12} md={12}>
							<Card>
								<CardHeader>
									<h3
										style={{
											marginBottom: 'unset',
											borderBottom: 'dashed',
											borderWidth: '2px',
											borderColor: '#ccc'
										}}
									>
										{withdrawal_lang.WITHDRAW_EMPLOYEE}
									</h3>
								</CardHeader>
								<CardBody>
									{loading ? null : (
										<React.Fragment>
											<Row>
												<Col sm={12} md={5} style={{ verticalAlign: 'middle' }}>
													<div style={{ textAlign: 'center' }}>
														<img
															src={URL + Employee.image.url}
															style={{ maxWidth: '200px' }}
														/>
													</div>
												</Col>
												<Col sm={12} md={7} className="mt-3">
													<Table>
														<tbody style={{ fontSize: '1rem' }}>
															<tr>
																<td style={{ textAlign: 'right', fontWeight: '700' }}>
																	{withdrawal_lang.NAME}
																</td>
																<td style={{ textAlign: 'center' }}>
																	{Employee.fullname}
																</td>
															</tr>
															<tr>
																<td style={{ textAlign: 'right', fontWeight: '700' }}>
																	{withdrawal_lang.GENDER}
																</td>
																<td style={{ textAlign: 'center' }}>
																	{Employee.gender}
																</td>
															</tr>
															<tr>
																<td style={{ textAlign: 'right', fontWeight: '700' }}>
																	{withdrawal_lang.BRANCH}
																</td>
																<td style={{ textAlign: 'center' }}>
																	{Employee.branch.name}
																</td>
															</tr>
															<tr>
																<td style={{ textAlign: 'right', fontWeight: '700' }}>
																	{withdrawal_lang.DEPARTMENT}
																</td>
																<td style={{ textAlign: 'center' }}>
																	{Employee.employeedetail.map(dp => (
																		<span key={dp.id}>
																			{' '}
																			{DepartmentById(dp.department)[0].name},
																		</span>
																	))}
																</td>
															</tr>
														</tbody>
													</Table>
												</Col>
											</Row>
										</React.Fragment>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col sm={12} md={6}>
							{loading ? null : (
								<ReturnLTDetail withdrawal={withdrawal} type={withdrawal.material_type} />
							)}
						</Col>
						<Col sm={12} md={6}>
							{loading ? null : withdrawal.closed ? (
								withdrawal.return_quantity === null ? (
									<Card>
										<CardHeader tag="h3">ການເບີກນີ້ບໍ່ມີການສົ່ງຄືນ</CardHeader>
									</Card>
								) : (
									<ReturnDetail withdrawal={withdrawal} type={withdrawal.material_type} />
								)
							) : (
								<Card>
									<CardHeader tag="h3">ລໍຖ້າສົ່ງຄືນ</CardHeader>
								</Card>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default WithdrawalResult;
