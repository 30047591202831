import Strapi from 'strapi-sdk-javascript';

// https://btjapi.laoitdev.com/

const strapi = new Strapi('https://assetapix.laoitdev.com/');

export const URL = 'https://assetapix.laoitdev.com/';

export const AUTH_URL = 'https://assetapix.laoitdev.com';

// const strapi = new Strapi('http://localhost:1337/');

// export const URL = 'http://localhost:1337/';

// export const AUTH_URL = 'http://localhost:1337';

export default strapi;
