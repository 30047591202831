import React, { useState, useEffect } from 'react';
import strapi, { URL } from '../../../../strapi-sdk';

import { material_lang, import_lang, withdrawal_lang, branch_lang } from '../../../../i18n/lo-LA';

import { Button, Pagination, PaginationItem, PaginationLink, Table, Input, FormGroup, Row, Col } from 'reactstrap';
import Switch from 'react-bootstrap-switch';

import useGlobal from '../../../../store';

import { Link } from 'react-router-dom';
import moment from 'moment';

import _ from 'lodash';

const MaterialTable = props => {
	const [globalState, globalActions] = useGlobal();
	const [warehouses, setWarehouses] = useState(null);
	const [racks, setRacks] = useState(null);
	const [loading, setLoading] = useState(true);

	// 14/07/2019 Added Pagination
	const [CountPages, setCountPages] = useState(null);
	const [page, setPage] = useState(1);

	// search
	const [search, setSearch] = useState('');

	// fetch data from API
	// using Strapi SDK
	// Update fetched data to Global State

	useEffect(
		() => {
			// async function run() {
			// 	// Update the document title using the browser API
			// 	setWarehouses(await strapi.getEntries('warehouses'));
			// 	setRacks(await strapi.getEntries('racks'));
			// 	const res = await strapi.request('GET', `${URL}materials?materialtype=${props.type}`);
			// 	const count = await strapi.request('GET', `${URL}materials/count/?materialtype=${props.type}`);
			// 	console.log(count);
			// 	globalActions.updateMaterialsList(res);
			// 	setLoading(false);
			// }
			// run();
			fetchData(1);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[globalActions]
	);

	const fetchData = async p => {
		setLoading(true);
		let countPage;
		const LIMIT = 15;
		setPage(p);
		let local_search = '';
		if (search !== '') local_search = `&name_contains=${search}`;

		countPage = await strapi.request('GET', `${URL}materials/count/?${local_search}&materialtype=${props.type}`);

		const res = await strapi.request(
			'GET',
			`${URL}materials?${local_search}&materialtype=${props.type}&_start=${(p - 1) * 15}&_limit=${LIMIT}`
		);

		setCountPages(Math.ceil(countPage / LIMIT));
		globalActions.updateMaterialsList(res);
		setLoading(false);
	};

	const getWarehouseName = id => {
		const result = warehouses.filter(warehouse => warehouse.id === id);
		// return result.name;
		return result[0].name;
	};
	const getRacks = id => {
		const result = racks.filter(rack => rack.id === id);
		// return result.name;
		return result[0].name;
	};

	// UPDATING HIDE STATUS
	const updateStatus = item => {
		let newItem = {
			hide: !item.hide
		};

		function updateList(elements) {
			const newList = globalState.materials.map(x => {
				if (x.id === elements.id) {
					x.hide = elements.hide;
				}
				return x;
			});
			globalActions.updateMaterialsList(newList);
		}

		strapi
			.updateEntry('materials', item.id, newItem)
			.then(res => {
				updateList(res);
				return true;
			})
			.catch(err => {
				updateList(item);
				// debugger;
				return false;
			});
	};

	// END UPDATING

	return (
		<React.Fragment>
			<Row style={{ borderBottom: 'dashed', borderWidth: '2px', borderColor: '#ccc' }}>
				<Col sm="12" md={{ size: 6, offset: 3 }}>
					<form
						onSubmit={e => {
							e.preventDefault();
							fetchData(1);
						}}
					>
						<FormGroup>
							<Row>
								<Col md={8}>
									<Input
										type="text"
										name="search"
										style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}
										placeholder={material_lang.SEARCH_MATERIAL_BY_NAME}
										onChange={e => setSearch(e.target.value)}
									/>
								</Col>
								<Col md={4}>
									<Button style={{ fontSize: '1rem' }} onClick={() => fetchData(1)}>
										{material_lang.SEARCH}
									</Button>
								</Col>
							</Row>
						</FormGroup>
					</form>
				</Col>
			</Row>

			{_.isEmpty(globalState.materials) ? (
				<React.Fragment>
					<Table>
						<tbody>
							<tr>
								<td />
								<td />
								<td />
								<td>ບໍ່ມີວັດສະດຸໃນໝວດນີ້</td>
								<td />
							</tr>
						</tbody>
					</Table>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Table responsive striped hover style={{ backgroundColor: 'white', fontSize: '0.9rem' }}>
						<thead>
							<tr>
								<th style={{ fontSize: '0.9rem', width: '5%' }}>#</th>
								{/* <th style={{ fontSize: '0.9rem', width: '5%' }}>{material_lang.STATUS}</th> */}
								{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem', width: '10%' }}>{material_lang.MATERIAL_CODE}</th>
								) : null}
								{props.location.pathname.indexOf('tool-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem', width: '10%' }}>{material_lang.TOOL_CODE}</th>
								) : null}
								{props.location.pathname.indexOf('raw-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem', width: '10%' }}>{material_lang.RAW_CODE}</th>
								) : null}
								<th style={{ fontSize: '0.9rem' }}>{material_lang.NAME}</th>
								{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem' }}>{material_lang.SERIAL_NUMBER}</th>
								) : null}
								{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem' }}>{material_lang.WARRANTY_DATE}</th>
								) : null}
								<th style={{ fontSize: '0.9rem', textAlign: 'center' }}>
									{material_lang.TOTAL_QUANTITY}
								</th>
								{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
									<th style={{ fontSize: '0.9rem' }}>{material_lang.MATERIAL_STATUS}</th>
								) : null}
								{/* <th style={{ fontSize: '0.9rem' }}>{material_lang.WAREHOUSE}</th> */}
								<th style={{ fontSize: '0.9rem' }} className="text-right">
									{branch_lang.ACTION}
								</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td>Loading...</td>
								</tr>
							) : (
								_.map(globalState.materials, (item, idx) => {
									return (
										<tr key={item.id}>
											<td>{page === 1 ? idx + 1 : (page - 1) * 15 + (idx + 1)}</td>
											{/* <td>
												<Switch
													onColor="default"
													offColor="default"
													// defaultValue={!item.hide}
													value={!item.hide}
													onChange={() => updateStatus(item)}
												/>
											</td> */}
											<td>
												{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
													<span>M-{_.padStart(item.id, 4, 0)}</span>
												) : null}
												{props.location.pathname.indexOf('tool-materials') !== -1 ? (
													<span>T-{_.padStart(item.id, 4, 0)}</span>
												) : null}
												{props.location.pathname.indexOf('raw-materials') !== -1 ? (
													<span>R-{_.padStart(item.id, 4, 0)}</span>
												) : null}
											</td>
											<td>
												{item.name}
												{/* {item.materialtype.id === 3 ? `(${item.serial_number})` : null} */}
											</td>
											{item.materialtype.id === 3 ? <td>{item.serial_number}</td> : null}
											{item.materialtype.id === 3 ? (
												<td>{moment(item.warranty).format('DD-MM-YYYY')}</td>
											) : null}
											<td style={{ textAlign: 'center' }}>
												{item.materialwarehouses.reduce(function(acc, obj) {
													return acc + obj.quantity;
												}, 0)}{' '}
												{item.unit.name}
											</td>
											{item.materialtype.id === 3 ? (
												<td>
													{item.status === 'new' ? withdrawal_lang.STATUS_NEW : null}
													{item.status === 'broken' ? withdrawal_lang.STATUS_BROKEN : null}
													{item.status === 'good' ? withdrawal_lang.STATUS_GOOD : null}
													{item.status === 'lost' ? withdrawal_lang.STATUS_LOST : null}
												</td>
											) : null}
											{/* <td>
										{item.materialwarehouses.map((w, idx) => {
											return (
												<p key={w.id}>
													{getWarehouseName(w.warehouse) +
														' (' +
														w.quantity +
														' ' +
														item.unit.name +
														') (' +
														getRacks(w.rack) +
														')'}
												</p>
											);
										})}
									</td> */}
											<td className="text-right">
												{props.type === '1' ? (
													<Link to={`/page/raw-materials/edit/${item.id}`}>
														<Button className="btn" color="success" size="sm">
															<i className="fa fa-edit" />
															{material_lang.EDIT}
														</Button>
													</Link>
												) : null}
												{props.type === '2' ? (
													<Link to={`/page/tool-materials/edit/${item.id}`}>
														<Button className="btn" color="success" size="sm">
															<i className="fa fa-edit" />
															{material_lang.EDIT}
														</Button>
													</Link>
												) : null}
												{props.type === '3' ? (
													<Link to={`/page/longterm-materials/edit/${item.id}`}>
														<Button className="btn" color="success" size="sm">
															<i className="fa fa-edit" />
															{material_lang.EDIT}
														</Button>
													</Link>
												) : null}
											</td>
										</tr>
									);
								})
							)}
						</tbody>
					</Table>
					<br />
					<Pagination listClassName="justify-content-center">
						{page - 1 === 0 ? null : (
							<PaginationItem>
								<PaginationLink tag="span" onClick={() => fetchData(page - 1)}>
									{import_lang.PREVIOUS}
								</PaginationLink>
							</PaginationItem>
						)}
						{_.range(1, CountPages + 1).map(p => (
							<PaginationItem key={p} active={page === p ? true : false}>
								<PaginationLink tag="span" onClick={() => fetchData(p)}>
									{p}
								</PaginationLink>
							</PaginationItem>
						))}

						{page === CountPages || CountPages === 0 ? null : (
							<PaginationItem>
								<PaginationLink tag="span" onClick={() => fetchData(page + 1)}>
									{import_lang.NEXT}
								</PaginationLink>
							</PaginationItem>
						)}
					</Pagination>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default MaterialTable;
