import React, { useState, useEffect, useRef } from 'react';

import {
	Button,
	Table,
	Input,
	Card,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Form,
	CardBody,
	CardHeader,
	CardFooter,
	Row,
	Col,
	Label
} from 'reactstrap';

import ReactDatetime from 'react-datetime';

import Select from 'react-select';

import strapi, { URL } from '../../../../strapi-sdk';

import useGlobal from '../../../../store';

import _ from 'lodash';

import '../../../../assets/scss/selectModal.css';

import { validateName } from '../../../../utils/validate';
import { material_lang, material_form_lang, modal_lang } from '../../../../i18n/lo-LA';

import MaterialFormModal from './MaterialFormModal';

import NotificationAlert from 'react-notification-alert';
import Material from '../../../pages/Material/Material';
import MaterialAddValueModal from './MaterialAddValueModal';
import MaterialLogModal from './MaterialLogModal';

import moment from 'moment';

const MaterialForm = props => {
	const [globalState, globalActions] = useGlobal();
	const [data, setData] = useState({
		name: '',
		unit: null,
		materialwarehouses: null
	});
	const [materialID, setMaterialID] = useState(null);
	const [err, setErr] = useState([]);
	const [units, setUnits] = useState([]);
	const [materialWarehouses, setMaterialWarehouses] = useState(null);
	const [selectedUnit, setSelectedUnit] = useState(null);
	const [loading, setLoading] = useState(true);
	const [contentLoading, setContentLoading] = useState(true);
	const [alert, setAlert] = useState(null);
	const [ModalMaterialForm, setModalMaterialForm] = useState(false);
	const [title, setTitle] = useState('');
	const [materialWarehouseID, setMaterialWarehouseID] = useState(null);
	const [currentUnit, setCurrentUnit] = useState(null);
	const [currentQuantity, setCurrentQuantity] = useState(0);

	// TYPE LONGTERM MATERIAL
	const [suppliers, setSuppliers] = useState(null);
	const [selectedSupplier, setSelectedSupplier] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const refNotification = useRef(null);

	// function useFetch() {
	async function fetchData() {
		const resUnits = await strapi.getEntries('units');
		await getUnits(resUnits);

		// long term
		if (props.location.pathname.indexOf('longterm-materials') !== -1) {
			const resSuppliers = await strapi.getEntries('suppliers');
			await getSuppliers(resSuppliers);
		}
		setLoading(false);
		// end
	}

	async function getMaterialWarehouses(queries) {
		setContentLoading(true);
		if (!_.isEmpty(queries)) {
			queries = newMaterialWarehousesURL(queries);
			const resMW = await strapi.request('GET', URL + queries);
			setMaterialWarehouses(resMW);
		} else {
			setMaterialWarehouses([]);
		}
		setContentLoading(false);
	}

	async function getReadyPage(id) {
		const res = await strapi.getEntry('materials', id);
		setCurrentUnit(res.unit.name);

		if (props.location.pathname.indexOf('longterm-materials') !== -1) {
			setData({
				name: res.name,
				unit: res.unit.id,
				materialwarehouses: res.materialwarehouses,
				supplier: res.supplier.id,
				serial_number: res.serial_number,
				status: res.status,
				buy_date: moment(res.buy_date).format('YYYY-MM-DD HH:mm:ss'),
				warranty: moment(res.warranty).format('YYYY-MM-DD HH:mm:ss')
			});
			setSelectedSupplier({ label: res.supplier.name, value: res.supplier.id });
			let statusArr = [
				{ value: 'new', label: material_lang.STATUS_NEW },
				{ value: 'good', label: material_lang.STATUS_GOOD },
				{ value: 'broken', label: material_lang.STATUS_BROKEN },
				{ value: 'lost', label: material_lang.STATUS_LOST }
			];
			setSelectedStatus(statusArr.filter(item => item.value === res.status)[0]);
		} else {
			setData({
				name: res.name,
				unit: res.unit.id,
				materialwarehouses: res.materialwarehouses
			});
		}
		getMaterialWarehouses(res.materialwarehouses);
		setSelectedUnit({ label: res.unit.name, value: res.unit.id });
	}

	useEffect(
		() => {
			fetchData();
			// console.log(props.match.params.id);
			setMaterialID(props.match.params.id);
			if (props.match.params.id !== null && props.match.params.id !== undefined) {
				setMaterialID(props.match.params.id);

				async function fetchDatafromID(materialID) {
					await getReadyPage(materialID);
				}
				fetchDatafromID(props.match.params.id);
			} else {
				setData({
					name: '',
					unit: null,
					materialwarehouses: null
				});
				setSelectedUnit(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.match.params.id]
	);

	function newMaterialWarehousesURL(res) {
		const newUrl = res.reduce((x, item) => {
			return x + '&id_in=' + item.id;
		}, 'materialwarehouses?');

		return newUrl;
	}

	async function getUnits(units_param) {
		let units_array = [];
		units_param.map(unit => {
			units_array = [...units_array, { value: unit.name, label: unit.name, id: unit.id }];
		});
		setUnits(units_array);
	}

	async function getSuppliers(suppliers_param) {
		let suppliers_array = [];
		suppliers_param.map(unit => {
			suppliers_array = [...suppliers_array, { value: unit.name, label: unit.name, id: unit.id }];
		});
		setSuppliers(suppliers_array);
	}

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit() {
		let errors = {};
		if (!validateName(data.name)) {
			errors.MaterialName = modal_lang.VALIDATE_NAME;
		}

		if (data.unit === null) errors.Unit = modal_lang.REQUIRE_UNIT;

		if (props.location.pathname.indexOf('longterm-materials') !== -1) {
			if (!data.supplier) errors.Supplier = modal_lang.REQUIRE_SUPPLIER;
			if (!data.warranty) errors.Warranty = modal_lang.REQUIRE_WARRANTY;
			if (!data.status) errors.Status = modal_lang.REQUIRE_STATUS;
			if (!data.buy_date) errors.BuyDate = material_lang.REQUIRE_BUY_DATE;
			if (!data.serial_number) errors.serial_number = material_lang.REQUIRE_SERIAL_NUMBER;
		}

		if (!_.isEmpty(errors)) {
			setErr(errors);
		} else {
			try {
				let createRes;
				if (props.match.params.id === null || props.match.params.id === undefined) {
					if (props.location.pathname.indexOf('raw-materials') !== -1) {
						data.materialtype = 1;
					}
					if (props.location.pathname.indexOf('tool-materials') !== -1) {
						data.materialtype = 2;
					}
					if (props.location.pathname.indexOf('longterm-materials') !== -1) {
						data.materialtype = 3;
					}
					createRes = await strapi.createEntry('materials', data);
					setMaterialID(createRes.id);
				} else {
					createRes = await strapi.updateEntry('materials', props.match.params.id, data);
				}
				globalActions.updateMaterialsList(await strapi.getEntries('materials'));
				getReadyPage(createRes.id);
				setErr([]);
				notify('tr', 2, modal_lang.SUCCESS);
			} catch {
				notify('tr', 3, modal_lang.FAIL);
			}
		}
	}

	// FORM MODAL
	const showAddModal = () => {
		setMaterialWarehouseID(null);
		setCurrentQuantity(0);
		setTitle(material_lang.ADD);
		setModalMaterialForm(!ModalMaterialForm);
	};

	const showEditModal = (id, quantity) => {
		setTitle(material_lang.EDIT);
		setMaterialWarehouseID(id);
		setCurrentQuantity(quantity);
		setModalMaterialForm(!ModalMaterialForm);
	};

	const hideModal = () => {
		setMaterialWarehouseID(null);
		setModalMaterialForm(false);
	};

	const notify = (place, color, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: <div>{message}</div>,
			type: type,
			icon: 'nc-icon nc-bell-55',
			autoDismiss: 7
		};
		refNotification.current.notificationAlert(options);
	};

	return (
		<React.Fragment>
			<Material location={props.location.pathname}>
				<Row>
					{alert}
					<NotificationAlert ref={refNotification} />
					{contentLoading ? null : (
						<MaterialFormModal
							ModalMaterialForm={ModalMaterialForm}
							showModal={materialWarehouseID === null ? showAddModal : showEditModal}
							title={title}
							hideModal={hideModal}
							notify={notify}
							materialID={materialID}
							materialWarehouseID={materialWarehouseID}
							getReadyPage={getReadyPage}
							unit={currentUnit}
							currentQuantity={currentQuantity}
						/>
					)}
					<Col sm="12" md={{ size: 4 }}>
						<Card>
							<CardHeader tag="h3">
								{props.match.params.method === 'add' ? material_form_lang.ADD : material_form_lang.EDIT}
								{props.location.pathname.indexOf('longterm-materials') !== -1
									? material_lang.MATERIAL_THINGS
									: null}
								{props.location.pathname.indexOf('tool-materials') !== -1 ? material_lang.TOOL : null}
								{props.location.pathname.indexOf('raw-materials') !== -1 ? material_lang.RAW : null}
							</CardHeader>
							<CardBody>
								<Form action="" className=" form" method="">
									<Row>
										<Col sm="12">
											<Label style={{ fontSize: '1.0rem' }}>
												{modal_lang.MATERIAL_NAME} <span style={{ color: 'red' }}>*</span>
											</Label>
											<InputGroup size="md" className={err.MaterialName && 'has-danger'}>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														{/* <i className=" nc-icon nc-shop" /> */}
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="name"
													placeholder={material_lang.NAME}
													type="text"
													style={{ borderColor: err.MaterialName && 'red' }}
													value={data.name}
													onChange={onChange}
												/>
											</InputGroup>
											{err.MaterialName && (
												<span
													className="text-center"
													style={{ color: 'red', fontSize: '1.1rem' }}
												>
													{err.MaterialName && err.MaterialName}
												</span>
											)}
										</Col>
										<Col sm="12">
											{loading ? (
												<p>Loading...</p>
											) : (
												<React.Fragment>
													<Label
														style={{ fontSize: '1.0rem' }}
														className="SelectInModalMarginTop"
													>
														{modal_lang.UNIT} <span style={{ color: 'red' }}>*</span>
													</Label>
													<Select
														className="react-select primary SelectInModalFont"
														classNamePrefix="react-select"
														name="unit"
														value={selectedUnit}
														onChange={value => {
															setData({ ...data, unit: value.id });
															setSelectedUnit(value);
														}}
														options={units}
														placeholder={material_form_lang.CHOOSE_UNIT}
													/>
													{err.Unit && (
														<span
															className="text-center"
															style={{ color: 'red', fontSize: '1.1rem' }}
														>
															{err.Unit}
														</span>
													)}
												</React.Fragment>
											)}
											{/* </InputGroup> */}
										</Col>
										{/* TYPE : LONG TERM MATERIAL */}
										{props.location.pathname.indexOf('longterm-materials') !== -1 && (
											<Col sm="12">
												{loading ? (
													<p>Loading...</p>
												) : (
													<React.Fragment>
														<Label
															style={{ fontSize: '1.0rem' }}
															className="SelectInModalMarginTop"
														>
															{modal_lang.SUPPLIER}{' '}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<Select
															className="react-select primary SelectInModalFont"
															classNamePrefix="react-select"
															name="supplier"
															value={selectedSupplier}
															onChange={value => {
																setData({ ...data, supplier: value.id });
																setSelectedSupplier(value);
															}}
															options={suppliers}
															placeholder={material_lang.CHOOSE_SUPPLIER}
														/>
														{err.Supplier && (
															<p
																style={{
																	color: 'red',
																	fontSize: '1.1rem',
																	marginBottom: 0
																}}
															>
																{err.Supplier}
															</p>
														)}
														{/* SERIAL NUMBER */}
														<Label
															style={{ fontSize: '1.0rem' }}
															className="SelectInModalMarginTop"
														>
															{modal_lang.SERIAL_NUMBER}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<InputGroup
															size="md"
															className={err.serial_number && 'has-danger'}
														>
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	{/* <i className=" nc-icon nc-shop" /> */}
																</InputGroupText>
															</InputGroupAddon>
															<Input
																name="serial_number"
																placeholder={material_lang.SERIAL_NUMBER}
																type="text"
																defaultValue={data.serial_number}
																onChange={onChange}
															/>
														</InputGroup>
														{err.serial_number && (
															<p
																style={{
																	color: 'red',
																	fontSize: '1.1rem',
																	marginBottom: 0
																}}
															>
																{err.serial_number}
															</p>
														)}
														{/* BUY_DATE */}
														<Label
															style={{ fontSize: '1.0rem' }}
															className="SelectInModalMarginTop"
														>
															{material_lang.BUY_DATE}{' '}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<ReactDatetime
															// dateFormat="YYYY-MM-DD HH:mm:ss"
															defaultValue={data.buy_date || ''}
															inputProps={{
																className: 'form-control',
																placeholder: material_lang.BUY_DATE
															}}
															onChange={e => {
																setData({
																	...data,
																	buy_date: moment(e).format('YYYY-MM-DD HH:mm:ss')
																});
															}}
														/>
														{err.BuyDate && (
															<span
																className="text-center"
																style={{ color: 'red', fontSize: '1.1rem' }}
															>
																{err.BuyDate}
																<br />
															</span>
														)}
														{/* WARRANTY */}
														<Label
															style={{ fontSize: '1.0rem' }}
															className="SelectInModalMarginTop"
														>
															{modal_lang.WARRANTY}{' '}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<ReactDatetime
															// dateFormat="YYYY-MM-DD HH:mm:ss"
															defaultValue={data.warranty || ''}
															inputProps={{
																className: 'form-control',
																placeholder: material_lang.WARRANTY
															}}
															onChange={e => {
																setData({
																	...data,
																	warranty: moment(e).format('YYYY-MM-DD HH:mm:ss')
																});
															}}
														/>
														{err.Warranty && (
															<span
																className="text-center"
																style={{ color: 'red', fontSize: '1.1rem' }}
															>
																{err.Warranty}
																<br />
															</span>
														)}

														{/* STATUS */}
														<Label
															style={{ fontSize: '1.0rem' }}
															className="SelectInModalMarginTop"
														>
															{modal_lang.STATUS} <span style={{ color: 'red' }}>*</span>
														</Label>
														<Select
															className="react-select primary SelectInModalFont"
															classNamePrefix="react-select"
															name="status"
															defaultValue={selectedStatus}
															onChange={value => {
																setData({ ...data, status: value.value });
															}}
															// options={branches}
															options={[
																{ value: 'new', label: material_lang.STATUS_NEW },
																{ value: 'good', label: material_lang.STATUS_GOOD },
																{ value: 'broken', label: material_lang.STATUS_BROKEN },
																{ value: 'lost', label: material_lang.STATUS_LOST }
															]}
															placeholder={material_lang.CHOOSE_STATUS}
														/>
														{err.Status && (
															<span
																className="text-center"
																style={{ color: 'red', fontSize: '1.1rem' }}
															>
																{err.Status}
															</span>
														)}
													</React.Fragment>
												)}
											</Col>
										)}
									</Row>
								</Form>
							</CardBody>
							<CardFooter className="text-muted">
								<Button
									block
									className=" btn-default "
									style={{ fontSize: '1.1rem' }}
									color="default"
									onClick={() => handleSubmit()}
								>
									{materialID === null || materialID === undefined
										? material_form_lang.SUBMIT
										: material_form_lang.SUBMIT_EDIT}
								</Button>
							</CardFooter>
						</Card>
					</Col>
					<Col sm="12" md="8">
						<Card>
							<CardHeader tag="h3" className="clear-fix">
								<span className="pull-left">{material_form_lang.HEADER}</span>
								<span className="pull-right">
									<Button
										color="primary"
										size="sm"
										style={{ margin: 'unset', fontSize: '1.1rem' }}
										onClick={() => showAddModal()}
										disabled={
											materialID === undefined ||
											props.location.pathname.indexOf('longterm-materials') !== -1
												? true
												: false
										}
									>
										{material_form_lang.ADD_DETAIL}
									</Button>
								</span>
							</CardHeader>
							<CardBody>
								{contentLoading ? (
									<center>
										{materialID === undefined
											? material_form_lang.NO_ID
											: material_form_lang.LOADING}
									</center>
								) : _.isEmpty(materialWarehouses) ? (
									material_form_lang.MATERIAL_WAREHOUSE_EMPTY
								) : (
									<Table striped style={{ fontSize: '1.1rem' }}>
										<thead>
											<tr>
												<th>{material_form_lang.WAREHOUSE}</th>
												<th>{material_form_lang.RACK}</th>
												<th>{material_form_lang.QUANTITY}</th>
												<th>{material_form_lang.ACTION}</th>
											</tr>
										</thead>
										<tbody>
											{materialWarehouses.map(item => (
												<tr key={item.id}>
													<td>
														<MaterialLogModal title={item.warehouse.name} id={item.id} />
													</td>
													<td>{item.rack.name}</td>
													<td>{item.quantity + currentUnit}</td>
													<td>
														<MaterialAddValueModal
															id={item.id}
															title={material_form_lang.ADD_MATERIAL}
															materialID={materialID}
															material={data.name}
															quantity={item.quantity}
															unit={currentUnit}
															warehouse={item.warehouse.name}
															getReadyPage={getReadyPage}
															notify={notify}
															rack={item.rack.name}
														/>{' '}
														<Button
															className="btn"
															color="success"
															size="sm"
															onClick={() => showEditModal(item.id, item.quantity)}
														>
															<i className="fa fa-edit" />
															{material_form_lang.EDIT}
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Material>
		</React.Fragment>
	);
};

export default MaterialForm;
