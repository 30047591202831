import React, { useState, useEffect } from 'react';

import {
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Button,
	InputGroup,
	InputGroupText,
	InputGroupAddon,
	Input
} from 'reactstrap';

import { supplier_lang } from '../../../i18n/lo-LA';

import strapi from '../../../strapi-sdk';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import history from '../../../history';

import { Link } from 'react-router-dom';

const SupplierForm = props => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({
		name: '',
		tel: '',
		address: ''
	});

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	const handleSubmit = () => {
		strapi
			.createEntry('suppliers', data)
			.then(res => {
				setVisible(true);
				ToastsStore.success(supplier_lang.SUCCESS);
			})
			.catch(err => {
				setVisible(true);
				ToastsStore.error(supplier_lang.FAILED);
			})
			.finally(() => {
				setTimeout(() => {
					setVisible(false);
					history.push('/page/suppliers');
				}, 1500);
			});
	};

	return (
		<div className="content">
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<Row>
				<Col sm={12} md={6}>
					<Card>
						<CardHeader
							tag="h3"
							style={{
								borderBottom: 'dashed',
								borderWidth: '2px',
								borderColor: '#ccc',
								padding: 5,
								textAlign: 'center'
							}}
							className="d-flex"
						>
							<div className="">
								<Link to="/page/suppliers/" className="">
									<Button color="danger" style={{ fontSize: '1.1rem' }}>
										<i className="fas fa-arrow-circle-left" /> ກັບຄືນ
									</Button>
								</Link>
							</div>
							<div className="w-75 align-self-center">{supplier_lang.SUPPLIER}</div>
						</CardHeader>
						<CardBody>
							<Row>
								<Col sm={12}>
									<InputGroup size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-single-02" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="name"
											placeholder={supplier_lang.NAME}
											type="text"
											defaultValue={data.name}
											onChange={onChange}
										/>
									</InputGroup>
								</Col>
								<Col sm={12}>
									<InputGroup size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-mobile" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="tel"
											placeholder={supplier_lang.TEL}
											type="text"
											// style={{ borderColor: err.tel && 'red' }}
											defaultValue={data.tel}
											onChange={onChange}
										/>
									</InputGroup>
								</Col>
								<Col sm={12}>
									<InputGroup size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-send" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											name="address"
											placeholder={supplier_lang.ADDRESS}
											type="text"
											// style={{ borderColor: err.rackName && 'red' }}
											defaultValue={data.address}
											onChange={onChange}
										/>
									</InputGroup>
								</Col>
							</Row>
						</CardBody>
						<CardFooter
							style={{
								textAlign: 'center',
								fontSize: '1.2rem',
								borderTop: 'dashed',
								borderWidth: '2px',
								borderColor: '#ccc'
							}}
						>
							<Button onClick={handleSubmit} disabled={visible ? true : false}>
								{supplier_lang.SUBMIT}
							</Button>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default SupplierForm;
