import React, { useState } from 'react';

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
	Row
} from 'reactstrap';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { strapiLogin } from '../../utils/auth';

import { login_lang } from '../../i18n/lo-LA';

import { withRouter } from 'react-router-dom';

import history from '../../history';

const Login = props => {
	const [data, setData] = useState({
		username: '',
		password: ''
	});

	const [error, setError] = useState({ incorrect: false });
	const [visible, setVisible] = useState(false);

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	const handleSubmit = async e => {
		e.preventDefault();
		strapiLogin(data.username, data.password)
			.then(async res => {
				setVisible(true);
				setError({ incorrect: false });
				ToastsStore.success(`${login_lang.WELCOME + ' ' + localStorage.getItem('username')}`);
				setTimeout(() => {
					setVisible(false);
					setError({ incorrect: false });
					history.push('/');
				}, 2000);
			})
			.catch(err => {
				setError({ incorrect: true });
			});
	};

	return (
		<div className="login-page">
			<Container>
				{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
				<Row>
					<Col className="ml-auto mr-auto" lg="4" md="6">
						<Form action="" className="form" method="">
							<Card className="card-login">
								<CardHeader>
									<CardHeader>
										<h3 className="header text-center" style={{ marginBottom: 'unset' }}>
											{login_lang.LOGIN}
										</h3>
									</CardHeader>
								</CardHeader>
								<CardBody>
									{error.incorrect ? (
										<p
											style={{
												color: 'red',
												fontSize: '1.1rem',
												fontWeight: 700,
												textAlign: 'center'
											}}
										>
											{login_lang.INCORRECT}
										</p>
									) : (
										''
									)}
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-single-02" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder={login_lang.USERNAME}
											type="text"
											name="username"
											onChange={onChange}
										/>
									</InputGroup>
									<InputGroup style={{ marginBottom: 'unset' }}>
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="nc-icon nc-key-25" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder={login_lang.PASSWORD}
											type="password"
											autoComplete="off"
											name="password"
											onChange={onChange}
										/>
									</InputGroup>
								</CardBody>
								<CardFooter>
									<Button
										block
										type="submit"
										className="btn-round mb-3"
										color="warning"
										style={{ fontSize: '1.1rem' }}
										disabled={visible ? true : false}
										onClick={e => handleSubmit(e)}
									>
										{login_lang.LOGIN}
									</Button>
								</CardFooter>
							</Card>
						</Form>
					</Col>
				</Row>
			</Container>
			<div
				className="full-page-background"
				style={{
					backgroundImage: `url(${require('../../assets/img/bg/fabio-mangione.jpg')})`
				}}
			/>
		</div>
	);
};

export default withRouter(Login);
