import React from 'react';

import { Table } from 'reactstrap';

import _ from 'lodash';

import { department_lang, branch_lang } from '../../../../../i18n/lo-LA';

import useGlobal from '../../../../../store';
import SubModal from './SubModal';

const DepartmentFormTable = props => {
	const [globalState, globalActions] = useGlobal();
	let count = 0;
	return (
		<React.Fragment>
			{_.isEmpty(globalState.subdepartments) ? (
				<h3 style={{ textAlign: 'center' }}>{department_lang.NOT_YET_HAVE}</h3>
			) : (
				<Table striped style={{ fontSize: '1.2rem' }}>
					<thead>
						<tr>
							<th>#</th>
							<th>{department_lang.SUB_DEPARTMENT}</th>
							<th>{branch_lang.ACTION}</th>
						</tr>
					</thead>
					<tbody>
						{globalState.subdepartments.map(sub => (
							<tr key={sub.id}>
								<th scope="row">{(count = count + 1)}</th>
								<td>{sub.name}</td>
								<td>
									<SubModal mode="edit" sid={sub.id} name={sub.name} />
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</React.Fragment>
	);
};

export default DepartmentFormTable;
