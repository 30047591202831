import React from 'react';

import { material_lang } from '../../../i18n/lo-LA';

import { Row, Col, Button, Card, CardHeader } from 'reactstrap';

import { Link } from 'react-router-dom';

import '../titleBar.css';

const Material = props => {
	return (
		<div className="content">
			<Card>
				<CardHeader>
					<Row>
						<Col lg="7" xs="6">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{material_lang.ALL_MATERIAL_TYPE}
							</h3>
						</Col>
						<Col lg="5" xs="6">
							<div className="pull-right">
								{props.location.indexOf('raw-materials') !== -1 ? (
									<Link
										to={
											window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? `/page/raw-materials/`
												: `/page/raw-materials/add`
										}
									>
										<Button
											size="sm"
											style={{ marginTop: 0, fontSize: '1.2rem' }}
											color={
												window.location.href.indexOf('/add') !== -1 ||
												window.location.href.indexOf('/edit') !== -1
													? 'danger'
													: ''
											}
										>
											{window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? material_lang.BACK
												: material_lang.ADD_NEW}
										</Button>
									</Link>
								) : null}

								{props.location.indexOf('tool-materials') !== -1 ? (
									<Link
										to={
											window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? `/page/tool-materials/`
												: `/page/tool-materials/add`
										}
									>
										<Button
											size="sm"
											style={{ marginTop: 0, fontSize: '1.2rem' }}
											color={
												window.location.href.indexOf('/add') !== -1 ||
												window.location.href.indexOf('/edit') !== -1
													? 'danger'
													: ''
											}
										>
											{window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? material_lang.BACK
												: material_lang.ADD_NEW}
										</Button>
									</Link>
								) : null}

								{props.location.indexOf('longterm-materials') !== -1 ? (
									<Link
										to={
											window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? `/page/longterm-materials/`
												: `/page/longterm-materials/add`
										}
									>
										<Button
											size="sm"
											style={{ marginTop: 0, fontSize: '1.2rem' }}
											color={
												window.location.href.indexOf('/add') !== -1 ||
												window.location.href.indexOf('/edit') !== -1
													? 'danger'
													: ''
											}
										>
											{window.location.href.indexOf('/add') !== -1 ||
											window.location.href.indexOf('/edit') !== -1
												? material_lang.BACK
												: material_lang.ADD_NEW}
										</Button>
									</Link>
								) : null}
							</div>
						</Col>
					</Row>
				</CardHeader>
			</Card>
			{props.children}
		</div>
	);
};

export default Material;
