import React from 'react';
import useGlobalHook from 'use-global-hook';

import * as actions from '../actions';

const initialState = {
	branches: [],
	categories: [],
	subcategories: [],
	warehouses: [],
	departments: [],
	racks: [],
	material_types: [],
	material_sub_types: [],
	materials: [],
	subdepartments: [],
	withdraw_emp: null,
	withdraw_list: [],
	withdraw_page_count: 1,
	withdraw_current_page: 1
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
