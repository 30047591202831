import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardBody, CardHeader, CardFooter, Table, Spinner, Button } from 'reactstrap';

import { supplier_lang } from '../../i18n/lo-LA';

import { Link } from 'react-router-dom';

import strapi from '../../strapi-sdk';

const Supplier = props => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		strapi.getEntries('suppliers').then(res => {
			setData(res);
			setLoading(false);
		});
	}, []);

	return (
		<div className="content">
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader tag="h3" className="clearfix">
							<span className="pull-left">{supplier_lang.SUPPLIER_LIST}</span>
							<span className="pull-right">
								<Link to={`/page/suppliers/add`}>
									<Button
										className="btn"
										// color="success"
										size="sm"
										style={{ fontSize: '1.1rem' }}
									>
										{supplier_lang.ADD}
									</Button>
								</Link>
							</span>
						</CardHeader>
						{loading ? (
							<Spinner style={{ width: '3rem', height: '3rem' }} />
						) : (
							<CardBody>
								<Table striped style={{ fontSize: '1.1rem' }}>
									<thead>
										<tr>
											<th>#</th>
											<th>{supplier_lang.SUPPLIER}</th>
											<th>{supplier_lang.TEL_TABLE}</th>
											<th>{supplier_lang.ADDRESS_TABLE}</th>
											<th>{''}</th>
										</tr>
									</thead>
									<tbody>
										{data.map((item, idx) => (
											<tr key={item.id}>
												<th scope="row" width="5%">
													{idx + 1}
												</th>
												<td width="25%">{item.name}</td>
												<td width="20%">{item.tel}</td>
												<td width="40%">{item.address}</td>
												<td width="10%" style={{ textAlign: 'right' }}>
													<Link to={`/page/suppliers/edit/${item.id}`}>
														<Button
															className="btn"
															color="success"
															size="sm"
															style={{ fontSize: '1.1rem' }}
														>
															<i className="fa fa-edit" />
															{supplier_lang.EDIT}
														</Button>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
						)}
						<CardFooter>{/* <Button>{supplier_lang.SUBMIT}</Button> */}</CardFooter>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default Supplier;
