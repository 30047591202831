import React, { useState, useEffect } from 'react';

import { Card, CardHeader, CardBody, CardFooter, Label, Spinner, Table, Input, FormGroup, Button } from 'reactstrap';

import Select from 'react-select';
import strapi, { URL } from '../../../strapi-sdk';

import { withdrawal_lang } from '../../../i18n/lo-LA';

import ImageUpload from '../../../components/CustomUpload/WithdrawalUpload';

import _ from 'lodash';
import WebcamCapture from '../../../utils/Webcam';

import moment from 'moment';

import ReactDatetime from 'react-datetime';

import useGlobal from '../../../store';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import history from '../../../history';

const ReturnLTForm = props => {
	const [contentLoading, setContentLoading] = useState(false);

	const [data, setData] = useState({
		return_date: moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
	});
	const [closed, setClosed] = useState(true);
	const [returnImage, setReturnImage] = useState(null);

	const [errors, setErrors] = useState({});

	//images
	const [imageOption, setImageOption] = useState('browse');

	//webcam
	const [webcamImg, setWebcamImg] = useState(null);
	const [webcamImgForm, setWebcamImgForm] = useState(null);

	const [visible, setVisible] = useState(false);
	//units
	const [units, setUnits] = useState(null);
	const [LoadingUnits, setLoadingUnits] = useState(true);

	const withdrawal = props.withdrawal;

	const [submitLoading, setSubmitLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			strapi.getEntries('units').then(res => {
				setUnits(res);
				setLoadingUnits(false);
			});
			// const res = await strapi.request('GET', `${URL}materials?materialtype=3`);
			// const newArray = res.filter(item => item.materialwarehouses.length !== 0);
		};
		fetchData();
	}, []);

	const onChangeRTQuantity = e => {
		const quantity = e.target.value;
		let error = {};
		if (quantity > withdrawal.withdraw_quantity) {
			error.RTQuantity = 'ຈຳນວນສົ່ງຄືນຕ້ອງບໍ່ຫລາຍກວ່າຈຳນວນທີ່ເບີກໄປ!';
		}
		if (quantity <= 0) error.rtQuantityLessThanZero = 'ຈຳນວນຕ້ອງບໍ່ໜ້ອຍຫຼືເທົ່າກັບ 0';
		setData({ ...data, return_quantity: quantity });
		setErrors(error);
	};

	const getUnit = id => {
		return units.filter(unit => unit.id === id)[0].name;
	};

	const handleSubmit = async () => {
		setSubmitLoading(true);
		//validation
		let error = errors;
		if (webcamImg === null && returnImage === null) error.image = 'ກະລຸນາເລືອກຮູບ ຫຼື ຖ່າຍຮູບຂອງອຸປະກອນຕອນສົ່ງຄືນ';
		if (data.return_quantity === null || data.return_quantity === undefined)
			error.quantity = 'ກະລຸນາລະບຸຈຳນວນທີ່ສົ່ງຄືນ';
		if (data.return_quantity > withdrawal.withdraw_quantity) {
			error.RTQuantity = 'ຈຳນວນສົ່ງຄືນຕ້ອງບໍ່ຫລາຍກວ່າຈຳນວນທີ່ເບີກໄປ!';
		}

		if (!_.isEmpty(error)) {
			setSubmitLoading(false);
			setVisible(true);
			ToastsStore.error('ກະລຸນາກວດຄືນຄວາມຖືກຕ້ອງ');
			error.custom = 'ກະລຸນາກວດຄືນຄວາມຖືກຕ້ອງ';
			setErrors(error);
			setTimeout(() => {
				setVisible(false);
			}, 1500);
			// setButtonLoading(false);
		} else {
			let newData = { ...data, closed: true };
			if (withdrawal.material_type === '3') {
				newData = { ...newData, return_status: data.return_status || withdrawal.withdraw_status };
			}
			try {
				const resUpdate = await strapi.updateEntry('withdrawals', withdrawal.id, newData);
				const q = {
					quantity: parseFloat(withdrawal.materialwarehouse.quantity) + parseFloat(newData.return_quantity)
				};
				const form = new FormData();
				let name = new Date().getTime();
				if (imageOption === 'browse') {
					form.append('files', returnImage, name + '.jpg');
				} else {
					form.append('files', webcamImgForm, name + '.jpg');
				}

				form.append('refId', withdrawal.id);
				form.append('ref', 'withdrawal');
				form.append('source', 'content-manager');
				form.append('field', 'return_images');
				const newStatus = { status: newData.return_status };
				const resUpdateMaterial = await strapi.updateEntry('materials', withdrawal.material.id, newStatus);
				const resUpload = await strapi.upload(form);
				const resMWarehouse = await strapi.updateEntry(
					'materialwarehouses',
					withdrawal.materialwarehouse.id,
					q
				);
				console.log(resMWarehouse);
				console.log(withdrawal);
				let logs = {
					user: localStorage.getItem('id'),
					from_value: withdrawal.materialwarehouse.quantity,
					to_value: parseFloat(withdrawal.materialwarehouse.quantity) + parseFloat(newData.return_quantity),
					action_type: 'RETURN',
					material: resMWarehouse.material.name,
					warehouse: resMWarehouse.warehouse.name,
					rack: resMWarehouse.rack.name,
					materialwarehouse: resMWarehouse.id,
					unit: getUnit(withdrawal.material.unit),
					bill_id: withdrawal.id
				};
				console.log(logs);
				const materialLogs = await strapi.createEntry('materiallogs', logs);
				setVisible(true);
				ToastsStore.success(withdrawal_lang.SUCCESS);
				setTimeout(() => {
					setSubmitLoading(false);
					setVisible(false);
					history.push('/page/withdrawal-tools/');
				}, 1500);
			} catch (err) {
				setVisible(true);
				setSubmitLoading(false);
				ToastsStore.error('ເກີດຂໍ້ຜິດພາດກະລຸນາລອງໃໝ່ອີກຄັ້ງ');
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			}
		}
	};

	return (
		<Card>
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<CardHeader>ວັດສະດຸມີປະກັນ</CardHeader>
			<CardBody>
				<Label style={{ fontSize: '1.1rem' }}>
					{withdrawal_lang.LONG_TERM_MATERIAL}
					<span style={{ color: 'red' }}>*</span>
				</Label>
				{contentLoading ? null : (
					<Table bordered style={{ marginTop: '10px' }}>
						<tbody>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>{withdrawal_lang.HAVE_QUANTITY}</td>
								<td style={{ textAlign: 'center' }}>
									{LoadingUnits
										? null
										: withdrawal.materialwarehouse.quantity +
											' ' +
											getUnit(withdrawal.material.unit)}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_QUANTITY_RETURN}
								</td>
								<td style={{ textAlign: 'center' }}>
									{LoadingUnits
										? null
										: withdrawal.withdraw_quantity + ' ' + getUnit(withdrawal.material.unit)}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.RETURN_QUANTITY}
								</td>
								<td style={{ textAlign: 'center' }}>
									<Input
										type="number"
										placeholder="ລະບຸຈຳນວນສົ່ງຄືນ"
										onChange={onChangeRTQuantity}
										className={errors.RTQuantity ? 'has-danger' : ''}
									/>
									{errors.RTQuantity ? <span style={{ color: 'red' }}>{errors.RTQuantity}</span> : ''}
									{errors.rtQuantityLessThanZero ? (
										<span style={{ color: 'red' }}>{errors.rtQuantityLessThanZero}</span>
									) : (
										''
									)}
									<p style={{ color: 'red' }}>{errors.quantity ? errors.quantity : null}</p>
								</td>
							</tr>
							{withdrawal.material_type === '3' ? (
								<tr style={{ fontSize: '1rem' }}>
									<td style={{ textAlign: 'right', fontWeight: 700 }}>
										{withdrawal_lang.RETURN_STATUS}
									</td>
									<td style={{ textAlign: 'left' }}>
										<Select
											className="react-select primary"
											classNamePrefix="react-select"
											name="status"
											defaultValue={
												withdrawal.withdraw_status === 'new'
													? { value: 'new', label: withdrawal_lang.STATUS_NEW }
													: withdrawal.withdraw_status === 'good'
														? { value: 'good', label: withdrawal_lang.STATUS_GOOD }
														: withdrawal.withdraw_status === 'broken'
															? { value: 'broken', label: withdrawal_lang.STATUS_BROKEN }
															: withdrawal.withdraw_status === 'lost'
																? { value: 'lost', label: withdrawal_lang.STATUS_LOST }
																: null
											}
											onChange={value => {
												setData({ ...data, return_status: value.value });
											}}
											// options={branches}
											options={[
												{ value: 'new', label: withdrawal_lang.STATUS_NEW },
												{ value: 'good', label: withdrawal_lang.STATUS_GOOD },
												{ value: 'broken', label: withdrawal_lang.STATUS_BROKEN },
												{ value: 'lost', label: withdrawal_lang.STATUS_LOST }
											]}
											placeholder={withdrawal_lang.CHOOSE_STATUS}
										/>
										{errors.Status && (
											<span className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
												{errors.Status}
											</span>
										)}
									</td>
								</tr>
							) : null}
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>{withdrawal_lang.RETURN_DATE}</td>
								<td>
									<ReactDatetime
										dateFormat="YYYY-MM-DD HH:mm:ss"
										defaultValue={moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')}
										inputProps={{
											className: 'form-control',
											placeholder: withdrawal_lang.CHOOSE_RETURN_DATE
										}}
										onChange={e => {
											setData({
												...data,
												return_date: moment(e).format('YYYY-MM-DD HH:mm:ss')
											});
										}}
									/>
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_PHOTO}
								</td>
								<td style={{ textAlign: 'center' }}>
									<div className="form-check-radio inline">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="return_image"
												id="radio1"
												value="browse"
												onClick={e => setImageOption(e.target.value)}
												defaultChecked
											/>
											ເລືອກຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<div className="form-check-radio">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="return_image"
												id="radio2"
												value="webcam"
												onClick={e => setImageOption(e.target.value)}
											/>
											ຖ່າຍຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<p style={{ color: 'red' }}>{errors.image ? errors.image : null}</p>
									{imageOption === 'browse' ? (
										<ImageUpload
											// image_url={
											// 	data.image === undefined || data.image === null
											// 		? null
											// 		: URL + data.image.url
											// }
											setAvatar={setReturnImage}
										/>
									) : webcamImg ? (
										<React.Fragment>
											<img src={webcamImg} />
											<Button style={{ marginTop: '10px' }} onClick={() => setWebcamImg(null)}>
												ຖ່າຍໃໝ່
											</Button>
										</React.Fragment>
									) : (
										<WebcamCapture
											setWebcamImg={setWebcamImg}
											setWebcamImgForm={setWebcamImgForm}
										/>
									)}
								</td>
							</tr>
						</tbody>
					</Table>
				)}
			</CardBody>
			{contentLoading ? null : (
				<CardFooter style={{ textAlign: 'center' }}>
					<p style={{ color: 'red' }}>{errors.employee ? errors.employee : null}</p>
					<p style={{ color: 'red' }}>{errors.custom ? errors.custom : null}</p>
					<Button
						size="md"
						style={{ fontSize: '1.1rem' }}
						color="success"
						onClick={handleSubmit}
						disabled={submitLoading}
					>
						ສົ່ງຂໍ້ມູນ
					</Button>
				</CardFooter>
			)}
		</Card>
	);
};

export default ReturnLTForm;
