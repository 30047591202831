import React, { useEffect, useState } from 'react';

import {
	Card,
	CardBody,
	Input,
	Form,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	CardHeader,
	Button
} from 'reactstrap';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { department_lang } from '../../../../../i18n/lo-LA';

import strapi from '../../../../../strapi-sdk';

import useGlobal from '../../../../../store';

const DepartmentLeftFrom = props => {
	const [globalState, globalActions] = useGlobal();
	const [id, setId] = useState(props.location.match.params.id);
	const [department, setDepartment] = useState(null);
	const [name, setName] = useState(null);
	const [loading, setLoading] = useState(true);
	const [visible, setVisible] = useState(false);

	useEffect(
		() => {
			async function fetchData() {
				const res = await strapi.getEntry('departments', id);
				setDepartment(res);
				setName(res.name);
				setLoading(false);
				globalActions.updateSubDepartment(res.subdepartments);
			}
			fetchData();
		},
		[globalActions, id]
	);

	const handleSubmit = () => {
		strapi
			.updateEntry('departments', id, { name })
			.then(res => {
				setVisible(true);
				ToastsStore.success(`${department_lang.SUCCESS}`);
			})
			.catch(err => {
				setVisible(true);
				ToastsStore.error(`${department_lang.FAIL}`);
			})
			.finally(() => {
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			});
	};

	const onChange = data => {
		setName(data);
	};

	return (
		<React.Fragment>
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<Card>
				{loading ? (
					<CardHeader>Loading...</CardHeader>
				) : (
					<React.Fragment>
						<CardHeader tag="h3" style={{ textAlign: 'center' }}>
							{window.location.pathname.indexOf('/add') !== -1
								? department_lang.ADD_NEW
								: department_lang.EDIT}
						</CardHeader>
						<CardBody>
							<Form action="" className=" form" method="">
								<InputGroup size="lg">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>{/* <i className=" nc-icon nc-shop" /> */}</InputGroupText>
									</InputGroupAddon>
									<Input
										type="text"
										name="name"
										placeholder={department_lang.NAME}
										autoComplete="off"
										defaultValue={department.name}
										onChange={e => onChange(e.target.value)}
									/>
								</InputGroup>
								<Button
									className="default"
									style={{ fontSize: '1.1rem', width: '100%' }}
									onClick={handleSubmit}
									disabled={visible ? true : false}
								>
									{department_lang.SUBMIT}
								</Button>
							</Form>
						</CardBody>
					</React.Fragment>
				)}
			</Card>
		</React.Fragment>
	);
};

export default DepartmentLeftFrom;
