import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, Redirect } from 'react-router-dom';

// import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from '../components/Footer/Footer.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';
import PageNavbar from '../components/Navbars/PageNavbar';

import routes from '../routes';
import jwt_decode from 'jwt-decode';

var ps;

class PageLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'brown',
			activeColor: 'warning',
			sidebarMini: false,
			loggedIn: false
		};
	}

	loggedInCheck() {
		let authen = false;
		if (localStorage.getItem('jwt')) {
			if (jwt_decode(localStorage.getItem('jwt')).exp < new Date()) {
				authen = true;
			} else {
				authen = false;
			}
		}
		return authen;
	}

	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
			ps = new PerfectScrollbar(this.refs.mainPanel);
		}
		// this.loggedInCheck();
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
			document.documentElement.className += ' perfect-scrollbar-off';
			document.documentElement.classList.remove('perfect-scrollbar-on');
		}
	}
	componentDidUpdate(e) {
		if (e.history.action === 'PUSH') {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
		}
	}
	getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (prop.layout === '/page') {
				return this.loggedInCheck() ? (
					<Route path={prop.layout + prop.path} component={prop.component} key={key} exact={prop.exact} />
				) : (
					<Redirect from="/" to="/auth/login" />
				);
			} else {
				return null;
			}
		});
	};

	render() {
		return (
			<div className="wrapper">
				<Sidebar
					{...this.props}
					routes={routes}
					bgColor={this.state.backgroundColor}
					activeColor={this.state.activeColor}
				/>
				<div className="main-panel" ref="mainPanel">
					<PageNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<Switch>{this.getRoutes(routes)}</Switch>
					{// we don't want the Footer to be rendered on full screen maps page
					this.props.location.pathname.indexOf('full-screen-map') !== -1 ? null : <Footer fluid />}
				</div>
			</div>
		);
	}
}

export default PageLayout;
