import React, { useState, useEffect } from 'react';

import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Input } from 'reactstrap';

import { department_lang } from '../../../../../i18n/lo-LA';

import strapi from '../../../../../strapi-sdk';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import useGlobal from '../../../../../store';

const SubModal = props => {
	const [globalState, globalActions] = useGlobal();
	const [modal, setModal] = useState(false);
	const [id, setId] = useState(props.location);
	const [name, setName] = useState(null);
	const [visible, setVisible] = useState(false);
	const [sid, setSid] = useState(null);

	useEffect(
		() => {
			if (props.mode === 'edit') {
				setName(props.name);
				setSid(props.sid);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const toggle = () => {
		setModal(!modal);
	};

	const onChange = data => {
		setName(data);
	};

	const handleSubmit = () => {
		strapi
			.createEntry('subdepartments', { name, department: id })
			.then(res => {
				setVisible(true);
				ToastsStore.success(`${department_lang.SUCCESS}`);
				strapi.getEntry('departments', id).then(res => {
					toggle();
					globalActions.updateSubDepartment(res.subdepartments);
				});
			})
			.catch(err => {
				toggle();
				setVisible(true);
				ToastsStore.error(`${department_lang.FAIL}`);
			})
			.finally(() => {
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			});
	};

	const handleSubmitEdit = () => {
		strapi
			.updateEntry('subdepartments', sid, { name })
			.then(res => {
				setVisible(true);
				ToastsStore.success(`${department_lang.SUCCESS}`);
				strapi.getEntry('departments', res.department.id).then(res => {
					globalActions.updateSubDepartment(res.subdepartments);
				});
			})
			.catch(err => {
				setVisible(true);
				ToastsStore.error(`${department_lang.FAIL}`);
			})
			.finally(() => {
				setTimeout(() => {
					setVisible(false);
					toggle();
				}, 3500);
			});
	};

	return (
		<React.Fragment>
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			{props.mode === 'edit' ? (
				<Button className="btn" color="success" size="sm" onClick={toggle}>
					<i className="fa fa-edit" />
					{department_lang.EDIT}
				</Button>
			) : (
				<Button size="sm" style={{ marginTop: 0, fontSize: '1.2rem' }} onClick={toggle}>
					{department_lang.ADD_NEW}
				</Button>
			)}
			<Modal isOpen={modal} toggle={toggle}>
				<div className="modal-header justify-content-center">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggle}>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{department_lang.ADD + department_lang.SUB_DEPARTMENT}</h5>
				</div>
				<ModalBody>
					<Input
						type="text"
						name="subdepartment"
						id="subdepartment"
						style={{ fontSize: '1.2rem' }}
						defaultValue={props.name || ''}
						placeholder={department_lang.SUB_DEPARTMENT}
						onChange={e => onChange(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={props.mode === 'edit' ? handleSubmitEdit : handleSubmit}
						style={{ fontSize: '1.1rem' }}
					>
						{department_lang.SUBMIT}
					</Button>{' '}
					<Button color="secondary" onClick={toggle} style={{ fontSize: '1.1rem' }}>
						{department_lang.CLOSE_MODAL}
					</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

export default SubModal;
