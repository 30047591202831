import React from 'react';
import Webcam from 'react-webcam';
import strapi from '../strapi-sdk';

class WebcamCapture extends React.Component {
	setRef = webcam => {
		this.webcam = webcam;
	};

	dataURItoBlob = (dataURI, type) => {
		// convert base64 to raw binary data held in a string
		var byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI
			.split(',')[0]
			.split(':')[1]
			.split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		var bb = new Blob([ab], { type: type });
		return bb;
	};

	capture = () => {
		const imageSrc = this.webcam.getScreenshot();
		const img = this.dataURItoBlob(imageSrc, 'image/jpg');
		// const buf = new Buffer(imageSrc, 'base64');
		// const form = new FormData();
		// let name = new Date().getTime();
		// form.append('files', img, name + '.jpg');
		// form.append('refId', res.id);
		// form.append('ref', 'employee');
		// form.append('source', 'content-manager');
		// form.append('field', 'image');
		// strapi.upload(form).then(res => {
		// 	console.log(res);
		// });
		this.props.setWebcamImgForm(img);
		this.props.setWebcamImg(imageSrc);
	};

	render() {
		const videoConstraints = {
			width: 1280,
			height: 720,
			facingMode: 'user'
		};

		return (
			<div>
				<Webcam
					audio={false}
					height={350}
					ref={this.setRef}
					screenshotFormat="image/jpeg"
					width={350}
					videoConstraints={videoConstraints}
				/>
				<button onClick={this.capture}>Capture photo</button>
			</div>
		);
	}
}

export default WebcamCapture;
