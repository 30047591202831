import React, { useState, useEffect } from 'react';

import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Button,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	Label,
	FormGroup,
	Table,
	Spinner
} from 'reactstrap';

import { Link } from 'react-router-dom';

import ReactDatetime from 'react-datetime';
import moment from 'moment';

import Select from 'react-select';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { import_lang, import_form_lang } from '../../../i18n/lo-LA';
import strapi, { URL } from '../../../strapi-sdk';
import history from '../../../history';

import _ from 'lodash';

let mytimer;

const ImportForm = props => {
	const [search, setSearch] = useState(null);
	const [visible, setVisible] = useState(false);
	const [searchOptions, setSearchOptions] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
	const [loading, setLoading] = useState(true);
	const [suppliers, setSuppliers] = useState(null);
	const [orderArray, setOrderArray] = useState([]);
	const [total, setTotal] = useState({
		all: 0,
		shipping: 0
	});

	const [data, setData] = useState({
		code: '',
		supplier: '',
		status: '',
		description: '',
		shipping_cost: 0,
		overall_price: 0,
		approve: false,
		bill_date: moment()
	});

	// ERRORS
	const [errors, setErrors] = useState({});

	// FIX 13/7/2019 ADDED MATERIAL TYLE SELECT
	const [materialTypes, setMaterialTypes] = useState([]);
	const [selectedMaterialType, setSelectedMaterialType] = useState(null);
	const [loadingTypes, setLoadingTypes] = useState(true);

	useEffect(() => {
		function initData() {
			return strapi.getEntries('suppliers');
		}
		function InitMaterialType() {
			return strapi.getEntries('materialtypes');
		}
		Promise.all([initData(), InitMaterialType()])
			.then(async resolves => {
				await assignSupplersToSelect(resolves[0]);
				await assignMaterialTypesToSelect(resolves[1]);
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setLoadingTypes(false);
				setLoading(false);
			});
	}, []);

	const searchOnInputChange = e => {
		if (e === '') return;
		clearInterval(mytimer);
		setSearchLoading(true);
		mytimer = setTimeout(() => {
			strapi
				.request('GET', `${URL}materials?materialtype=${selectedMaterialType.id}&name_contains=${e}`)
				.then(res => {
					assignMaterialToSelect(res);
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					setSearchLoading(false);
				});
		}, 1000);
	};

	// UPDATED VALIDATE IF LONG TERM MATERIAL IS ALREADY EXIST IN WAREHOUSE OR NOT
	const checkMaterial = async id => {
		try {
			const res = await strapi.request('GET', `${URL}materialwarehouses?material=${id}`);
			if (res.length === 0) return true;
			else return false;
		} catch (err) {
			console.log(err);
		}
	};

	const searchOnChange = async e => {
		setSearchLoading(false);
		setSearchOptions([]);
		let orders = orderArray;
		if (selectedMaterialType.id === 3) {
			const chkMaterial = await checkMaterial(e.id);
			if (chkMaterial) {
				orders.push({
					materialType: selectedMaterialType.id,
					quantity: selectedMaterialType.id === 3 ? 1 : 0,
					price: 0,
					material: e.id,
					name: e.label
				});
				setOrderArray(orders);
				setSearchLoading(true);
			} else {
				setVisible(true);
				ToastsStore.error(`${e.label + ' ' + import_form_lang.ERROR_ALREADY_EXISTED}`);
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			}
		} else {
			orders.push({
				materialType: selectedMaterialType.id,
				quantity: selectedMaterialType.id === 3 ? 1 : 0,
				price: 0,
				material: e.id,
				name: e.label
			});
			setOrderArray(orders);
		}
	};

	// Material OnChage
	const materialTypeOnChange = e => {
		setSelectedMaterialType(e);
	};

	const onSelectedItem = idx => evt => {
		let newOrders = orderArray;
		let order = newOrders[idx];
		let newOrder = {
			...order,
			[evt.target.name]: evt.target.value
		};
		newOrders[idx] = newOrder;
		setOrderArray(newOrders);
		calculateTotal();
	};

	const handleRemove = idx => {
		let calc = orderArray[idx].price * orderArray[idx].quantity;
		let all = total.all - calc;
		setTotal({ ...total, all });
		setOrderArray(orderArray.filter((s, didx) => idx !== didx));
	};

	const validateNumZero = () => {
		orderArray.map(item => {
			if (item.quantity <= 0 || item.price <= 0) return false;
		});
		return true;
	};

	const validateOrder = () => {
		if (data.supplier === '' || data.overall_price === 0 || data.status === '' || data.supplier === '')
			return false;
		return true;
	};

	const handleSubmit = () => {
		let finalData = data;
		finalData.overall_price = +total.all + +total.shipping;
		finalData.shipping_cost = +finalData.shipping_cost;
		let newArray = JSON.parse(JSON.stringify(orderArray));
		newArray.map(function(item) {
			delete item.name;
			delete item.materialType;
			return item;
		});
		const newData = {
			data: {
				order: data,
				items: newArray
			}
		};
		setVisible(true);

		let error = {};

		if (data.supplier === '') error.supplier = import_form_lang.ERROR_SUPPLIER;
		if (data.status === '') error.status = import_form_lang.ERROR_STATUS;
		if (total.all === 0) error.overall = import_form_lang.ERROR_OVERALL;
		if (data.bill_date === '' || data.bill_date === 'Invalid date')
			error.bill_date = import_form_lang.ERROR_BILL_DATE;
		if (!_.isEmpty(error)) {
			if (validateNumZero() === false) {
				ToastsStore.error(import_form_lang.VALIDATE_ZERO);
			}
			if (validateOrder() === false) {
				ToastsStore.error(import_form_lang.FAILED);
			}
			setVisible(false);
			setErrors(error);
		} else {
			strapi
				.request('POST', `${URL}materialorders/create`, newData)
				.then(res => {
					ToastsStore.success(import_form_lang.SUCCESS);
				})
				.catch(err => {
					ToastsStore.error(import_form_lang.FAILED);
				})
				.finally(() => {
					setTimeout(() => {
						setVisible(false);
						history.push('/page/import');
					}, 1500);
				});
		}
	};

	const calculateTotal = () => {
		let all = 0;
		orderArray.map(item => {
			all += item.price * item.quantity;
		});
		setTotal({ ...total, all });
	};

	async function assignMaterialToSelect(resSearch) {
		let local_materials = [];
		resSearch.map(x => {
			if (x.materialtype.id === 3) {
				local_materials = [
					...local_materials,
					{
						value: x.name + ' (SN# ' + x.serial_number + ')',
						label: x.name + ' (SN# ' + x.serial_number + ')',
						id: x.id
					}
				];
			} else {
				local_materials = [
					...local_materials,
					{
						value: x.name,
						label: x.name,
						id: x.id
					}
				];
			}
		});
		setSearchOptions(local_materials);
	}

	async function assignSupplersToSelect(resSuppliers) {
		let local_suppliers = [];
		resSuppliers.map(x => {
			local_suppliers = [...local_suppliers, { value: x.name, label: x.name, id: x.id }];
		});
		setSuppliers(local_suppliers);
	}
	// MATERIAL TYPE
	async function assignMaterialTypesToSelect(resMaterialTypes) {
		let local_material_types = [];
		resMaterialTypes.map(x => {
			local_material_types = [...local_material_types, { value: x.name, label: x.name, id: x.id }];
		});
		setMaterialTypes(local_material_types);
	}

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	const loadingMessage = () => <Spinner color="primary" />;

	return (
		<div className="content">
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader
							tag="h3"
							style={{
								borderBottom: 'dashed',
								borderWidth: '2px',
								borderColor: '#ccc',
								padding: 5,
								textAlign: 'center'
							}}
							className="d-flex"
						>
							<div className="">
								<Link to="/page/import/" className="">
									<Button color="danger" style={{ fontSize: '1.1rem' }}>
										<i className="fas fa-arrow-circle-left" /> {import_form_lang.BACK}
									</Button>
								</Link>
							</div>
							<div className="w-75 align-self-center">{import_lang.CREATE_BILL}</div>
						</CardHeader>
						<CardBody>
							<Row>
								<Col
									sm={12}
									md={5}
									style={{ borderRight: 'dashed', borderWidth: '2px', borderColor: '#ccc' }}
								>
									{loading ? (
										<Spinner color="primary" />
									) : (
										<React.Fragment>
											<Row>
												<Col sm={12}>
													<FormGroup className="has-label">
														<Label style={{ fontSize: '1.1rem' }}>
															{import_form_lang.SUPPLIER}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<Select
															required
															className="react-select primary SelectInModalFont"
															classNamePrefix="react-select"
															name="supplier"
															// value={singleSelect}
															onChange={value => {
																setData({ ...data, supplier: value.id });
															}}
															options={suppliers}
															placeholder={import_form_lang.CHOOSE_SUPPLIER}
														/>
													</FormGroup>
													{errors.supplier ? (
														<p style={{ fontSize: '1rem', color: 'red' }}>
															{import_form_lang.ERROR_SUPPLIER}
														</p>
													) : null}
												</Col>
											</Row>
											<Row>
												<Col sm={6}>
													<FormGroup className="has-label">
														<Label style={{ fontSize: '1.1rem' }}>
															{import_form_lang.STATUS}
															<span style={{ color: 'red' }}>*</span>
														</Label>
														<Select
															className="react-select primary SelectInModalFont"
															classNamePrefix="react-select"
															name="status"
															// value={singleSelect}
															onChange={value => {
																setData({ ...data, status: value.value });
															}}
															// options={branches}
															options={[
																{
																	value: '',
																	label: import_form_lang.CHOOSE_STATUS,
																	isDisabled: true
																},
																{ value: 'waiting', label: import_form_lang.WAITING },
																{ value: 'unpaid', label: import_form_lang.UNPAID },
																{ value: 'paid', label: import_form_lang.PAID }
															]}
															placeholder={import_form_lang.CHOOSE}
														/>
													</FormGroup>
													{errors.status ? (
														<p style={{ fontSize: '1rem', color: 'red' }}>
															{import_form_lang.ERROR_STATUS}
														</p>
													) : null}
												</Col>
												<Col sm={6}>
													<FormGroup className="has-label">
														<Label style={{ fontSize: '1.1rem' }}>
															{import_form_lang.SHIPPING_COST}
														</Label>
														<InputGroup size="md">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="nc-icon nc-delivery-fast" />
																</InputGroupText>
															</InputGroupAddon>
															<Input
																required
																name="shipping_cost"
																placeholder="0"
																type="text"
																defaultValue="0"
																onChange={e => {
																	onChange(e);
																	setTotal({ ...total, shipping: e.target.value });
																}}
															/>
														</InputGroup>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col sm={12}>
													<FormGroup className="has-label">
														<Label style={{ fontSize: '1.1rem' }}>
															{import_form_lang.DESCRIPTION}
														</Label>
														<Input type="textarea" name="description" onChange={onChange} />
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col sm={12}>
													<Label style={{ fontSize: '1.1rem' }}>
														{import_form_lang.BILL_DATE}
														<span style={{ color: 'red' }}>*</span>
													</Label>
													<ReactDatetime
														// dateFormat="YYYY-MM-DD HH:mm:ss"
														defaultValue={moment().format('YYYY-MM-DD HH:mm:ss') || ''}
														inputProps={{
															className: 'form-control',
															placeholder: import_form_lang.CHOOSE_BILL_DATE
														}}
														onChange={e => {
															setData({
																...data,
																bill_date: moment(e).format('YYYY-MM-DD HH:mm:ss')
															});
														}}
													/>
													{errors.bill_date ? (
														<p style={{ fontSize: '1rem', color: 'red' }}>
															{import_form_lang.ERROR_BILL_DATE}
														</p>
													) : null}
												</Col>
											</Row>
										</React.Fragment>
									)}
								</Col>

								{/* START RIGHT SIDE CONTENT */}
								<Col sm={12} md={7}>
									<Row>
										{/* Material Type Select */}
										<Col sm={12} md={4}>
											<FormGroup className="has-label">
												<Label style={{ fontSize: '1.1rem' }}>
													{import_form_lang.CHOOSE_MATERIAL_TYPE}
												</Label>
												<Select
													required
													className="react-select primary SelectInModalFont"
													classNamePrefix="react-select"
													name="type"
													// onInputChange={searchOnInputChange}
													onChange={materialTypeOnChange}
													// value={search}
													options={materialTypes}
													isLoading={loadingTypes}
													// loadingMessage={loadingMessage}
													// style={{ fontSize: '1.1rem' }}
													placeholder={import_form_lang.CHOOSE}
												/>
											</FormGroup>
										</Col>
										{/* END Material Type Select */}
										<Col sm={12} md={8}>
											<FormGroup className="has-label">
												<Label style={{ fontSize: '1.1rem' }}>
													{import_form_lang.SEARCH_ITEM}
												</Label>
												<Select
													required
													className="react-select primary SelectInModalFont"
													classNamePrefix="react-select"
													name="search"
													onInputChange={searchOnInputChange}
													onChange={searchOnChange}
													value={search}
													options={searchOptions}
													isLoading={searchLoading}
													loadingMessage={loadingMessage}
													style={{ fontSize: '1.1rem' }}
													placeholder={import_form_lang.CHOOSE}
													isDisabled={_.isEmpty(selectedMaterialType) ? true : false}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col sm={12}>
											<Label style={{ fontSize: '1.1rem' }}>
												{import_form_lang.LIST_SELECT_ITEM}
												<span style={{ color: 'red' }}>*</span>
											</Label>
										</Col>
									</Row>
									<Row>
										<Col sm={12}>
											<Table striped>
												<thead>
													<tr>
														<th>#</th>
														<th>{import_form_lang.MATERIAL}</th>
														<th>{import_form_lang.PRICE}</th>
														<th>{import_form_lang.QUANTITY}</th>
													</tr>
												</thead>
												<tbody>
													{orderArray.map((item, idx) => (
														<tr key={idx}>
															<th scope="row">{idx + 1}</th>
															<td>{item.name}</td>
															<td style={{ width: '30%' }}>
																<FormGroup
																	className={
																		orderArray[idx].price <= 0 ? 'has-danger' : ''
																	}
																>
																	<Input
																		type="number"
																		// defaultValue={item.price}
																		name="price"
																		onChange={onSelectedItem(idx)}
																		placeholder="0"
																		autoComplete="off"
																	/>
																</FormGroup>
															</td>
															<td style={{ width: '12%' }}>
																<FormGroup
																	className={
																		orderArray[idx].quantity <= 0
																			? 'has-danger'
																			: ''
																	}
																>
																	<Input
																		type="text"
																		defaultValue={item.quantity}
																		name="quantity"
																		placeholder="0"
																		onChange={onSelectedItem(idx)}
																		disabled={
																			orderArray[idx].materialType === 3
																				? true
																				: false
																		}
																		autoComplete="off"
																	/>
																</FormGroup>
															</td>
															<td style={{ textAlign: 'right' }}>
																<Button
																	color="danger"
																	size="sm"
																	onClick={() => handleRemove(idx)}
																>
																	x
																</Button>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</Col>
									</Row>
									<Row>
										<Col sm={12}>
											<Table>
												<tbody>
													<tr style={{ textAlign: 'right' }}>
														<td style={{ width: '50%' }}>
															{import_form_lang.ALL_ITEMS_PRICE}
														</td>
														<td>
															{new Intl.NumberFormat('ja-JP').format(total.all) +
																' ' +
																import_form_lang.KIP}
														</td>
													</tr>
													<tr style={{ textAlign: 'right' }}>
														<td>{import_form_lang.SHIPPING_COST}</td>
														<td>
															{new Intl.NumberFormat('ja-JP').format(total.shipping) +
																' ' +
																import_form_lang.KIP}
														</td>
													</tr>
													<tr style={{ textAlign: 'right' }}>
														<td style={{ fontWeight: 'bold' }}>{import_form_lang.TOTAL}</td>
														<td style={{ fontWeight: 'bold' }}>
															{new Intl.NumberFormat('ja-JP').format(
																parseFloat(total.all) + parseFloat(total.shipping)
															) +
																' ' +
																import_form_lang.KIP}
														</td>
													</tr>
												</tbody>
											</Table>
											{errors.overall ? (
												<p style={{ fontSize: '1rem', color: 'red', textAlign: 'right' }}>
													{import_form_lang.ERROR_OVERALL}
												</p>
											) : null}
										</Col>
									</Row>
								</Col>
							</Row>
						</CardBody>
						<CardFooter style={{ borderTop: 'dashed', borderWidth: '2px', borderColor: '#ccc' }}>
							<div className="pull-right">
								<Button style={{ fontSize: '1.1rem' }} onClick={handleSubmit} disabled={visible}>
									{import_form_lang.SUBMIT}
								</Button>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ImportForm;
