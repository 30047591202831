import React, { useState, useEffect } from 'react';

import {
	Card,
	CardHeader,
	CardBody,
	Table,
	FormGroup,
	Input,
	Label,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Pagination,
	PaginationItem,
	PaginationLink,
	UncontrolledDropdown
} from 'reactstrap';

import { withdrawal_lang, branch_lang } from '../../../i18n/lo-LA';
import strapi from '../../../strapi-sdk';
import { Link } from 'react-router-dom';

import useGlobal from '../../../store';
import _ from 'lodash';
import moment from 'moment';

const WithdrawalList = props => {
	const [globalState, globalActions] = useGlobal();
	const [warehouses, setWarehouses] = useState(null);
	const [racks, setRacks] = useState(null);
	const [units, setUnits] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const init = () => {
			var w = strapi.getEntries('warehouses');
			var r = strapi.getEntries('racks');
			var u = strapi.getEntries('units');
			Promise.all([w, r, u]).then(resolves => {
				setWarehouses(resolves[0]);
				setRacks(resolves[1]);
				setUnits(resolves[2]);
				setLoading(false);
			});
		};
		init();
	}, []);

	const getWarehouse = warehouse => {
		return warehouses.filter(item => item.id === warehouse)[0].name;
	};

	const getRack = rack => {
		return ' (' + racks.filter(item => item.id === rack)[0].name + ')';
	};

	const getUnits = unit => {
		return units.filter(item => item.id === unit)[0].name;
	};

	return (
		<Card>
			<CardHeader>
				<h3 style={{ marginBottom: 'unset' }}>{withdrawal_lang.WITHDRAWAL_LIST}</h3>
			</CardHeader>
			<CardBody>
				{loading ? null : (
					<Table striped>
						<thead>
							<tr>
								<th style={{ textAlign: 'center' }}>{withdrawal_lang.ROW_INDEX}</th>
								<th style={{ textAlign: 'center' }}>{withdrawal_lang.WITHDRAWAL_ID}</th>
								<th>{withdrawal_lang.WITH_DRAW_FROM_DATE}</th>
								<th>{withdrawal_lang.RETURN_DATE}</th>
								<th>{withdrawal_lang.MATERIAL_NAME}</th>
								<th>{withdrawal_lang.WAREHOUSE}</th>
								<th>{withdrawal_lang.WITHDRAW_QUANTITY}</th>
								<th>{withdrawal_lang.RETURN_QUANTITY}</th>
								<th>{withdrawal_lang.EMPLOYEE}</th>
								<th>{withdrawal_lang.CLOSED}</th>
								<th>{branch_lang.ACTION}</th>
							</tr>
						</thead>
						<tbody>
							{_.isEmpty(props.withdrawals)
								? null
								: props.withdrawals.map((item, idx) => {
										return (
											<tr key={item.id}>
												<td style={{ textAlign: 'center' }}>
													{props.page === 1 ? idx + 1 : (props.page - 1) * 10 + (idx + 1)}
												</td>
												<td style={{ textAlign: 'center' }}>{item.id}</td>
												<td>{moment(item.withdraw_date).format('DD/MM/YYYY')}</td>
												<td>
													{item.return_date
														? moment(item.return_date).format('DD/MM/YYYY')
														: null}
												</td>
												<td>
													{item.material.name}
													{item.material_type === '3'
														? ` (SN#${item.material.serial_number})`
														: null}
												</td>
												<td>
													{getWarehouse(item.materialwarehouse.warehouse) +
														getRack(item.materialwarehouse.rack)}
												</td>
												<td>{item.withdraw_quantity + ' ' + getUnits(item.material.unit)}</td>
												<td>
													{item.closed === false ? 'ລໍຖ້າສົ່ງຄືນ' : null}
													{item.return_quantity
														? item.return_quantity + ' ' + getUnits(item.material.unit)
														: null}
												</td>
												<td>{item.employee.fullname}</td>
												<td>
													<FormGroup check>
														<Label check>
															<Input
																type="checkbox"
																defaultChecked={item.closed}
																disabled={true}
															/>{' '}
															<span className="form-check-sign">
																<span className="check" />
															</span>
														</Label>
													</FormGroup>
												</td>
												<td style={{ minWidth: '150px' }}>
													<UncontrolledDropdown group direction="up">
														<DropdownToggle caret>ເລືອກຕັ້ງຄ່າ</DropdownToggle>
														<DropdownMenu
															style={{
																border: 'dashed',
																borderWidth: '2px',
																borderColor: '#ccc',
																fontSize: '1rem'
															}}
														>
															{item.closed ? (
																<Link
																	to={`/page/withdrawal-tools/result/${item.id}`}
																	style={{ textDecoration: 'none', fontSize: '1rem' }}
																>
																	<DropdownItem
																		style={{
																			textDecoration: 'none',
																			fontSize: '1rem',
																			color: '#000'
																		}}
																	>
																		ເບິ່ງສະຫຼຸບ
																	</DropdownItem>
																</Link>
															) : (
																<Link
																	style={{
																		textDecoration: 'none',
																		fontSize: '1rem',
																		color: '#000'
																	}}
																	to={`/page/withdrawal-tools/return/${item.id}`}
																>
																	<DropdownItem
																		style={{
																			textDecoration: 'none',
																			fontSize: '1rem',
																			color: '#000'
																		}}
																	>
																		ສົ່ງອຸປະກອນຄືນ
																	</DropdownItem>
																</Link>
															)}
														</DropdownMenu>
													</UncontrolledDropdown>
												</td>
											</tr>
										);
									})}
						</tbody>
					</Table>
				)}
				<Pagination listClassName="justify-content-center">
					{props.page - 1 === 0 ? null : (
						<PaginationItem>
							<PaginationLink tag="span" onClick={() => props.updatePage(props.page - 1)}>
								{withdrawal_lang.PREVIOUS}
							</PaginationLink>
						</PaginationItem>
					)}
					{_.range(1, props.pageCount + 1).map(p => (
						<PaginationItem key={p} active={props.page === p ? true : false}>
							<PaginationLink tag="span" onClick={() => props.updatePage(p)}>
								{p}
							</PaginationLink>
						</PaginationItem>
					))}

					{props.page === props.pageCount || props.pageCount === 0 ? null : (
						<PaginationItem>
							<PaginationLink tag="span" onClick={() => props.updatePage(props.page + 1)}>
								{withdrawal_lang.NEXT}
							</PaginationLink>
						</PaginationItem>
					)}
				</Pagination>
			</CardBody>
		</Card>
	);
};

export default WithdrawalList;
