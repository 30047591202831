import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import history from './history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';

import PageLayout from './layouts/PageLayout';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss';

import routes from './routes';
import AuthLayout from './layouts/AuthLayout';
import strapi from './strapi-sdk';

const cache = new InMemoryCache();
const link = new HttpLink({
	uri: 'https://assetapix.laoitdev.com/graphql'
});

const client = new ApolloClient({
	cache,
	link
});

ReactDOM.render(
	<ApolloProvider client={client}>
		<Router history={history}>
			<Switch>
				{routes.map(route => (
					<Route key={route} path={'/page' + route.path} render={props => <PageLayout {...props} />} />
				))}
				<Route path="/page" render={props => <PageLayout {...props} />} />
				<Route path="/auth" render={props => <AuthLayout {...props} />} />
				{/* <Route path="/admin" render={props => <AdminLayout {...props} />} /> */}
				<Redirect from="/" to="/page/dashboard" />
			</Switch>
		</Router>
	</ApolloProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
