import React, { useState, useEffect } from 'react';

import {
	Button,
	Modal,
	Input,
	Card,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Form,
	CardBody,
	Row,
	Col
} from 'reactstrap';
import '../../../assets/scss/selectModal.css';

import Select from 'react-select';

import strapi from '../../../strapi-sdk';

import useGlobal from '../../../store';

import _ from 'lodash';

import { rack_lang, modal_lang } from '../../../i18n/lo-LA';

import { validateName } from '../../../utils/validate';

const RackModal = props => {
	const [globalState, globalActions] = useGlobal();
	const [data, setData] = useState({ name: '', warehouse: null });
	const [contentID, setContentID] = useState(null);
	const [err, setErr] = useState([]);
	const [warehouses, setWarehouses] = useState([]);
	const [selectedWarehouse, setSelectedWarehouse] = useState(null);
	const [loading, setLoading] = useState(true);

	// function useFetch() {
	async function fetchWarehouses() {
		const resWarehouse = await strapi.getEntries('warehouses');
		getWarehouses(resWarehouse);
	}

	useEffect(
		() => {
			fetchWarehouses();
			if (props.contentID !== null) {
				setContentID(props.contentID);
				async function fetchDatafromID(contentID) {
					var type = typeof contentID;
					if (type === 'number') {
						const res = await strapi.getEntry('racks', contentID);
						setData({ name: res.name, tel: res.tel });
						setSelectedWarehouse({ label: res.warehouse.name, value: res.warehouse.id });
					}
				}
				fetchDatafromID(props.contentID);
			} else {
				setData({ name: '', warehouse: null });
				setSelectedWarehouse(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.contentID]
	);

	async function getWarehouses(wares) {
		let warehs = [];
		wares.map(w => {
			warehs = [...warehs, { value: w.name, label: w.name, id: w.id }];
		});
		setWarehouses(warehs);
		setLoading(false);
	}

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit(props) {
		let errors = {};
		if (!validateName(data.name)) {
			errors.rackName = modal_lang.VALIDATE_NAME;
		}

		if (!_.isEmpty(errors)) {
			setErr(errors);
			return;
		}

		try {
			if (props.contentID === null) {
				await strapi.createEntry('racks', data);
			} else {
				await strapi.updateEntry('racks', contentID, data);
			}
			globalActions.updateRacksList(await strapi.getEntries('racks'));
			setData({ name: '' });
			setContentID(null);
			props.hideModal();
			props.notify('tr', 2, modal_lang.SUCCESS);
		} catch {
			props.notify('tr', 3, modal_lang.FAIL);
		}
	}

	return (
		<Modal
			className="modal-login"
			modalClassName=" modal-primary"
			isOpen={props.ModalRack}
			toggle={props.showModal}
		>
			<Card className=" card-login card-plain">
				<div className=" modal-header justify-content-center">
					<button
						aria-label="Close"
						className=" close"
						data-dismiss="modal"
						type="button"
						onClick={props.showModal}
					>
						<span aria-hidden={true}>×</span>
					</button>
					<div className=" header header-primary text-center">
						<div className=" logo-container">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{props.title + rack_lang.CURRENT}
							</h3>
						</div>
					</div>
				</div>
				<div className=" modal-body">
					<Form action="" className=" form" method="">
						<CardBody>
							<Row>
								<Col sm="12">
									<InputGroup size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>{/* <i className=" nc-icon nc-shop" /> */}</InputGroupText>
										</InputGroupAddon>
										<Input
											name="name"
											placeholder={rack_lang.NAME}
											type="text"
											style={{ borderColor: err.rackName && 'red' }}
											value={data.name}
											onChange={onChange}
										/>
									</InputGroup>
									<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
										{err.rackName && err.rackName}
									</p>
								</Col>
								<Col sm="12">
									{loading ? (
										<p>Loading...</p>
									) : (
										<Select
											className="react-select primary SelectInModalFont"
											classNamePrefix="react-select"
											name="category"
											value={selectedWarehouse}
											onChange={value => {
												setData({ ...data, warehouse: value.id });
												setSelectedWarehouse(value);
											}}
											options={warehouses}
											placeholder={rack_lang.CHOOSE_WAREHOUSE}
										/>
									)}
									<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
										{err.warehouseName && err.warehouseName}
									</p>
								</Col>
							</Row>
						</CardBody>
					</Form>
				</div>
				<div className=" modal-footer text-right">
					<Button
						block
						className=" btn-default "
						style={{ fontSize: '1.1rem' }}
						color="default"
						onClick={() => handleSubmit(props)}
					>
						{modal_lang.SUBMIT}
					</Button>
				</div>
			</Card>
		</Modal>
	);
};

export default RackModal;
