import React, { useState, useEffect } from 'react';

import { Card, CardHeader, CardBody, CardFooter, Label, Spinner, Table, Input, FormGroup, Button } from 'reactstrap';

import Select from 'react-select';
import strapi, { URL } from '../../../strapi-sdk';

import { withdrawal_lang } from '../../../i18n/lo-LA';

import ImageUpload from '../../../components/CustomUpload/WithdrawalUpload';

import _ from 'lodash';
import WebcamCapture from '../../../utils/Webcam';

import moment from 'moment';

import ReactDatetime from 'react-datetime';

import useGlobal from '../../../store';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import history from '../../../history';

const LongTermMaterial = props => {
	const loadingMessage = () => <Spinner color="primary" />;

	const [materialOptions, setMaterialOptions] = useState([]);
	const [selectedMaterial, setSelectedMaterial] = useState(null);
	const [loading, setLoading] = useState(false);
	const [material, setMaterial] = useState(null);
	const [materialwarehouse, setMaterialwarehouse] = useState(null);
	const [contentLoading, setContentLoading] = useState(true);

	const [data, setData] = useState({
		withdraw_date: moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
	});
	const [closed, setClosed] = useState(true);
	const [beforeImages, setBeforeImages] = useState(null);

	const [errors, setErrors] = useState({});

	//images
	const [imageOption, setImageOption] = useState('browse');

	//webcam
	const [webcamImg, setWebcamImg] = useState(null);
	const [webcamImgForm, setWebcamImgForm] = useState(null);

	//global state
	const [globalState, globalActions] = useGlobal();

	const [visible, setVisible] = useState(false);

	//loading
	const [submitLoading, setSubmitLoading] = useState(false);

	let mytimer;

	useEffect(() => {
		const fetchData = async () => {
			const res = await strapi.request('GET', `${URL}materials?materialtype=3`);
			const newArray = res.filter(item => item.materialwarehouses.length !== 0);
		};
		fetchData();
	}, []);

	// SEARCH MATERIAL TYPE 3
	const searchOnInputChange = e => {
		if (e === '') return;
		clearInterval(mytimer);
		setLoading(true);
		mytimer = setTimeout(() => {
			strapi
				.request('GET', `${URL}materials?materialtype=3&name_contains=${e}`)
				.then(res => {
					assignToSelect(res);
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}, 1000);
	};

	const assignToSelect = resMt => {
		let local = [];
		resMt.map(x => {
			local = [
				...local,
				{
					value: x.name + ' (SN# ' + x.serial_number + ')',
					label: x.name + ' (SN# ' + x.serial_number + ')',
					id: x.id,
					status: x.status
				}
			];
		});
		setMaterialOptions(local);
	};

	const OnChange = async e => {
		try {
			setContentLoading(true);
			const res = await strapi.request('GET', `${URL}materialwarehouses?material=${e.id}`);
			const resM = await strapi.getEntry('materials', e.id);
			setData({ ...data, material: e.id, withdraw_status: e.status, materialwarehouse: res[0].id });
			setMaterial(resM);
			setMaterialwarehouse(res);
			setContentLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const onChangeWDQuantity = e => {
		const quantity = e.target.value;
		let error = {};
		if (quantity > materialwarehouse[0].quantity) {
			error.wdquantity = 'ຈຳນວນໃນສາງມີບໍ່ພໍ!';
		}
		if (quantity <= 0) error.WDQuantityLessThanZero = 'ຈຳນວນຕ້ອງບໍ່ໜ້ອຍຫຼືເທົ່າກັບ 0';
		setData({ ...data, withdraw_quantity: quantity });
		setErrors(error);
	};

	const handleSubmit = async () => {
		setSubmitLoading(true);
		//validation
		let error = {};
		if (_.isEmpty(props.getEmployee())) error.employee = 'ກະລຸນາເລືອກພະນັກງານ';
		if (webcamImg === null && beforeImages === null) error.image = 'ກະລຸນາເລືອກຮູບ ຫຼື ຖ່າຍຮູບຂອງອຸປະກອນກ່ອນເບີກ';
		if (data.withdraw_quantity === null || data.withdraw_quantity === undefined)
			error.quantity = 'ກະລຸນາລະບຸຈຳນວນທີ່ຕ້ອງການເບີກ';
		if (data.withdraw_quantity > materialwarehouse[0].quantity) {
			error.wdquantity = 'ຈຳນວນໃນສາງມີບໍ່ພໍ!';
		}

		if (!_.isEmpty(error)) {
			setSubmitLoading(false);
			setErrors(error);
		} else {
			try {
				const newData = { ...data, employee: props.getEmployee().id, closed: !closed, material_type: 3 };
				const resCreate = await strapi.createEntry('withdrawals', newData);
				const q = {
					quantity: parseFloat(materialwarehouse[0].quantity) - parseFloat(newData.withdraw_quantity)
				};
				const form = new FormData();
				let name = new Date().getTime();
				if (imageOption === 'browse') {
					form.append('files', beforeImages, name + '.jpg');
				} else {
					form.append('files', webcamImgForm, name + '.jpg');
				}
				form.append('refId', resCreate.id);
				form.append('ref', 'withdrawal');
				form.append('source', 'content-manager');
				form.append('field', 'before_images');
				const resUpload = await strapi.upload(form);
				const resMWarehouse = await strapi.updateEntry('materialwarehouses', materialwarehouse[0].id, q);
				let logs = {
					user: localStorage.getItem('id'),
					from_value: materialwarehouse[0].quantity,
					to_value: materialwarehouse[0].quantity - newData.withdraw_quantity,
					action_type: 'WITHDRAW',
					material: material.name,
					warehouse: resMWarehouse.warehouse.name,
					rack: resMWarehouse.rack.name,
					materialwarehouse: resMWarehouse.id,
					unit: material.unit.name,
					bill_id: resCreate.id
				};
				const materialLogs = await strapi.createEntry('materiallogs', logs);
				setVisible(true);
				ToastsStore.success(withdrawal_lang.SUCCESS);
				setTimeout(() => {
					setSubmitLoading(false);
					setVisible(false);
					history.push('/page/withdrawal-tools/');
				}, 1500);
			} catch (err) {
				setVisible(true);
				setSubmitLoading(false);
				ToastsStore.error('ເກີດຂໍ້ຜິດພາດກະລຸນາລອງໃໝ່ອີກຄັ້ງ');
				setTimeout(() => {
					setVisible(false);
				}, 1500);
			}
		}
	};

	return (
		<Card>
			{visible ? <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} /> : null}
			<CardHeader>ວັດສະດຸມີປະກັນ</CardHeader>
			<CardBody>
				<Label style={{ fontSize: '1.1rem' }}>
					{withdrawal_lang.LONG_TERM_MATERIAL}
					<span style={{ color: 'red' }}>*</span>
				</Label>
				<Select
					className="react-select primary SelectInModalFont"
					classNamePrefix="react-select"
					name="material"
					onInputChange={searchOnInputChange}
					onChange={OnChange}
					// value={search}
					options={materialOptions}
					isLoading={loading}
					loadingMessage={loadingMessage}
					// style={{ fontSize: '1.1rem' }}
					// placeholder={withdrawal_lang.EMPLOYEE_NAME}
				/>
				{contentLoading ? null : (
					<Table bordered style={{ marginTop: '10px' }}>
						<tbody>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ width: '50%', textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WAREHOUSE}
								</td>
								<td style={{ width: '50%', textAlign: 'center' }}>
									{materialwarehouse[0].warehouse.name}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>{withdrawal_lang.RACK}</td>
								<td style={{ textAlign: 'center' }}>{materialwarehouse[0].rack.name}</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.MATERIAL_STATUS}
								</td>
								<td style={{ textAlign: 'center' }}>{material.status}</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>{withdrawal_lang.HAVE_QUANTITY}</td>
								<td style={{ textAlign: 'center' }}>
									{materialwarehouse[0].quantity + ' ' + material.unit.name}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_QUANTITY}
								</td>
								<td style={{ textAlign: 'center' }}>
									<Input
										type="number"
										placeholder="ໃສ່ຈຳນວນທີ່ຕ້ອງການ"
										onChange={onChangeWDQuantity}
										className={errors.wdquantity || errors.quantity ? 'has-danger' : null}
									/>
									{errors.wdquantity ? <span style={{ color: 'red' }}>{errors.wdquantity}</span> : ''}
									{errors.WDQuantityLessThanZero ? (
										<span style={{ color: 'red' }}>{errors.WDQuantityLessThanZero}</span>
									) : (
										''
									)}
									<p style={{ color: 'red' }}>{errors.quantity ? errors.quantity : null}</p>
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>ວັນທີເບີກ</td>
								<td>
									<ReactDatetime
										dateFormat="YYYY-MM-DD HH:mm:ss"
										defaultValue={moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')}
										inputProps={{
											className: 'form-control',
											placeholder: withdrawal_lang.WITHDRAWAL_DATE
										}}
										onChange={e => {
											setData({
												...data,
												withdraw_date: moment(e).format('YYYY-MM-DD HH:mm:ss')
											});
										}}
									/>
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.WITHDRAW_PHOTO}
								</td>
								<td style={{ textAlign: 'center' }}>
									<div className="form-check-radio inline">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="before_images"
												id="radio1"
												value="browse"
												onClick={e => setImageOption(e.target.value)}
												defaultChecked
											/>
											ເລືອກຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<div className="form-check-radio">
										<Label className="form-check-label">
											<Input
												type="radio"
												name="before_images"
												id="radio2"
												value="webcam"
												onClick={e => setImageOption(e.target.value)}
											/>
											ຖ່າຍຮູບ
											<span className="form-check-sign" />
										</Label>
									</div>
									<p style={{ color: 'red' }}>{errors.image ? errors.image : null}</p>
									{imageOption === 'browse' ? (
										<ImageUpload setAvatar={setBeforeImages} />
									) : webcamImg ? (
										<React.Fragment>
											<img src={webcamImg} />
											<Button style={{ marginTop: '10px' }} onClick={() => setWebcamImg(null)}>
												ຖ່າຍໃໝ່
											</Button>
										</React.Fragment>
									) : (
										<WebcamCapture
											setWebcamImg={setWebcamImg}
											setWebcamImgForm={setWebcamImgForm}
										/>
									)}
								</td>
							</tr>
							<tr style={{ fontSize: '1rem' }}>
								<td style={{ textAlign: 'right', fontWeight: 700 }}>
									{withdrawal_lang.REQUIRE_RETURN}
								</td>
								<td style={{ textAlign: 'center' }}>
									<FormGroup check>
										<Label check>
											<Input
												type="checkbox"
												defaultChecked={true}
												onClick={() => setClosed(!closed)}
											/>{' '}
											<span className="form-check-sign">
												<span className="check" />
											</span>
										</Label>
									</FormGroup>
								</td>
							</tr>
						</tbody>
					</Table>
				)}
			</CardBody>
			{contentLoading ? null : (
				<CardFooter style={{ textAlign: 'center' }}>
					<p style={{ color: 'red' }}>{errors.employee ? errors.employee : null}</p>
					<Button
						size="md"
						style={{ fontSize: '1.1rem' }}
						color="success"
						onClick={handleSubmit}
						disabled={submitLoading}
					>
						ສົ່ງຂໍ້ມູນ
					</Button>
				</CardFooter>
			)}
		</Card>
	);
};

export default LongTermMaterial;
