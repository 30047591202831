import React, { useState } from 'react';

import { Row, Col, Card, CardHeader, Button } from 'reactstrap';

import { Link } from 'react-router-dom';

import { withdrawal_lang } from '../../i18n/lo-LA';
import FilterWidget from '../components/Withdrawal/FilterWidget';
import WithdrawalList from '../components/Withdrawal/WithdrawalList';
import useGlobal from '../../store';
import strapi, { URL } from '../../strapi-sdk';

const LIMIT = 10;

const WithdrawalTool = props => {
	const [searchParams, setSearchParams] = useState(null);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [withdrawals, setWithdrawals] = useState(null);

	const handleSubmit = async newParams => {
		const countPage = await strapi.request('GET', `${URL}withdrawals/count/?${newParams}`);
		setPageCount(Math.ceil(countPage / LIMIT));
		strapi
			.request('GET', `${URL}withdrawals?${newParams}&_start=${0}&_limit=${LIMIT}&_sort=updated_at:DESC`)
			.then(res => {
				setWithdrawals(res);
			});
	};

	const updatePage = async p => {
		setPage(p);
		strapi
			.request(
				'GET',
				`${URL}withdrawals?${searchParams}&_start=${(p - 1) * LIMIT}&_limit=${LIMIT}&_sort=updated_at:DESC`
			)
			.then(res => {
				setWithdrawals(res);
			});
	};

	return (
		<div className="content">
			<Row>
				<Col sm={12} md={12}>
					<Card>
						<CardHeader>
							<Row>
								<Col lg="7" xs="6">
									<h3 className="title" style={{ marginBottom: 'unset' }}>
										{withdrawal_lang.WITHDRAWAL_TOOL_LIST}
									</h3>
								</Col>
								<Col lg="5" xs="6">
									<div className="pull-right">
										<Link to="/page/withdrawal-tools/add">
											<Button size="sm" style={{ marginTop: 0, fontSize: '1.2rem' }}>
												{withdrawal_lang.ADD}
											</Button>
										</Link>
									</div>
								</Col>
							</Row>
						</CardHeader>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12}>
					<FilterWidget setSearchParams={setSearchParams} handleSubmit={handleSubmit} />
				</Col>
				<Col sm={12} md={12}>
					<WithdrawalList
						page={page}
						updatePage={updatePage}
						withdrawals={withdrawals}
						pageCount={pageCount}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default WithdrawalTool;
