import React, { useState, useEffect } from 'react';

import {
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	Label,
	Spinner,
	FormGroup,
	Input,
	Table,
	CardFooter,
	Button
} from 'reactstrap';
import { withdrawal_lang } from '../../../i18n/lo-LA';
import strapi, { URL } from '../../../strapi-sdk';
import useGlobal from '../../../store';
import Select from 'react-select';
import moment from 'moment';
import ReactDatetime from 'react-datetime';

let mytimer;

export const handlePageChange = () => {};

const FilterWidget = props => {
	const [globalState, globalActions] = useGlobal();
	const [EmployeesOption, setEmployeesOption] = useState([]);
	const [loadingEmp, setLoadingEmp] = useState(true);
	const [Employee, setEmployee] = useState(null);
	// Material Types
	const [materialTypeOptions, setMaterialTypeOptions] = useState([]);
	const [loadingMTypes, setLoadingMTypes] = useState(true);
	const [materialType, setMaterialType] = useState(null);

	//date
	const [dateOption, setDateOption] = useState(null);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	//closed
	const [closed, setClosed] = useState(false);

	useEffect(
		() => {
			const initialize = async () => {
				var emp = strapi.request('GET', `${URL}employees?_limit=10`);
				var mtypes = strapi.getEntries('materialtypes');
				// console.log(emp);
				Promise.all([emp, mtypes]).then(resolves => {
					assignEmpToSelect(resolves[0]);
					assignMtypesToSelect(resolves[1]);
					setLoadingEmp(false);
				});
			};
			initialize();
			handleSubmit();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// EMPLOYEE
	const EmpOnChange = async e => {
		if (e.value !== 'all') {
			const emp = await strapi.getEntry('employees', e.id);
			setEmployee(emp);
		} else {
			setEmployee('all');
		}
	};

	const assignEmpToSelect = resEmp => {
		let local_Emp = [{ value: 'all', label: 'ສະແດງທັງໝົດ' }];
		resEmp.map(x => {
			local_Emp = [...local_Emp, { value: x.fullname, label: x.fullname, id: x.id }];
		});
		setEmployeesOption(local_Emp);
	};

	// EMPLOYEE SIDEBAR
	const searchOnInputChange = e => {
		if (e === '') return;
		clearInterval(mytimer);
		setLoadingEmp(true);
		mytimer = setTimeout(() => {
			strapi
				.request('GET', `${URL}employees?fullname_contains=${e}`)
				.then(res => {
					assignEmpToSelect(res);
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					setLoadingEmp(false);
				});
		}, 1000);
	};

	// END EMPLOYEE

	// MATERIAL TYPE
	const assignMtypesToSelect = resMTypes => {
		setLoadingMTypes(true);
		let local_MTypes = [{ value: 'all', label: 'ສະແດງທັງໝົດ' }];
		resMTypes.map(x => {
			local_MTypes = [...local_MTypes, { value: x.name, label: x.name, id: x.id }];
		});
		const newArr = local_MTypes.filter(item => item.id !== 1);
		setMaterialTypeOptions(newArr);
		setLoadingMTypes(false);
	};
	const onMTypeChange = e => {
		if (e.value !== 'all') setMaterialType(e);
		else setMaterialType('all');
	};
	// END MATERIAL TYPE

	// HANDLESUBMIT
	function newParams() {
		let emp = '';
		let mtype = '';
		let local_fromDate = '';
		let local_toDate = '';
		let local_closed = `&closed=${closed}`;
		if (Employee !== null && Employee !== 'all') emp = `employee=${Employee.id}`;
		if (materialType !== null && materialType !== 'all') mtype = `&material_type=${materialType.id}`;

		if (dateOption === 'withdraw') {
			if (fromDate !== 'Invalid date' || fromDate !== null) {
				if (fromDate !== null) local_fromDate = `&withdraw_date_gte=${fromDate}`;
			}
			if (toDate !== 'Invalid date' || toDate !== null) {
				if (toDate !== null) local_toDate = `&withdraw_date_lte=${toDate}`;
			}
		}
		if (dateOption === 'return') {
			if (fromDate !== 'Invalid date' && fromDate !== null) {
				if (fromDate !== null) local_fromDate = `&return_date_gte=${fromDate}`;
			}
			if (toDate !== 'Invalid date' && toDate !== null) {
				if (toDate !== null) local_toDate = `&return_date_lte=${toDate}`;
			}
		}
		const newParams = emp + mtype + local_fromDate + local_toDate + local_closed;
		return newParams;
	}
	const handleSubmit = async () => {
		// console.log(materialType);
		// const countPage = await strapi.request('GET', `${URL}withdrawals/count/?${newParams()}`);
		// globalActions.updateWithdrawPageCount(countPage);
		// strapi.request('GET', `${URL}withdrawals?${newParams()}`).then(res => {
		// 	globalActions.updateWithdrawList(res);
		// });
		props.setSearchParams(newParams());
		props.handleSubmit(newParams());
	};

	const loadingMessage = () => <Spinner color="primary" />;

	return (
		<Card>
			<CardHeader>
				<h3 style={{ marginBottom: 'unset' }}>{withdrawal_lang.FILTER}</h3>
			</CardHeader>
			<CardBody>
				<Row>
					<Col sm={3}>
						<Label style={{ fontSize: '1.1rem' }}>{withdrawal_lang.CHOOSE_EMPLOYEE}</Label>
						<Select
							className="react-select primary SelectInModalFont"
							classNamePrefix="react-select"
							name="employee"
							onInputChange={searchOnInputChange}
							onChange={EmpOnChange}
							// value={search}
							options={EmployeesOption}
							isLoading={loadingEmp}
							loadingMessage={loadingMessage}
							style={{ fontSize: '1.1rem' }}
							placeholder={withdrawal_lang.EMPLOYEE_NAME}
						/>
					</Col>

					<Col sm={3}>
						<Label style={{ fontSize: '1.1rem' }}>{withdrawal_lang.MATERIAL_TYPE}</Label>
						<Select
							className="react-select primary SelectInModalFont"
							classNamePrefix="react-select"
							name="materialtypes"
							// onInputChange={searchOnInputChange}
							onChange={onMTypeChange}
							// value={search}
							options={materialTypeOptions}
							isLoading={loadingMTypes}
							loadingMessage={loadingMessage}
							style={{ fontSize: '1.1rem' }}
							placeholder={withdrawal_lang.CHOOSE_MATERIAL_TYPE}
							isSearchable={false}
						/>
					</Col>
					<Col sm={3} className="mt-3">
						<Table borderless>
							<tbody>
								<tr>
									<td style={{ fontSize: '1rem', width: '40%' }}>{withdrawal_lang.CLOSED}</td>
									<td>
										<FormGroup check>
											<Label check>
												<Input
													type="checkbox"
													defaultChecked={closed}
													onClick={() => setClosed(!closed)}
												/>{' '}
												<span className="form-check-sign">
													<span className="check" />
												</span>
											</Label>
										</FormGroup>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<Row>
					<Col sm={3}>
						<Label style={{ fontSize: '1.1rem' }}>{withdrawal_lang.PICK_DATE_OPTIONS}</Label>
						<Select
							className="react-select primary"
							classNamePrefix="react-select"
							name="status"
							onChange={value => {
								setDateOption(value.value);
							}}
							// options={branches}
							options={[{ value: 'withdraw', label: 'ເບີກ' }, { value: 'return', label: 'ສົ່ງຄືນ' }]}
							placeholder={withdrawal_lang.PICK_DATE_OPTIONS}
						/>
					</Col>
					{dateOption ? (
						<React.Fragment>
							<Col sm={3}>
								<Label style={{ fontSize: '1.1rem' }}>{withdrawal_lang.WITH_DRAW_FROM_DATE}</Label>
								<ReactDatetime
									dateFormat="YYYY-MM-DD HH:mm:ss"
									// defaultValue={moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')}
									inputProps={{
										className: 'form-control',
										placeholder: withdrawal_lang.FROM_DATE
									}}
									onChange={e => {
										setFromDate(moment(e).format('YYYY-MM-DD HH:mm:ss'));
									}}
								/>
							</Col>
							<Col sm={3}>
								<Label style={{ fontSize: '1.1rem' }}>{withdrawal_lang.WITH_DRAW_TO_DATE}</Label>
								<ReactDatetime
									dateFormat="YYYY-MM-DD HH:mm:ss"
									// defaultValue={moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')}
									inputProps={{
										className: 'form-control',
										placeholder: withdrawal_lang.TO_DATE
									}}
									onChange={e => {
										setToDate(moment(e).format('YYYY-MM-DD HH:mm:ss'));
									}}
								/>
							</Col>
						</React.Fragment>
					) : null}
				</Row>
			</CardBody>
			<CardFooter
				style={{
					fontSize: '1.1rem',
					textAlign: 'center',
					borderTop: 'dashed',
					borderWidth: '2px',
					borderColor: '#ccc'
				}}
			>
				<Button color="success" onClick={handleSubmit}>
					ຄົ້ນຫາ
				</Button>
			</CardFooter>
		</Card>
	);
};

export default FilterWidget;
