import React, { useState, useEffect } from 'react';

import { UncontrolledTooltip, Button, Modal, Card, CardBody, Row, Col, Table, Spinner } from 'reactstrap';

import { import_lang, import_form_lang, import_modal_lang, material_form_modal_lang } from '../../../i18n/lo-LA';

import _ from 'lodash';
import strapi, { URL } from '../../../strapi-sdk';

import Select from 'react-select';

const MoveModal = props => {
	const [modal, setModal] = useState(false);
	const [orders, setOrders] = useState(null);
	const [loading, setLoading] = useState(true);

	const [warehouses, setWarehouses] = useState(null);
	const [data, setData] = useState({
		warehouse: '',
		rack: ''
	});
	const [selectedWarehouse, setSelectedWarehouse] = useState(null);
	//RACK
	const [RackLoading, setRackLoading] = useState(true);
	const [Racks, setRacks] = useState(null);
	const [SelectedRack, setSelectedRack] = useState(null);

	//logs
	const [units, setUnits] = useState(null);

	//errors
	const [errors, setErrors] = useState({});

	const fetchOrders = () => {
		strapi.request('GET', `${URL}materialorderitems?materialorder=${props.id}`).then(res => {
			setOrders(res);
			assignWarehousesToSelect(props.warehouses);
			setSelectedWarehouse({ id: 1, name: 'ສາງວຽງຈັນເຊັນເຕີ', label: 'ສາງວຽງຈັນເຊັນເຕີ' });
			setLoading(false);
		});
	};

	// FETCHING UNITS
	const fetchUnits = async () => {
		try {
			const res = await strapi.getEntries('units');
			setUnits(res);
		} catch (err) {
			console.log(err);
		}
	};
	// END FETCHING UNITS

	// GET RACKS
	const fetchRacks = async id => {
		const resRacks = await strapi.getEntry('warehouses', id);
		setRackLoading(true);
		await getRacks(resRacks);
	};

	async function getRacks(resRacks) {
		let racksArray = [];
		resRacks.racks.map(x => {
			racksArray = [...racksArray, { value: x.name, label: x.name, id: x.id }];
		});
		setRacks(racksArray);
		setRackLoading(false);
	}
	// END RACK

	const toggleModalLarge = () => {
		setModal(!modal);
		if (modal === false) {
			fetchOrders();
			fetchUnits();
		} else {
			setErrors({});
			setRacks(null);
		}
	};

	async function assignWarehousesToSelect(resSearch) {
		let local_warehouses = [];
		resSearch.map(x => {
			local_warehouses = [...local_warehouses, { value: x.name, label: x.name, id: x.id }];
		});
		setWarehouses(local_warehouses);
	}

	const handleSubmit = () => {
		//CHECK IF WAREHOUSES & RACK IS EXISTS
		let errors = {};
		if (data.warehouse === '') errors.warehouse = import_modal_lang.ERROR_WAREHOUSE;
		if (data.rack === '') errors.rack = import_modal_lang.ERROR_RACK;

		if (_.isEmpty(errors)) {
			let arr = [];
			_.forEach(orders, async order => {
				setLoading(true);
				try {
					const resApi = await strapi.request(
						'GET',
						`${URL}materialwarehouses?warehouse=${data.warehouse}&rack=${data.rack}&material=${order
							.material.id}`
					);
					// IF EMPTY CREATE ELSE UPDATE
					if (_.isEmpty(resApi)) {
						strapi
							.createEntry('materialwarehouses', {
								material: order.material.id,
								quantity: order.quantity,
								warehouse: data.warehouse,
								rack: data.rack,
								materialtype: order.material.materialtype
							})
							.then(async res => {
								let x = {
									user: localStorage.getItem('id'),
									from_value: _.isEmpty(resApi) ? 0 : resApi[0].quantity,
									to_value: res.quantity,
									action_type: 'MOVED',
									material: res.material.name,
									warehouse: res.warehouse.name,
									rack: res.rack.name,
									materialwarehouse: res.id,
									unit: units.filter(item => item.id === res.material.unit)[0].name,
									bill_id: props.item.id
								};
								await strapi.createEntry('materiallogs', x);
							});
					} else {
						strapi
							.updateEntry('materialwarehouses', resApi[0].id, {
								quantity: resApi[0].quantity + order.quantity
							})
							.then(async res => {
								let x = {
									user: localStorage.getItem('id'),
									from_value: resApi[0].quantity,
									to_value: res.quantity,
									action_type: 'MOVED',
									material: res.material.name,
									warehouse: res.warehouse.name,
									rack: res.rack.name,
									materialwarehouse: res.id,
									unit: units.filter(item => item.id === res.material.unit)[0].name,
									bill_id: props.item.id
								};
								await strapi.createEntry('materiallogs', x);
							});
					}
				} catch (err) {
					console.log(err);
				}
			});
			setLoading(false);
			if (!loading) {
				const newData = { approve: true };
				Promise.all(arr).then(resolves => {
					strapi.request('PUT', `${URL}materialorders/edit/${props.id}`, { data: newData }).then(res => {
						props.updateTab(props.tab, 1, props.nav);
					});
				});
			}
		} else {
			setErrors(errors);
		}
	};

	return (
		<React.Fragment>
			<Modal isOpen={modal} toggle={toggleModalLarge} size="lg">
				<Card className=" card-login card-plain">
					<div className=" modal-header justify-content-center">
						<button
							aria-label="Close"
							className=" close"
							data-dismiss="modal"
							type="button"
							onClick={toggleModalLarge}
						>
							<span aria-hidden={true}>×</span>
						</button>
						<div className=" header header-primary text-center">
							<div className=" logo-container">
								<h3 className="title" style={{ marginBottom: 'unset' }}>
									{import_modal_lang.BILL_CODE} #{props.item.id}
								</h3>
							</div>
						</div>
					</div>
					<div className="modal-body">
						<CardBody>
							{loading ? (
								<Row>
									<Col sm={12} md={{ size: 6, offset: 3 }} className="text-center">
										<Spinner color="primary" />
									</Col>
								</Row>
							) : (
								<React.Fragment>
									{props.success ? null : (
										<Row
											style={{
												borderBottom: 'dashed',
												borderWidth: '2px',
												borderColor: '#ccc',
												paddingBottom: '10px'
											}}
										>
											<Col sm="12" md={{ size: 9, offset: 3 }}>
												<Row>
													<Col md={3} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
														<span style={{ fontSize: '1.2rem' }}>
															{import_modal_lang.MOVE_TO_WAREHOUSE}{' '}
															<span style={{ color: 'red' }}>*</span>
														</span>
													</Col>
													<Col md={6}>
														<Select
															required
															className={`react-select primary SelectInModalFont ${errors.warehouse ===
															''
																? null
																: 'has-danger'}`}
															classNamePrefix="react-select"
															name="warehouses"
															// value={selectedWarehouse}
															onChange={async value => {
																const res = await strapi.getEntry(
																	'warehouses',
																	value.id
																);
																setData({ ...data, warehouse: value.id });
																setSelectedWarehouse({
																	label: value.name,
																	value: value.id
																});
																setSelectedRack(null);
																await fetchRacks(res.id);
															}}
															options={warehouses}
															placeholder={import_modal_lang.CHOOSE_WAREHOUSE}
														/>
														{errors.warehouse === '' ? null : (
															<span style={{ fontSize: '0.9rem', color: 'red' }}>
																{errors.warehouse}
															</span>
														)}
														{RackLoading ? null : _.isEmpty(Racks) ? (
															<p style={{ fontSize: '0.9rem,', color: 'red' }}>
																{import_modal_lang.ERROR_NO_RACK}
															</p>
														) : (
															<Select
																className="react-select primary SelectInModalMarginTop SelectInModalFont"
																classNamePrefix="react-select"
																name="rack"
																value={SelectedRack}
																onChange={value => {
																	setData({ ...data, rack: value.id });
																	setSelectedRack(value);
																}}
																options={Racks}
																placeholder={material_form_modal_lang.SELECT_RACK}
															/>
														)}
														{errors.rack === '' ? null : (
															<span style={{ fontSize: '0.9rem', color: 'red' }}>
																{errors.rack}
															</span>
														)}
													</Col>
												</Row>
											</Col>
										</Row>
									)}
									<br />
									<Row>
										<Col
											sm={12}
											style={{
												fontSize: '1.2rem',
												textDecoration: 'underline',
												fontWeight: 'bold'
											}}
											className="text-center"
										>
											{import_modal_lang.ORDERS_LIST}
										</Col>
									</Row>
									<Row>
										<Col
											sm={12}
											style={{
												fontSize: '1.0rem',
												fontWeight: 'bold'
											}}
											className="text-center"
										>
											{import_modal_lang.FROM} {props.item.supplier.name}
										</Col>
									</Row>
									<Row>
										<Col sm={12}>
											{_.isEmpty(orders) ? (
												<p
													style={{
														textAlign: 'center',
														fontSize: '1.3rem',
														color: 'red',
														marginTop: '20px'
													}}
												>
													{import_modal_lang.ERROR_NO_ORDER}
												</p>
											) : (
												<Table style={{ fontSize: '1.1rem' }} striped>
													<thead>
														<tr>
															<th>{import_form_lang.MATERIAL}</th>
															<th className="text-center">{import_form_lang.QUANTITY}</th>
															<th style={{ textAlign: 'right' }}>
																{import_form_lang.PRICE}
															</th>
														</tr>
													</thead>
													<tbody>
														{orders.map(order => (
															<tr key={order.id}>
																<td>
																	{order.material.name}{' '}
																	{order.material.serial_number === null ? null : (
																		<span>
																			(#SN: {order.material.serial_number})
																		</span>
																	)}
																</td>
																<td className="text-center">{order.quantity}</td>
																<td style={{ textAlign: 'right' }}>
																	{new Intl.NumberFormat('ja-JP').format(
																		order.price
																	) +
																		' ' +
																		import_form_lang.KIP}
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											)}
										</Col>
									</Row>
								</React.Fragment>
							)}
						</CardBody>
					</div>
					{props.success ? null : (
						<div className="modal-footer text-right" style={{ paddingRight: '30px' }}>
							<Button
								color="secondary"
								style={{ fontSize: '1.1rem' }}
								onClick={handleSubmit}
								disabled={loading || _.isEmpty(orders)}
							>
								{import_modal_lang.MOVE}
							</Button>
						</div>
					)}
				</Card>
			</Modal>

			{props.success === true ? (
				<Button
					className="btn"
					style={{ fontSize: '1rem', padding: '0.375rem 0.75rem' }}
					size="md"
					color="success"
					onClick={toggleModalLarge}
				>
					{import_lang.APPROVED}
				</Button>
			) : (
				<React.Fragment>
					<UncontrolledTooltip delay={0} target={`tooltip${props.id}`} style={{ fontSize: '1.1rem' }}>
						{import_modal_lang.CLICK_TO_MOVE}
					</UncontrolledTooltip>
					<Button
						className="btn"
						size="md"
						style={{ fontSize: '1rem', padding: '0.375rem 0.75rem' }}
						color="warning"
						id={`tooltip${props.id}`}
						onClick={toggleModalLarge}
					>
						{import_lang.WAITING_FOR_APPROVING}
					</Button>
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export default MoveModal;
