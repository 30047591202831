import React, { useState } from 'react';
import strapi from '../../../strapi-sdk';

import { Button } from 'reactstrap';
import Switch from 'react-bootstrap-switch';

import useGlobal from '../../../store';

import _ from 'lodash';
import { warehouse_lang } from '../../../i18n/lo-LA';

const WarehouseTable = props => {
	const [globalState, globalActions] = useGlobal();
	const [alert, setAlert] = useState(null);

	// fetch data from API
	// using Strapi SDK
	// Update fetched data to Global State
	const useFetchWarehouses = () => {
		const [globalState, globalActions] = useGlobal();

		const fetchData = async () => {
			const res = await strapi.getEntries('warehouses');
			globalActions.updateWarehousesList(res);
		};

		if (_.isEmpty(globalState.warehouses)) {
			fetchData();
		}
		return null;
	};
	useFetchWarehouses();
	// END useFetchWarehouses

	// UPDATING HIDE STATUS
	const updateStatus = item => {
		let newItem = {
			name: item.name,
			tel: item.tel,
			hide: !item.hide
		};

		function updateList(elements) {
			const newList = globalState.warehouses.map(x => {
				if (x.id === elements.id) {
					x.hide = elements.hide;
				}
				return x;
			});
			globalActions.updateWarehousesList(newList);
		}

		strapi
			.updateEntry('warehouses', item.id, newItem)
			.then(res => {
				updateList(res);
				return true;
			})
			.catch(err => {
				updateList(item);
				// debugger;
				return false;
			});
	};

	// END UPDATING

	return (
		<React.Fragment>
			{alert}
			<tbody>
				{_.isEmpty(globalState.warehouses) ? (
					<tr>
						<td>Loading...</td>
					</tr>
				) : (
					globalState.warehouses.map((item, idx) => (
						<tr key={item.id}>
							<td>{idx + 1}</td>
							<td>{item.name}</td>
							{/* <td>
								<Switch
									onColor="default"
									offColor="default"
									// defaultValue={!item.hide}
									value={!item.hide}
									onChange={() => updateStatus(item)}
								/>
							</td> */}
							<td>{item.tel}</td>
							<td className="text-right">
								<Button
									className="btn"
									color="success"
									size="sm"
									onClick={() => props.showModal(item.id)}
								>
									<i className="fa fa-edit" />
									{warehouse_lang.EDIT}
								</Button>
							</td>
						</tr>
					))
				)}
			</tbody>
		</React.Fragment>
	);
};

export default WarehouseTable;
