import React, { useState } from 'react';

import { DropdownItem } from 'reactstrap';

import { import_lang, modal_lang } from '../../../i18n/lo-LA';
import strapi from '../../../strapi-sdk';

import ReactBSAlert from 'react-bootstrap-sweetalert';

const DeleteModal = props => {
	const [alert, setAlert] = useState(null);

	const handleRemove = () => {
		setAlert(
			<ReactBSAlert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={modal_lang.ARE_YOU_SURE}
				onConfirm={() => successDelete()}
				onCancel={() => cancelDetele()}
				confirmBtnBsStyle="info"
				cancelBtnBsStyle="danger"
				confirmBtnText={modal_lang.YES_IM_SURE}
				cancelBtnText={modal_lang.CANCEL}
				showCancel
			>
				You will not be able to recover this imaginary file!
			</ReactBSAlert>
		);
	};

	const successDelete = () => {
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '-100px' }}
				title="Deleted!"
				onConfirm={() => hideAlert()
				// {
				// strapi
				// .deleteEntry('materialorders', props.id)
				// .then(res => {
				//     hideAlert()
				// })
				// .catch(err => {
				//     console.log(err);
				// });

				// }
				}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				Your imaginary file has been deleted.
			</ReactBSAlert>
		);
	};
	const cancelDetele = () => {
		setAlert(
			<ReactBSAlert
				danger
				style={{ display: 'block', marginTop: '-100px' }}
				title="Cancelled"
				onConfirm={() => hideAlert()}
				onCancel={() => hideAlert()}
				confirmBtnBsStyle="info"
			>
				Your imaginary file is safe :)
			</ReactBSAlert>
		);
	};

	const hideAlert = () => {
		setAlert(null);
	};

	return (
		<React.Fragment>
			<DropdownItem href="#pablo" onClick={() => props.handleRemove(props.id)}>
				{import_lang.REMOVE}
			</DropdownItem>
		</React.Fragment>
	);
};

export default DeleteModal;
