import React, { useState, useEffect } from 'react';

import { Button, Modal, Input, Card, InputGroup, InputGroupAddon, InputGroupText, Form, CardBody } from 'reactstrap';

import strapi from '../../../strapi-sdk';

import useGlobal from '../../../store';

import _ from 'lodash';

import { validateName, validateTel } from '../../../utils/validate';

const BranchModal = props => {
	const [globalState, globalActions] = useGlobal();
	const [data, setData] = useState({ name: '', tel: '' });
	const [contentID, setContentID] = useState(null);
	const [err, setErr] = useState([]);

	useEffect(
		() => {
			if (props.contentID !== null) {
				setContentID(props.contentID);
				async function fetchDatafromID(contentID) {
					var type = typeof contentID;
					if (type === 'number') {
						const res = await strapi.getEntry('branches', contentID);
						setData({ name: res.name, tel: res.tel });
					}
				}
				fetchDatafromID(props.contentID);
			} else {
				setData({ name: '', tel: '' });
			}
		},
		[props.contentID]
	);

	function onChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	async function handleSubmit(props) {
		let errors = {};
		if (!validateName(data.name)) {
			errors.branchName = 'ຊື່ສາຂາຕ້ອງບໍ່ໃຫ້ມີໂຕອັກສອນພິເສດ ຫຼື ວ່າງເປົ່າ';
		}
		if (!validateTel(data.tel)) {
			errors.branchTel = 'ເບີໂທຕ້ອງເປັນໂຕເລກເທົ່ານັ້ນ';
		}

		if (!_.isEmpty(errors)) {
			setErr(errors);
			return;
		}

		try {
			if (props.contentID === null) {
				await strapi.createEntry('branches', data);
			} else {
				await strapi.updateEntry('branches', contentID, data);
			}
			globalActions.updateBranchesList(await strapi.getEntries('branches'));
			setData({ name: '', tel: '' });
			setContentID(null);
			props.hideModal();
			props.notify('tr', 2, 'ເພີ່ມຂໍ້ມູນສາຂາເຂົ້າຖານຂໍ້ມູນຮຽບຮ້ອຍແລ້ວ!');
		} catch {
			props.notify('tr', 3, 'ຜິດພາດ! ກະລຸນາຕິດຕໍ່ຜູ້ພັດທະນາລະບົບ');
		}
	}

	return (
		<Modal
			className="modal-login"
			modalClassName=" modal-primary"
			isOpen={props.ModalBranch}
			toggle={props.showModal}
		>
			<Card className=" card-login card-plain">
				<div className=" modal-header justify-content-center">
					<button
						aria-label="Close"
						className=" close"
						data-dismiss="modal"
						type="button"
						onClick={props.showModal}
					>
						<span aria-hidden={true}>×</span>
					</button>
					<div className=" header header-primary text-center">
						<div className=" logo-container">
							<h3 className="title" style={{ marginBottom: 'unset' }}>
								{props.title}ສາຂາ
							</h3>
						</div>
					</div>
				</div>
				<div className=" modal-body">
					<Form action="" className=" form" method="">
						<CardBody>
							<InputGroup size="lg">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className=" nc-icon nc-shop" />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									name="name"
									placeholder="ຊື່ສາຂາ"
									type="text"
									style={{ borderColor: err.branchName && 'red' }}
									value={data.name}
									onChange={onChange}
								/>
							</InputGroup>
							<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
								{err.branchName && err.branchName}
							</p>
							<InputGroup size="lg">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<i className=" nc-icon nc-mobile" />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									name="tel"
									placeholder="ເບີໂທລະສັບ"
									type="tel"
									style={{ borderColor: err.branchTel && 'red' }}
									value={data.tel}
									onChange={onChange}
								/>
							</InputGroup>
							<p className="text-center" style={{ color: 'red', fontSize: '1.1rem' }}>
								{err.branchTel && err.branchTel}
							</p>
						</CardBody>
					</Form>
				</div>
				<div className=" modal-footer text-right">
					<Button
						block
						className=" btn-default "
						style={{ fontSize: '1.1rem' }}
						color="default"
						onClick={() => handleSubmit(props)}
					>
						ສົ່ງຂໍ້ມູນ
					</Button>
				</div>
			</Card>
		</Modal>
	);
};

export default BranchModal;
