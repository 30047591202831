import validator from 'validator';

export const validateName = name => {
	const iChars = /[!@#$^&*(),?":{}|<>]/g; //except % .
	if (name.match(iChars) !== null || name.trim() === '') return false;
	return true;
};

export const validateTel = tel => {
	return validator.isNumeric(tel);
};

export const validateQuantity = num => {
	return validator.isFloat(num);
};
