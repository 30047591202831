import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Row, Col, Input } from 'reactstrap';

import strapi from '../../../../strapi-sdk';

import { modal_lang } from '../../../../i18n/lo-LA';

const MaterialAddValueModal = props => {
	const [modal, setModal] = useState(false);
	const [currentQuantity, setCurrentQuantity] = useState(0);
	const toggle = () => {
		setCurrentQuantity(0);
		setModal(!modal);
	};

	const onChange = e => {
		setCurrentQuantity(e.target.value);
	};

	const handleSubmit = async () => {
		let logs = {
			user: localStorage.getItem('id'),
			from_value: props.quantity,
			to_value: parseFloat(props.quantity) + parseFloat(currentQuantity),
			materialwarehouse: props.id,
			material: props.material,
			warehouse: props.warehouse,
			rack: props.rack,
			unit: props.unit,
			action_type: 'EDIT'
		};

		const item = {
			quantity: parseFloat(props.quantity) + parseFloat(currentQuantity)
		};
		try {
			await strapi.updateEntry('materialwarehouses', props.id, item);
			await strapi.createEntry('materiallogs', logs);
			props.getReadyPage(props.materialID);
			props.notify('tr', 2, modal_lang.SUCCESS);
		} catch {
			props.notify('tr', 3, modal_lang.FAIL);
		}
	};

	return (
		<React.Fragment>
			<Button onClick={toggle} className="btn" color="primary" size="sm">
				<i className="fa fa-plus" />
				{props.title}
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<div className="modal-header justify-content-center">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggle}>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{modal_lang.ADD_MTR_VALUE}</h5>
				</div>
				<ModalBody>
					<Row>
						<Col sm={12} md={6} className="border-right">
							<p style={{ fontSize: '1rem', fontWeight: 700 }}>{props.material}</p>
							<p style={{ fontSize: '1rem' }}>{props.warehouse}</p>
							<p style={{ fontSize: '1rem' }}>
								ປັດຈຸບັນມີ{' '}
								<span style={{ color: 'green', fontWeight: 700 }}>{props.quantity + props.unit}</span>
							</p>
						</Col>
						<Col sm={12} md={6}>
							<p style={{ fontSize: '1rem' }}>ເພີ່ມຕື່ມຈຳນວນ</p>
							<Input placeholder="ຈຳນວນ" name="quantity" onChange={onChange} autoComplete="off" />
							<p />
							{currentQuantity !== 0 && !isNaN(parseFloat(currentQuantity)) ? (
								<React.Fragment>
									<p style={{ fontSize: '1.1rem' }}>
										ເພີ່ມເປັນ{' '}
										<span style={{ color: 'red', fontWeight: 700 }}>
											{parseFloat(props.quantity) + parseFloat(currentQuantity)}
											{props.unit}
										</span>
									</p>
								</React.Fragment>
							) : (
								''
							)}
						</Col>
					</Row>
					{/* <h5 style={{ textAlign: 'center' }}>
						{props.material} ຢູ່ສາງ {props.warehouse}
						<p style={{ color: 'green', fontWeight: '700' }}>
							ມີ
							{props.quantity}
							{props.unit}
						</p>
					</h5> */}
				</ModalBody>
				{currentQuantity !== 0 && !isNaN(parseFloat(currentQuantity)) ? (
					<ModalFooter>
						<Button
							className="btn"
							color="primary"
							// size="sm"
							style={{ fontSize: '1.1rem', width: '100%' }}
							onClick={handleSubmit}
						>
							ເພີ່ມເລີຍ
						</Button>
					</ModalFooter>
				) : (
					''
				)}
			</Modal>
		</React.Fragment>
	);
};

export default MaterialAddValueModal;
