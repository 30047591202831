import React, { useRef, useEffect, useState } from 'react';

import { material_lang } from '../../../i18n/lo-LA';

import { Row, Col, Table, Card, CardBody } from 'reactstrap';

import NotificationAlert from 'react-notification-alert';

import MaterialTable from '../../components/Material/Material/MaterialTable';

import '../titleBar.css';
import Material from './Material';

const MaterialTableLayout = props => {
	const refNotification = useRef(null);
	const [type, setType] = useState(null);

	useEffect(
		() => {
			if (props.location.pathname.indexOf('raw-materials') !== -1) {
				setType(1);
			}
			if (props.location.pathname.indexOf('tool-materials') !== -1) {
				setType(2);
			}
		},
		[props.location.pathname]
	);

	const notify = (place, color, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: <div>{message}</div>,
			type: type,
			icon: 'nc-icon nc-bell-55',
			autoDismiss: 7
		};
		refNotification.current.notificationAlert(options);
	};

	return (
		<Material type={type} location={props.location.pathname}>
			<NotificationAlert ref={refNotification} />
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							{props.location.pathname.indexOf('raw-materials') !== -1 ? (
								<MaterialTable location={props.location} type="1" notify={notify} />
							) : null}
							{props.location.pathname.indexOf('tool-materials') !== -1 ? (
								<MaterialTable location={props.location} type="2" notify={notify} />
							) : null}
							{props.location.pathname.indexOf('longterm-materials') !== -1 ? (
								<MaterialTable location={props.location} type="3" notify={notify} />
							) : null}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Material>
	);
};

export default MaterialTableLayout;
